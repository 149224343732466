import { useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  withStyles
} from '@material-ui/core'
import { toast } from 'react-toastify'

import { 
  useGetElevationsQuery,
  useGetFacilityByIdQuery 
} from '../../../api/inspectionOps'
import {
  useUpdateFacilityStatusMutation,
  useDeleteFacilityMutation,
} from '../../../api/inspectionOps'
import { history } from '../../../utils/store'
import { useFacilityInspections } from '../../shared/hooks/inspections'
import Company from '../../shared/text/Company'
import NavBar  from '../../shared/NavBar'
import AreYouSureDialog from '../../shared/AreYouSureDialog'

import Header from './components/Header'
import LastInspectionBar from './components/LastInspectionBar'
import ProfileGraphic from './components/ProfileGraphic'
import ProfileInfo from './components/ProfileInfo'
import ProfileElevation  from './components/ElevationCard'
import ProfileInspection from './components/InspectionCard'

import FacilityDialog from '../components/FacilityDialog'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  facName: {
    fontWeight: 'bold'
  },
  miscDetails: {
    marginTop: theme.spacing(2)
  },
  rightContainer: {
    gap: theme.spacing(2),
    height: '100%',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
  }
})

const FacilityDetails = ({ classes, match }) => {
  const { facilityId } = match.params
  const { data, error, isLoading: facIsLoading } = useGetFacilityByIdQuery(facilityId)
  const { inspections } = useFacilityInspections({ facility_ids: facilityId })

  const [ isEdit, setIsEdit ] = useState(false)
  const [ isArchive, setIsArchive ] = useState(false)
  const [ isDelete, setIsDelete ] = useState(false)

  const [ isLoading, setIsLoading ] = useState(false)
  const [ archError, setArchError ] = useState('')
  const [ deleteError, setDeleteError ] = useState('')
  
  const [ updateFacilityStatus ] = useUpdateFacilityStatusMutation()
  const [ deleteFacility ] = useDeleteFacilityMutation()

  function handleDelete() {
    deleteFacility(facility._id)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('delete failed', fulfilled)
        setDeleteError('Failed to delete facility.')
      } else {
        console.log('delete success', fulfilled)
        setDeleteError('')
        setIsDelete(false)
        history.push('/facilities')
      }
    })
    .catch(rejected => {
      console.log('delete rejected', rejected)
      setDeleteError('Error when deleting facility.')
    })
  }

  function handleArchive() {
    setIsLoading(true)
    setArchError('')
    const patch = {
      _id: facility._id,
      status: facility.status === 'archived' ? 'active' : 'archived'
    }
    updateFacilityStatus(patch)
      .unwrap()
      .then(fulfilled => {
        console.log('update fulfilled', fulfilled)
        setIsLoading(false)
        if (fulfilled.status === 'success') {
          setIsArchive(false)
          toast.success(`Facility ${patch.status === 'active' ? 'un' : ''}archived`)
        } else {
          setArchError(`Failed to ${patch.status === 'active' ? 'un' : ''}archive facility`)
        }
      })
      .catch(rejected => {
        console.log('update rejected', rejected)
        setIsLoading(false)
        toast.error(`Error ${patch.status === 'active' ? 'un' : ''}archiving facility`)
        setArchError(`Error ${patch.status === 'active' ? 'un' : ''}archiving facility`)
      })
  }

  if (!facIsLoading) {
    if (error) {
      console.log('Error loading facility:', error)
      toast.error('Error loading facility.')
    } else if (data.status !== 'success') {
      console.log('Failed to load facility:', data)
      toast.error('Failed loading facility:' + data.data?.facility)
    }
  }

  const facility = data?.data?.facility || {}
  const {
    data:  elevationData,
    error: elevationError,
  } = useGetElevationsQuery({ facility_id: facilityId }, { skip: !facilityId })

  if (elevationError) {
    console.error(elevationError)
  }

  // Empty media_id can occur due to failed image upload while creating elevation
  // TODO: Improve create elevation with upload image success check
  const elevations = elevationData?.data?.elevations?.filter(
    elevation => !!elevation.images?.[0]?.media_id
  ) || []

  return (<>
    <NavBar
      subtitleOverline='facility owned by'
      subtitle={<Company id={facility.company_id} name />}
    />
    <div className={classes.root}>
      {facIsLoading && <CircularProgress />}
      <Header
        name={facility.name}
        shortName={facility.short_name}
        archived={facility.status === 'archived'}
        onEdit={() => setIsEdit(true)}
        onArchive={() => setIsArchive(true)}
        onDelete={() => setIsDelete(true)}
      />
      <LastInspectionBar facility={facility} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box display='flex' flexDirection='column' flex='1 1 0' className={classes.leftContainer}>
          {/* Map / Image */}
          <ProfileGraphic facility={facility} />
          {/* Elevations */}
          <ProfileElevation facility={facility} elevations={elevations}/>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display='flex' flexDirection='column' flex='1 1 0' className={classes.rightContainer}>
            {/* Inspections */}
            <ProfileInspection inspections={inspections} facilityId={facility._id} />
          </Box>
        </Grid>
      </Grid>

      <div className={classes.miscDetails}>
        <ProfileInfo facility={facility} />
      </div>
    </div>
    { isEdit &&
      <FacilityDialog
        open={isEdit}
        onClose={() => setIsEdit(false)}
        facility={facility}
        isUpdate={true}
      />
    }
    { isArchive && 
      <AreYouSureDialog
        open={isArchive}
        onClose={() => {
          setIsArchive(false)
          setArchError('')
        }}
        title={<>{facility.status === 'archived' ? 'Unarchive' : 'Archive'} Facility {facility.name}</>}
        message={<>Are you sure you want to {facility.status === 'archived' ? 'unarchive' : 'archive'} this facility?</>}
        action={<>Confirm {facility.status === 'archived' ? 'unarchive' : 'archive'}</>}
        onOK={handleArchive}
        error={archError}
        isLoading={isLoading}
      />
    }
    { isDelete &&
      <AreYouSureDialog
        open={isDelete}
        onClose={() => setIsDelete(false)}
        title={`Delete Facility ${facility.name}`}
        message={<>Are you sure you want to delete this facility?<br /><br />
          You cannot undo this action.</>}
        action='Confirm Delete'
        onOK={handleDelete}
        error={deleteError}
      />
    }
  </>)
}

export default withStyles(styles)(FacilityDetails)