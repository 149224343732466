export function convertCOCOtoYOLOPolygon(cocoData) {
  const yoloData = []

  for (const annotation of cocoData.annotations) {

    const image = cocoData.images.find(image => annotation.image_id === image.id)
    const category = cocoData.categories.find(category => annotation.category_id === category.id)

    const segmentations = annotation.segmentation

    const segmentation = segmentations[0] //assuming only one polygon
    const polygon = []
    if (segmentation.length % 2 === 0) {
      for (let i = 0; i < segmentation.length; i += 2) {
        const x = segmentation[i] / image.width
        const y = segmentation[i + 1] / image.height
        polygon.push(x, y)
      }

      const yoloAnnotation = {
        width: image.width,
        height: image.height,
        type: category.name,
        filename: image.file_name,
        polygon: polygon,
      }

      yoloData.push(yoloAnnotation)
    } else {
      return null
    }
  }

  return yoloData
}
