import { Chip, withStyles } from '@material-ui/core'

const styles = theme => ({
  countChip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(2)
  }
})

const CardHeaderTitle = ({ classes, label, count }) => {
  return (<>
    <span>{label}</span>
    <Chip label={count} size='small' className={classes.countChip} />
  </>)
}

export default withStyles(styles)(CardHeaderTitle)