import { useState, useEffect } from 'react'
import { history } from '../../../utils/store'
import SearchField from './SearchField'

import {
  Button,
  ButtonGroup,
  Grid,
  Tooltip,
} from '@material-ui/core'
import {
  MyLocation,
  Public,
  ViewComfy,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(1, 0),
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btn: {
    marginLeft: theme.spacing(1),
  }
})

function FacilityControls({
  classes,
  view, setView,
  showMyLocation, setShowMyLocation,
  onAddress,
  onLatLng,
  onCreate
}) {
  const [ showMyLocationButton, setShowMyLocationButton ] = useState(true)

  useEffect(() => {
    if (view !== 'map') {
      return
    }

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log('Successfully got current position - showing my location button', position)
        setShowMyLocationButton(true)
      }, err => {
        console.warn('Error getting current position - not showing my location button', err)
        setShowMyLocationButton(false)
      })
    } else {
      setShowMyLocationButton(false)
    }
  }, [view])

  const onChange = (facility) => {
    history.push(`/facilities/${facility._id}`)
  }

  return (
    <Grid container className={classes.root}>
      <Grid item sm={6} xs={12} className={classes.search}>
        { view === 'map' &&
          <SearchField
            setAddress={onAddress}
            setFacility={onChange}
            setLatLng={onLatLng}
          />
        }
        { view === 'grid' &&
          <SearchField
            setFacility={onChange}
          />
        }
      </Grid>
      <Grid item sm={6} xs={12} className={classes.action}>
        { view === 'map' && showMyLocationButton &&
          <Button className={classes.btn}
            variant={ showMyLocation ? 'contained' : 'outlined' }
            onClick={e => { setShowMyLocation(!showMyLocation) }}>
            <Tooltip title='My Location'><MyLocation /></Tooltip>
          </Button>
        }
        <ButtonGroup className={classes.btn}>
          <Button color='primary'
            variant={ view === 'grid' ? 'contained' : 'outlined' }
            onClick={e => { setView('grid') }}>
            <Tooltip title='Grid View'><ViewComfy /></Tooltip>
          </Button>
          <Button color='primary'
            variant={ view === 'map' ? 'contained' : 'outlined' }
            onClick={e => { setView('map') }}>
            <Tooltip title='Map View'><Public /></Tooltip>
          </Button>
        </ButtonGroup>
        <Button color='secondary' className={classes.btn}
          variant='contained' onClick={onCreate}>
          New Facility
        </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(FacilityControls)
