import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { useAccessToken } from '../../../../api/accounts'
import { getMediaURL } from '../../../../api/media'
import { pretty }  from '../../../../utils'
import {
  useGetDefectCategoriesByFacilityTypeQuery,
  // useGetImagesQuery,
  useCreateLabelWithDefectMutation
} from '../../../../api/inspectionOps'

import LoadingLightbox from '../../../shared/LoadingLightbox'
import TopXCloseButton from '../../../shared/TopXCloseButton'
import Image           from '../../../shared/Image'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Undo
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  dragArea: {
    margin:       theme.spacing(2, 0),
    minHeight:    theme.spacing(12),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(1),
    border:       '1px dashed black',
    overflow:     'hidden',
  },
  table: {
    margin:       theme.spacing(2, 0),
    minHeight:    theme.spacing(12),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(1),
    border:       '1px solid black',
  },
  title: {
    // padding:      theme.spacing(2),
    // marginBottom: theme.spacing(1),
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  cloudupload: {
    color:        theme.palette.primary.light,
    width:        theme.spacing(8),
    height:       theme.spacing(8),
  },
  instructions: {
    margin:       theme.spacing(1, 0),
    padding:      theme.spacing(2, 0),
    width:        '100%',
    textAlign:    'center',
  },
  error: {
    margin:       theme.spacing(1, 0),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(2),
    fontWeight:   'bold',
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
})

function AnnotateDefectDialog({ classes, open, onClose, inspectionId, facilityType, selectedImage }) {

  const [ errMsg,  setErrMsg  ] = useState('')
  const [ defects, setDefects ] = useState([])
  const [ step, setStep ] = useState(2) // 1: Choose image, 2: annotate
  // const [ selectedImage, setSelectedImage ] = useState(null)
  // const setSI = useCallback((img) => setSelectedImage(img), [setSelectedImage])

  const setD = useCallback((d) => setDefects(d), [setDefects] )

  useEffect(() => {
    setDefects([...[]])
  }, [step, open])

  const { data: defectCategoryData } = useGetDefectCategoriesByFacilityTypeQuery(facilityType, {
    skip: !facilityType
  })
  const defectCategories = defectCategoryData?.data?.categories || {}

  // const { data, error, isLoading } = useGetImagesQuery({
  //   inspection_id: inspectionId,
  // })
  // if (!isLoading) {
  //   if (error) {
  //     console.error('Error loading images', error)
  //     setErrMsg('Error loading images')
  //   } else if (!data.status === 'success') {
  //     console.warn('Failed loading images', data)
  //     setErrMsg('Failed loading images')
  //   }
  // }
  // const imageArray = useMemo(() => (data?.data?.images || []), [data])

  const [ createLabelWithDefect, {
    isLoading: isCreating,
    isError:   isErrorCreate,
    error:     errorCreate,
  } ] = useCreateLabelWithDefectMutation()

  function handleSubmit() {
    for (let defect of defects) {
      const label = {
        description: defect.description,
        inspection_id: inspectionId,
        inspection_image_id: selectedImage._id,
        shape: defect.shape,
        surface: defect.surface,
        type: defect.type,
        severity: defect.severity
      }

      if (defect.shape === 'rect') {
        const minX = Math.min(defect.corner1[0], defect.corner2[0])
        const minY = Math.min(defect.corner1[1], defect.corner2[1])
        const maxX = Math.max(defect.corner1[0], defect.corner2[0])
        const maxY = Math.max(defect.corner1[1], defect.corner2[1])
  
        label.x = minX * selectedImage.media_width
        label.y = minY * selectedImage.media_height
        label.w = (maxX - minX) * selectedImage.media_width
        label.h = (maxY - minY) * selectedImage.media_height
      }
      if (defect.shape === 'poly') {
        label.shape = 'yolo-poly'
        label.polygon = defect.vertices
      }

      createLabelWithDefect(label)
        .unwrap()
        .then(fulfilled => {
          if (fulfilled.status !== 'success') {
            console.log('create failed', fulfilled)
            setErrMsg('At least one create label / defect failed')
          } else {
            toast.success('Defect annotation created')
            onClose()
          }
        })
        .catch(rejected => {
          console.error('create rejected', rejected)
          console.log('create results: isCreating', isCreating, 'isErrorCreate', isErrorCreate, 'errorCreate', errorCreate)
          setErrMsg('At least one create label / defect error')
        })
    }
  }

  return (
    <Dialog open={open} onClose={onClose}
      fullWidth maxWidth='lg'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        { step === 1 ? 'Select an image to annotate' : 'Annotate Image' }
      </DialogTitle>
      <DialogContent>
        <HTML5warning />
        { step === 1 &&
          <ImagesList imageArray={[]} selectedImage={selectedImage}/>
        }
        { step === 2 &&
          <AnnotationCanvas defectCategories={defectCategories} image={selectedImage} defects={defects} setDefects={setD}/>
        }
      </DialogContent>
      <DialogActions>
        { step === 1 &&
          <Button variant='contained' color='primary'
            disabled={!selectedImage}
            onClick={() => setStep(2)}>
            Next
          </Button>
        }
        { step === 2 &&
          <Box display='flex' justifyContent='space-between' width='100%'>
            <Button variant='contained'
              style={{position: 'relative', left: '0px'}}
              onClick={() => setStep(1)}>
              Back
            </Button>

            <Button variant='contained' color='primary'
              disabled={defects.length === 0}
              onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        }
      </DialogActions>
      <LoadingLightbox
        open={!!errMsg}
        onClose={() => setErrMsg('')}
        error={errMsg}
      />
    </Dialog>
  )
}

function AnnotationCanvas ({image, defectCategories, defects, setDefects}) {
  const access_token = useAccessToken()

  const [ corner1, setCorner1 ] = useState(null)
  const [ corner2, setCorner2 ] = useState(null)
  const [ openDetailsDialog, setOpenDetailsDialog ] = useState(false)
  const [ isDrawing, setIsDrawing ] = useState(false)
  const [ surface, setSurface ] = useState('plaster')
  const [ type, setType ] = useState('crack')
  const [ severity, setSeveriry ] = useState('')
  const [ shape, setShape ] = useState('rect')
  // const shape = 'rect'
  
  const canvasRef = useRef(null)
  const backgroundRef = useRef(null)
  const descRef = useRef()
  const verticesRef = useRef([])

  const init = useCallback(() => {
    setCorner1(null)
    setCorner2(null)
    verticesRef.current = []
  }, [])

  const handleMouseDown = useCallback((event) => {
    event.preventDefault()
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()

    function getCursorPosition(event) {
      const x = (event.clientX - rect.left) / rect.width
      const y = (event.clientY - rect.top) / rect.height
      return [x,y]
    }

    const pos = getCursorPosition(event)

    if (shape === 'rect') {
      setCorner1(pos)
      setCorner2(null)
      setIsDrawing(true)
    }
    if (shape === 'poly') {
      const context = canvas.getContext('2d')
      const vertices = verticesRef.current
      context.fillStyle = 'rgba(255,0,0,1)'
      context.strokeStyle = 'rgba(255,0,0,1)'
      vertices.push(pos)
      setIsDrawing(true)

      const dotSize = 5
      context.lineWidth = 1
    
      function pointHitTest(x, y, point) {
        return (x >= point[0] - .5 * dotSize / rect.width &&
          x <= point[0] + .5 * dotSize / rect.width &&
          y >= point[1] - .5 * dotSize / rect.height &&
          y <= point[1] + .5 * dotSize / rect.height)
      }

      for (let i = 0; i < vertices.length; i++) {
        context.fillRect(vertices[i][0]*rect.width - .5*dotSize, vertices[i][1]*rect.height - .5*dotSize,dotSize,dotSize)
        if (i > 0) {
          context.beginPath()
          context.moveTo(vertices[i][0]*rect.width, vertices[i][1]*rect.height)
          context.lineTo(vertices[i-1][0]*rect.width, vertices[i-1][1]*rect.height)
          context.stroke()
        }
      }

      if (vertices.length > 1 && pointHitTest(pos[0], pos[1], vertices[0])) {
        setIsDrawing(false)
        setOpenDetailsDialog(true)
      }

    }
  }, [shape])

  const handleMouseMove = useCallback((event) => {
    event.preventDefault()
    if (!isDrawing) return
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()

    function getCursorPosition(canvas, event) {
      const x = (event.clientX - rect.left) / rect.width
      const y = (event.clientY - rect.top) / rect.height
      return [x,y]
    }

    if (shape === 'rect')
      setCorner2([...getCursorPosition(canvas, event)])
  }, [shape, isDrawing])

  const handleMouseUp = useCallback((event) => {
    event.preventDefault()
    if (shape === 'rect')
      setIsDrawing(false)
  }, [shape])

  const handleMouseOut = useCallback((event) => {
    event.preventDefault()
    if (shape === 'rect')
      setIsDrawing(false)
  }, [shape])

  useEffect(() => {
    if (!isDrawing && corner1 && corner2)
      setOpenDetailsDialog(true)
  }, [isDrawing, corner1, corner2])

  const draw = useCallback(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const rect = canvas.getBoundingClientRect()
    context.fillStyle = 'rgba(255,0,0,0.2)'
    context.strokeStyle = 'rgba(255,0,0,1)'
    
    context.clearRect(0, 0, rect.width, rect.height)
    context.drawImage(backgroundRef.current, 0, 0, rect.width, rect.height)

    if (shape === 'rect' && corner1 && corner2) {
      context.rect(corner1[0] * rect.width, corner1[1] * rect.height, (corner2[0] - corner1[0]) * rect.width, (corner2[1] - corner1[1]) * rect.height)
      context.fill()
    }
    
    if (defects)
      for (let d of defects) {
        if (d.shape === 'rect')
          context.rect(d.corner1[0] * rect.width, d.corner1[1] * rect.height, (d.corner2[0] - d.corner1[0]) * rect.width, (d.corner2[1] - d.corner1[1]) * rect.height)
        else if (d.shape === 'poly') {
          for (let i = 0; i < d.vertices.length; i++) {
            context.moveTo(d.vertices[i][0]*rect.width, d.vertices[i][1]*rect.height)
            context.lineTo(d.vertices[(i+d.vertices.length-1)%d.vertices.length][0]*rect.width, d.vertices[(i+d.vertices.length-1)%d.vertices.length][1]*rect.height)
          }
        }
      }
    context.stroke()
    
  }, [shape, defects, corner1, corner2])

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d')

    const handleResize = (width, height) => {
      ctx.canvas.width = canvasRef.current.parentElement.clientWidth
      ctx.canvas.height = ctx.canvas.width * height / width
    }

    const background = new Image()
    background.onload = (e) => {
      handleResize(image.media_width, image.media_height)
      draw()
    }
    background.src = getMediaURL(image.media_id, 'fullscreen', access_token)
    backgroundRef.current = background
    
  }, [draw, image, access_token])

  useEffect(() => { init() }, [init, shape])

  useEffect(() => {
    const canvas = canvasRef.current

    canvas.addEventListener('mousedown', handleMouseDown)
    canvas.addEventListener('mousemove', handleMouseMove)
    canvas.addEventListener('mouseup',   handleMouseUp)
    canvas.addEventListener('mouseout',  handleMouseOut)

    return () => {
      canvas?.removeEventListener('mousedown', handleMouseDown)
      canvas?.removeEventListener('mousemove', handleMouseMove)
      canvas?.removeEventListener('mouseup',   handleMouseUp)
      canvas?.removeEventListener('mouseout',  handleMouseOut)
    }
  }, [handleMouseDown, handleMouseMove, handleMouseUp, handleMouseOut])

  return (
    <Box>
      <Dialog open={openDetailsDialog} onClose={()=> {
          setOpenDetailsDialog(false)
          init()
        }}>
        <DialogContent>
          <TextField fullWidth label={'Description'} inputRef={descRef}/>
          <TextField select fullWidth
            value={surface}
            onChange={e => setSurface(e.target.value)}
            label={'Surface'}>
            { defectCategories?.surface?.map(v => <MenuItem key={v} value={v}>{pretty(v)}</MenuItem>) }
          </TextField>
          <TextField select fullWidth
            value={type}
            onChange={e => setType(e.target.value)}
            label={'Type'}>
            { defectCategories?.type?.map(v => <MenuItem key={v} value={v}>{pretty(v)}</MenuItem>) }
          </TextField>
          <TextField select fullWidth
            value={severity}
            onChange={e => setSeveriry(e.target.value)}
            label={'Severity'}>
            { defectCategories?.severity?.map(v => <MenuItem key={v} value={v}>{pretty(v)}</MenuItem>) }
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary'
            onClick={() => {
              defects.push({
                shape: shape,
                corner1: shape === 'rect' ? corner1 : undefined,
                corner2: shape === 'rect' ? corner2 : undefined,
                vertices: shape === 'poly' ? verticesRef.current : undefined,
                description: descRef.current.value,
                surface: surface,
                type: type,
                severity: severity
              })
              setDefects([...defects])
              setOpenDetailsDialog(false)
              init()
            }}>
            Add annotation
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{position: 'absolute', zIndex: 2, margin: '28px 0 0 4px'}}>
        <Button variant='contained' style={{marginLeft: '4px', border: (shape === 'rect' ? '3px solid #246ff1' : '')}} onClick={() => { setShape('rect') }}>
          Rectangle
        </Button>
        <Button variant='contained' style={{marginLeft: '4px', border: (shape === 'poly' ? '3px solid #246ff1' : '')}} onClick={() => { setShape('poly') }}>
          Polygon
        </Button>
        { defects.length > 0 &&
          <Button variant='contained' style={{marginLeft: '4px'}} onClick={() => {
            defects.pop()
            setDefects([...defects])
          }}>
            <Undo/> Undo
          </Button>
        }
      </div>
      { shape === 'rect' && <Typography>Click and drag to draw rectangle.</Typography> }
      { shape === 'poly' && <Typography>Click to add point, and click at the first point to finish drawing the polygon.</Typography> }
      <canvas style={{cursor: 'crosshair'}} ref={canvasRef}> </canvas>
    </Box>
  )
}

function ImagesList({imageArray, selectedImage, setSelectedImage}) {
  const imageCard = (image, i, selected) => (
    <Card style={{cursor: 'pointer', border: selected ? '3px solid #3498db' : ''}} onClick={() => setSelectedImage(image)}>
      <Image media_id={image.media_id} size='preview' alt={image.filename} style={{width: '100%'}}/>
      <Typography variant='body2' color='textSecondary' style={{ padding: '16px' }}>
        {image.filename || '-'}
      </Typography>
    </Card>
  )

  return (
    <Box>
      { imageArray?.map((image, i) => (
        <Box key={i} style={{width: '30%', display: 'inline-block', margin: '8px'}}>
          { imageCard(image, i, selectedImage ? selectedImage._id === image._id : false) }
        </Box>
      ))}
    </Box>
  )
}

function HTML5warning() {
  const classes = makeStyles(styles)()
  if (!window.FileReader) // only need FileReader, no need Worker
   return (
      <Typography variant='body1' className={classes.error}>
        Your browser does not have HTML 5. Please use a modern browser.
      </Typography>
    )
  else
    return null
}


export default withStyles(styles)(AnnotateDefectDialog)
