
import {
  IconButton,
  Typography,
  TextField,
  InputAdornment
} from '@material-ui/core'
import {
  ChevronLeft,
  ChevronRight,
  Edit,
  Cancel,
  ZoomIn,
  ZoomOut,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const styles = theme => ({
  middle: {
    display: 'flex',
    alignItems: 'center'
  }
})

function Controls({
  classes,
  id,
  inspectionId,
  name,
  index,
  ratioImage,
  maxIndex,
  setIndex,
  zoom,
  addZoom,
  minusZoom,
  hasLabels,
  editingLabel,
  toggleEditLabel,
}) {
  const items = useSelector(state => state.pagination.items)
  const currIdx = items?.findIndex(defectId => defectId === id)
  const history = useHistory()

  function handleNext() {
    history.push(`/inspection/${inspectionId}/detections/${items[currIdx + 1]}`)
  }

  function handlePrevious() {
    history.push(`/inspection/${inspectionId}/detections/${items[currIdx - 1]}`)
  }

  return (<>
    <div className={classes.middle}>
      { items.length > 1 && (<>
        <IconButton
        disabled={currIdx <= 0}
        onClick={handlePrevious}
        >
          <ChevronLeft fontSize='small' />
        </IconButton>
        <IconButton
          disabled={currIdx + 1 >= items.length || currIdx === -1}
          onClick={handleNext}
        >
          <ChevronRight fontSize='small' />
        </IconButton> 
      </>)}
      <Typography>
        { name }
      </Typography>
      { hasLabels &&
        <IconButton color='primary' onClick={toggleEditLabel}>
          { editingLabel ? <Cancel /> : <Edit /> }
        </IconButton>
      }
    </div>
    <div className={classes.middle}>
      <IconButton color='primary' onClick={minusZoom}>
         <ZoomOut />
      </IconButton>
      <TextField
          variant='outlined'
          value={Math.round(zoom * 100)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            style:{  width:  80 , height : 35 }
          }}
      />
      <IconButton color='primary' onClick={addZoom}>
        <ZoomIn />
      </IconButton>
    </div>
  </>)
}

export default withStyles(styles)(Controls)
