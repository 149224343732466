import { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  useGetDefectsQuery,
  useGetElevationsQuery
} from '../../../api/inspectionOps'

import AIApplyButton        from './Components/AIApplyButton'
import DefectCard           from './Components/DefectCard'
import EmptyDetection       from './Components/EmptyDetection'
import FilterDrawer         from './Components/FilterDrawer'
import InspectionImageCard  from './Components/InspectionImageCard'
import UploadLabelsButton   from './Components/UploadLabelsButton'
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  FilterList,
  PictureInPicture,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import { useDispatch } from 'react-redux'
import { loadItems } from '../../../utils/paginationSlice'

const styles = theme => ({
  root: {
    marginTop: '8px',
    padding: theme.spacing(1),
  },
  sort: {
    width:      '100%',
    maxWidth:   theme.spacing(50),
    marginLeft: theme.spacing(1),
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  controlsLeft: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  controlsRight: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  filter: {
    padding: theme.spacing(1),
  },
  iconBackground: {
    position: 'absolute',
    top:      '100px',
    right:    0,
    width:    '620px',
    height:   '620px',
    color:    theme.palette.grey[200],
    zIndex:   -100,
  },
})

function Detections({
  classes,
  inspectionId,
  facilityId,
  facilityType,
}) {
  const dispatch = useDispatch()
  const [ allDefects, setAllDefects ] = useState([])
 
  const [ openDrawer, setOpenDrawer ] = useState(false)
  const [ sortBy,     setSortBy     ] = useState('exif_created_time_asc')
  const [ filters,    setFilters    ] = useState({})
  const [ expandedImage, setExpandedImage ] = useState(null)
  const [ collapsed,  setCollapsed  ] = useState(true) // collapsed = combined defects of the same img together in one card

  const queryParams = { inspection_ids: [inspectionId], ...filters, sort: sortBy }

  const {
    data:      defectsData,
    isLoading: defectsLoading,
    error:     defectsError,
  } = useGetDefectsQuery(queryParams, {
    skip: !inspectionId
  })
  
  const { data: elevData } = useGetElevationsQuery({
    facility_id: facilityId,
  }, {
    skip: !facilityId
  })
  const elevations = elevData?.data?.elevations || []

  useEffect(() => {
    setExpandedImage(null)
  }, [collapsed])

  useEffect(() => {
    if (!dispatch)
      return

    if (defectsData?.status === 'success') {
      const defects = defectsData?.data?.defects
      setAllDefects(defects)
      dispatch(loadItems(defects?.map(d => d?._id)))
    }
  }, [defectsData, dispatch])

  if (defectsLoading) {
    return <Typography>Loading Detections...</Typography>
  }
  else if (defectsError) {
    toast.error('Error loading defects')
    console.log('Error loading defects', defectsError)
    return null
  }

  const hasFilters = filters && (
    filters.generic?.length             > 0 ||
    filters.status?.length              > 0 ||
    filters.facility_elevations?.length > 0 ||
    filters.surface?.length             > 0 ||
    filters.type?.length                > 0 ||
    filters.severity?.length            > 0
  )

  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        <div className={classes.controlsLeft}>
          { (allDefects?.length > 0 || hasFilters) && <>
            <Button
              variant='contained'
              color='primary'
              onClick={e => setCollapsed(!collapsed)}>
              { collapsed ? 'Expand' : 'Collapse' }
            </Button>
            <Button
              style={{marginLeft: '8px'}}
              variant='contained'
              color='primary'
              onClick={e => setOpenDrawer(true)}>
              <FilterList /> Filter
            </Button>
            <TextField select
              value={sortBy}
              variant='outlined'
              size='small'
              label='Sort'
              className={classes.sort}
              onChange={e => setSortBy(e.target.value)}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }
              }}>
              <MenuItem value='exif_created_time_asc'>Image Created Time (Chronological)</MenuItem>
              <MenuItem value='exif_created_time_desc'>Image Created Time (Most Recent)</MenuItem>
              <MenuItem value='relative_altitude_asc'>Image Relative Altitude (Lowest to Highest)</MenuItem>
              <MenuItem value='relative_altitude_desc'>Image Relative Altitude (Highest to Lowest)</MenuItem>
            </TextField>
          </> }
        </div>
        <div className={classes.controlsRight}>
          <AIApplyButton inspectionId={inspectionId} />
          {
          // <Button variant='outlined' onClick={() => { window.open(offlineAnnotateURL) }}>
          //   Launch Offline Annotate
          // </Button>
          // <AnnotateDefectButton inspectionId={inspectionId} facilityType={facilityType}/>
          }
          <UploadLabelsButton inspectionId={inspectionId} facilityType={facilityType}/>
        </div>
      </div>
      { allDefects?.length === 0 && !hasFilters &&
        <EmptyDetection />
      }
      { hasFilters &&
        <div className={classes.filter}>
          <Typography variant='body1'>
            {filters.surface?.length   > 0 && <><b>Surface</b> {filters.surface.join(', ')}<br /></>}
            {filters.type?.length      > 0 && <><b>Defect Type</b> {filters.type.join(', ')}<br /></>}
            {filters.severity?.length  > 0 && <><b>Severity</b> {filters.severity.join(', ')}<br /></>}
            {filters.facility_elevations?.length > 0 && <><b>Elevation</b> {
              filters.facility_elevations.map(fe => elevations.find(e => e._id === fe).name).join(', ')
            }<br /></>}
            {filters.status?.length    > 0 && <><b>Status</b> {filters.status.join(', ')}<br /></>}
            {filters.generic?.length   > 0 && <><b>Others</b> {filters.generic.join(', ')}<br /></>}
          </Typography>
          <br />
          { allDefects?.length === 0 &&
            <Typography variant='body1'>
              No Defects found with the given filters.<br />
              <br />
              <button onClick={() => setFilters({})}>
                Reset filters
              </button>
            </Typography>
          }
        </div>
      }
      { allDefects?.length > 0 &&
        <Grid container spacing={3}>
          { collapsed ?
            <DefectCardsByImage
              defects={allDefects}
              expandedImage={expandedImage}
              setExpandedImage={setExpandedImage}
              inspectionId={inspectionId}
            />
          : allDefects?.map((defect, index) => (
            <Grid item xxl={1} key={index}>
              <DefectCard
                defect={defect}
                index={index}
                inspectionId={inspectionId}
              />
            </Grid>
          )) }
        </Grid>
      }
      <FilterDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onFilter={setFilters}
        elevations={elevations}
        facilityType={facilityType}
      />
      <PictureInPicture className={classes.iconBackground} />
    </div>
  )
}

function DefectCardsByImage({ defects, expandedImage, setExpandedImage, inspectionId }) {

  if (defects.length === 0)
    return null

  // Round about way to collate for now - TODO: remove inspection image info
  // from defect, and count on RTK Query to cache it correctly, then move this
  // section to standalone file
  const insp_images = {}
  defects.forEach(d => {
    const iid = d.instance.inspection_image_id
    if (!insp_images[iid]) {
      insp_images[iid] = {
        _id: iid,
        defects: [],
        media_id: d.instance.media_id,
        filename: d.instance.filename,
        surface_types: {}
      }
    }
    insp_images[iid].defects.push(d)
    const st = d.surface + '-' + d.type
    if (insp_images[iid].surface_types[st])
      insp_images[iid].surface_types[st]++
    else
      insp_images[iid].surface_types[st] = 1
  })

  return (<>
    { Object.keys(insp_images).map(insp_image_id => (
      insp_image_id !== expandedImage ?
        <InspectionImageCard
          key={insp_image_id}
          image={insp_images[insp_image_id]}
          setExpandedImage={setExpandedImage}/> 
      : <Fragment key={insp_image_id}>
        { defects?.map((defect, index) => (
          defect.instance.inspection_image_id === expandedImage &&
          <Grid item xxl={1} key={index}>
            <DefectCard
              defect={defect}
              index={index}
              inspectionId={inspectionId}
            />
          </Grid>
        ))}
      </Fragment>
    ))}
  </>)
}

export default withStyles(styles)(Detections)
