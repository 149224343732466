import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  ErrorOutline,
  PhotoLibrary,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { useGetImagesQuery } from '../../../../../api/inspectionOps'

import CardHeaderTitle from './CardHeaderTitle'
import Image from '../../../../shared/Image'

const styles = theme => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiCardHeader-action': {
      marginRight: 'auto',
      marginTop: 'auto',
    }
  },
  inspectionName: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  inspectionList: {
    maxHeight: theme.spacing(66)
  },
  subcard: {
    height: '100%',
  },
})

function ImagesCard({ push, classes, facilityId, inspection }) {
  const { data: imagesData } = useGetImagesQuery({
    inspection_ids: [inspection._id],
    page:         1,
    limit:        3,
    sort:         'exif_created_time_desc',
  }, {skip: !inspection._id})
  const images = imagesData?.data?.images || []

  return <Card className={classes.subcard}>
    <CardHeader
      avatar={<Avatar><PhotoLibrary /></Avatar>}
      title={<CardHeaderTitle count={images.length} label='Images' />}
      titleTypographyProps={{ variant: 'h6' }}
      className={classes.cardHeader}
    />
    <CardContent>
      <List className={classes.inspectionList}>
        {/* { inspections.map((inspection, i) =>
          <ListItem key={i}
            onClick={() => push(`/inspection/${inspection._id}`)}>
            <ListItemIcon>
              <InspectionStatusIconWithToolTip status={inspection.status} />
            </ListItemIcon>
            <ListItemText
              primary={inspection.name}
              primaryTypographyProps={{ className: classes.inspectionName }}
              secondary={`${dd_mmm_yyyyEpoch(inspection.start_date)} - ${dd_mmm_yyyyEpoch(inspection.end_date)}`} />
          </ListItem>
        ) } */}
        { images.length === 0 ?
          <ListItem>
            <ListItemIcon><ErrorOutline /></ListItemIcon>
            <ListItemText primary='No images found' />
          </ListItem>
        :
          <ImageList gap={1} cols={3} className={classes.imageList}>
            {images.map((image, i) => (
              <ImageListItem key={i}>
                <Image media_id={image.media_id} size='preview' alt={image.filename} />
              </ImageListItem>
            ))}
          </ImageList>
        }

      </List>
    </CardContent>
  </Card>
}

export default withStyles(styles)(ImagesCard)