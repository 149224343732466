import { CallbackComponent } from 'redux-oidc'
import { connect } from 'react-redux'
import { push }    from 'connected-react-router'
import { jwtDecode } from 'jwt-decode'
import userManager from '../../utils/user-manager'
import Loading     from './Loading'

function LoginCallback({ push }) {
  const successCallback = async (plexUser) => {
    plexUser && plexUser.access_token && plexUser.profile.sub &&
    jwtDecode(plexUser.access_token)?.app_roles?.facilities?.length > 0
      ? push('/facilities')
      : push('/')
  }

  const errorCallback = async (error) => {
    console.log('Login error callback: ', error)
    // We sign in redirect again for cases where user
    // signs in post verification email
    await userManager.signinRedirect()
  }

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}>
      <Loading message='Login success. Redirecting ...' />
    </CallbackComponent>
  )
}

export default connect(null, { push })(LoginCallback)
