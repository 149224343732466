import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  Image,
  Map
} from '@material-ui/icons'
import TopXCloseButton from '../../../../shared/TopXCloseButton'
import DialogActionButtons from './components/DialogActionButtons'

const styles = (theme) => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  dialogPaper: {
    minHeight: theme.spacing(24),
    maxHeight: theme.spacing(24),
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  fileUpload: {
    width: theme.spacing(27),
    overflow: 'hidden'
  },
  content: {
    height: theme.breakpoints.values.sm,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  button: {
    width: '50%',
    borderRadius: 0,
    zIndex: 1,
    opacity: 1,
  },
  buttonLeft: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
  },
  buttonRight: {
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
})

function ElevationBgMethod({
  classes,
  open,
  onClose,
  onNext,
  onBack,
  onSave,
  mode,
  setMode,
}) {

  return (
    <Dialog classes={{ paper: classes.dialogPaper}} open={open} onClose={onClose} fullWidth maxWidth='md'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        Step 2: Create an Elevation background image
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant='body1'>
          Select preferred method of creating elevation background image
        </Typography>
          <Box display='flex' flexDirection='column' flexGrow='1'>
            <Box display='flex' flexDirection='row' className={classes.buttons}>
              <Button
                className={classes.button + ' ' + classes.buttonLeft}
                color='primary'
                variant={mode === 'map' ? 'contained' : 'outlined'}
                onClick={() => {
                  setMode('map')
                  onNext()
                }}
                startIcon={<Map />}
              >
                  3D Map
              </Button>
              <Button
                className={classes.button + ' ' + classes.buttonRight}
                color='primary'
                variant={mode === 'image' ? 'contained' : 'outlined'}
                onClick={() => {
                  setMode('image')
                  onNext()
                }}
                startIcon={<Image />}
              >
                Image
              </Button>
            </Box>
          </Box>
        </DialogContent>
      <DialogActions>
        <DialogActionButtons
          onNext={onNext}
          disableNext={mode === null}
          onCancel={onBack}
          nextText='' />
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ElevationBgMethod)