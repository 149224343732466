import { useState, useMemo } from 'react'
import {
  useGetDocumentTypesQuery,
  useGetDocumentByDocumentIdQuery,
} from '../../api/inspectionOps'
import { useMediaURL } from '../../api/media'

import NavBar from '../shared/NavBar'
import { DocumentStatusIcon } from '../shared/Icons'
import TopXCloseButton from '../shared/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import {
  Archive,
  CloudDownload,
  // Delete,
  // Edit,
  RateReview,
  ThumbUp,
  ThumbDown,
  Unarchive,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  title: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 2),
  },
  icon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  actions: {
  }
})

function SingleDocument({ classes, match }) {
  const documentId = match.params.documentId

  const { data: docTypesData } = useGetDocumentTypesQuery()
  const allDocTypes = useMemo(() => docTypesData?.data?.document_types || [], [docTypesData])

  const { data } = useGetDocumentByDocumentIdQuery(documentId, {
    skip: !documentId
  })
  const doc = data?.data?.document || {}
  const status = doc.status
  const docType = allDocTypes.find(t => t._id === doc.type_id) || {}
  const uri = useMediaURL(doc.original_file_media_id, 'original')
  const docArray = [{ uri }]

  const [ edit, setEdit ] = useState(false)
  const [ dele, setDele ] = useState(false)

  // To debate whether to go to backend later sprint or let user have a chance to fix email.
  function handleReview() {
    // TODO: Update document status to review
    window.open(`mailto:?subject=${encodeURIComponent(`[For Review] ${doc.name}`)}&body=${encodeURIComponent(`Hi,

    Please approve this document:

    ${process.env.REACT_APP_PUBLIC_URL}/document/${documentId}

    Thank you!
    `)}`)
  }
  function handleReject() {
    // TODO: Update document status to draft & send email
  }
  function handleApprove() {
    // TODO: Update document status to final & send email
  }
  function handleArchive() {
    // TODO: Update document status to archive & send email
  }
  function handleUnarchive() {
    // TODO: Update document status to unarchive & send email
  }

  return (<>
    <NavBar />
    <div className={classes.title}>
      <div style={{ display: 'flex' }}>
        <div className={classes.iconContainer}>
          <DocumentStatusIcon status={status} className={classes.icon} />
          <Typography variant='overline'>{status}</Typography>
        </div>
        <div>
          <Typography variant='h4'>{doc.name || '-'}</Typography>
          <Typography variant='body1'>{docType.name || '-'}</Typography>
          <Typography variant='body2'>{docType.description || '-'}</Typography>
        </div>
      </div>
      <div className={classes.actions}>
        { status === 'deleted' ?
          <Typography variant='body1'>This document has been deleted.</Typography>
        : <>
          <Button variant='contained' startIcon={<CloudDownload />}
            onClick={() => window.open(docArray[0].uri)}>
            Download
          </Button> &nbsp;
        </> }
        { status === 'draft' && <>
          {/* <Button variant='contained' startIcon={<Delete />} onClick={() => setDele(true)}>
            Delete
          </Button> &nbsp; */}
          {/* <Button variant='contained' startIcon={<Edit />} onClick={() => setEdit(true)}>
            Edit
          </Button> &nbsp; */}
          {/* <Button variant='contained' color='primary' startIcon={<RateReview />} onClick={handleReview}>
            Submit for Review
          </Button> &nbsp; */}
        </>}
        { status === 'review' && <>
          <Button variant='contained' color='secondary' startIcon={<ThumbDown />}
            onClick={handleReject}>
            Reject
          </Button> &nbsp;
          <Button variant='contained' color='primary' startIcon={<ThumbUp />}
            onClick={handleApprove}>
            Approve
          </Button> &nbsp;
        </>}
        { status === 'final' && <>
          <Button variant='contained' startIcon={<RateReview />}
            onClick={handleReview}>
            Re-Review
          </Button> &nbsp;
          <Button variant='contained' color='secondary' startIcon={<Archive />}
            onClick={handleArchive}>
            Archive
          </Button> &nbsp;
        </>}
        { status === 'archived' && <>
          <Button variant='contained' color='primary' startIcon={<Unarchive />}
            onClick={handleUnarchive}>
            Unarchive
          </Button> &nbsp;
        </> }
      </div>
    </div>
    { edit &&
      <EditDialog open={edit} onClose={() => setEdit(false)} />
    }
    { dele &&
      <DeleteDialog open={dele} onClose={() => setDele(false)} />
    }
  </>)
}

function EditDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>Edit Document Metadata</DialogTitle>
      <DialogContent>TODO: Inspection Dropdown, Facility Dropdown.
        Note: if a document is already associated with an Inspection that
        is no longer in Draft, the association cannot be removed.
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained'>Edit</Button>
      </DialogActions>
    </Dialog>
  )
}

function DeleteDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>Delete Document</DialogTitle>
      <DialogContent>TODO: Check if document can be deleted,
        if already associated with an inspection that is no longer in draft,
        then can only archive cannot delete.
      </DialogContent>
      <DialogActions>
        <Button color='secondary' variant='contained'>Confirm Deletion</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(SingleDocument)
