import { useState, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

// import DetailModal from './DetailModal'
import ElevationLineModal from './ElevationLineModal'
import ElevationEditQuadrilateralModal from './ElevationEditQuadrilateralModal'
import ElevationBgMethod from './ElevationBgMethod'
import ElevationViewModal from './ElevationViewModal'
import ElevationQuadrilateralModal from './ElevationQuadrilateralModal'

import { useCreateElevationMutation, useUpdateElevationImageMutation, useUpdateElevationMutation } from '../../../../../api/inspectionOps'
// import { useTerrainQuery, useLazyTerrainQuery } from '../../../../../api/geospatial'

const MAX_STEP = 4

const ElevationModal = ({ facility, elevation, open, onClose }) => {
  const [ currentStep, setCurrentStep ] = useState(1)
  const [ name, setName ] = useState('')
  const [ location, setLocation ] = useState(null)
  const [ mode, setMode ] = useState(null)
  const [ file, setFile ] = useState(null)
  const [ isSaving, setIsSaving ] = useState(false) // Currently unused
  const [ quadrilateral, setQuadrilateral ] = useState([])
  const [ createElevation ] = useCreateElevationMutation()
  const [ updateElevation ] = useUpdateElevationMutation()
  const [ updateElevationImage ] = useUpdateElevationImageMutation()
  const [ topAGL, setTopAGL ] = useState(0)
  const [ bottomAGL, setBottomAGL ] = useState(0)

  const setTAGL = useCallback((a) => {
    setTopAGL(a)
  }, [setTopAGL])
  const setBAGL = useCallback((a) => {setBottomAGL(a)}, [setBottomAGL])

  useEffect(() => {
    setFile(null)
    setQuadrilateral([])
  }, [mode])

  useEffect(() => {
    // Prepopulate edit elevation fields
    if (!elevation) return
    
    if (elevation.name)
      setName(elevation.name)

    if (elevation._id) {
      setTopAGL(elevation.geometry.coordinates[0][0][2])
      setBottomAGL(elevation.geometry.coordinates[0][2][2])
    }
  }, [elevation])

  const setQ = useCallback((quad) => {
    setQuadrilateral(quad)
  }, [setQuadrilateral])

  const handleClose = () => {
    setCurrentStep(1)
    setName('')
    setLocation(null)
    setFile(null)
    setQuadrilateral([])
    setIsSaving(false)
    onClose()
  }

  const handleNextStep = async () => {
    if (currentStep === 1 && elevation?._id)
      setCurrentStep(1.5)
    else
      setCurrentStep(currentStep + 1)
    if (currentStep === 1.5 || currentStep + 1 > MAX_STEP) {
      setIsSaving(true)
      await handleSave()
      setIsSaving(false)
      handleClose()
    }
  }

  const handlePreviousStep = () => {
    if (currentStep === 1.5) {
      setCurrentStep(1)
      return
    }

    setCurrentStep(currentStep - 1)
    if (currentStep - 1 < 1) {
      handleClose()
    }
  }

  const handleSave = async () => {
    async function submitImage(elevationId) {
      updateElevationImage({ elevationId, image: file })
      .unwrap()
      .then(fulfilled => {
        console.log('update fulfilled', fulfilled)
        if (fulfilled.status === 'success') {
          console.log('Elevation image updated.')
        }
        else
          console.log('Failed to update elevation image.')
      })
      .catch(rejected => {
        console.log('Update rejected', rejected)
      }).finally(() => {
        
      })
      setFile(null)
      handleClose()
    }

    const crossProduct = (a, b) => {
      return [
        a[1]*b[2] - a[2]*b[1],
        a[2]*b[0] - a[0]*b[2],
        a[0]*b[1] - a[1]*b[0]
      ]
    }

    const checkDirection = (location, facilityCenter) => {
      const line1 = [location[0][0] - facilityCenter.coordinates[0], location[0][1] - facilityCenter.coordinates[0], 0]
      const line2 = [location[1][0] - facilityCenter.coordinates[0], location[1][1] - facilityCenter.coordinates[0], 0]
      const cp = crossProduct(line2, line1)
      if (cp[2] > 0) return 'right'
      else return 'left'
    }

    const label = []
    const loc = location ? location.features[0].geometry.coordinates : elevation.geometry.coordinates[0]
    const bearing = facility.centroid ? checkDirection(loc, facility.centroid) : 'right' // If first elev point is on left or right
    const left  = bearing === 'left'  ? loc[0] : loc[1]
    const right = bearing === 'right' ? loc[0] : loc[1]

    // Sort to quadrilateral vertices in clockwise
    // 1. Find top left (TL): Shortest Euclidean distance to (0,0)
    let topLeft = quadrilateral[0]
    let quadCenter = {x: 0, y: 0}
    for (let q of quadrilateral) {
      quadCenter.x += q.x/4
      quadCenter.y += q.y/4
      if (q.x*q.x + q.y*q.y < topLeft.x*topLeft.x + topLeft.y*topLeft.y)
        topLeft = q
    }
    // 2. Calculate angle between center to other 3 vertices and center to TL
    const ctl = {x: topLeft.x - quadCenter.x, y: topLeft.y - quadCenter.y}
    for (let q of quadrilateral) {
      const cq = {x: q.x - quadCenter.x, y: q.y - quadCenter.y}
      const dot = cq.x * ctl.x + cq.y * ctl.y
      const det = cq.x * ctl.y - cq.y * ctl.x
      q.dot = dot
      q.det = det
      q.angle = Math.atan2(det,dot)
      if (q.angle < 0)  q.angle += 2*Math.PI
      q.angle *= 180 / Math.PI
    }

    // 3. Sort the vertices by the angle
    const compare = (a,b) => {return b.angle-a.angle}
    quadrilateral.sort(compare)
    quadrilateral.unshift(quadrilateral.pop())

    const quadGeometry = []

    for (let i = 0; i < quadrilateral.length; i++) {
      const q = quadrilateral[i]
      label.push([q.x/q.imgWidth,q.y/q.imgHeight])
      const lat = i === 0 || i === 3 ? left[1] : right[1] //left[1] + latRange*(q.x-minX)/xRange,
      const lng = i === 0 || i === 3 ? left[0] : right[0] //left[0] + lngRange*(q.x-minX)/xRange,
      const agl = i === 0 || i === 1 ? topAGL : bottomAGL

      // const groundHeight = await terrainQuery({ locations: [{lat: 0, lng: 0}]})
      // console.log(groundHeight)

      quadGeometry.push({
        lat: lat,
        lng: lng,
        amsl: parseFloat(agl) //TODO: use google terrain API, convert during click (not here)
      })
    }

    const newElevation = {
      facility_id: facility._id,
      name: name,
      description: '',
      geometry: {
        type: 'Polygon',
        coordinates: [quadGeometry.map(q => [q.lng, q.lat, q.amsl])]
      },
      height: 0,
      images: [{
        media_id: '',
        label: label
        // data_provider: ''
      }]
    }

    // Edit elevation
    if (elevation._id) {
      delete newElevation.facility_id
      newElevation.images[0].media_id = elevation.images[0].media_id
      updateElevation({_id: elevation._id, elevation: newElevation})
      .unwrap()
      .then(fulfilled => {
        console.log('update fulfilled', fulfilled)
        if (fulfilled.status === 'success') {
          console.log('Update elevation success')
          toast.success(`Elevation ${name} updated`)
        }
        else {
          console.log('Failed to update elevation.')
          toast.error(`Failed to update elevation ${name}`)
        }
      })
      .catch(rejected => {
        console.log('Update rejected', rejected)
        toast.error(`Update elevation ${name} rejected`)
      })
      .finally(() => {
      })
      return
    }
    
    // Create elevation
    createElevation(newElevation)
    .unwrap()
    .then(fulfilled => {
      console.log('create fulfilled', fulfilled)
      if (fulfilled.status === 'success') {
        console.log('Create elevation success')
        toast.success(`Elevation ${name} created`)
        submitImage(fulfilled.data.elevation._id)
      }
      else {
        console.log('Failed to create elevation.')
        toast.error(`Failed to create elevation ${name}`)
      }
    })
    .catch(rejected => {
      console.log('Create rejected', rejected)
      toast.error(`Elevation ${name} rejected`)
    })
    .finally(() => {
    })
  }

  return (<>
    <ElevationLineModal
      facility={facility}
      open={open && currentStep === 1}
      onClose={handleClose}
      name={name.length > 0 ? name : (elevation?.name || '')}
      setName={name => setName(name)}
      location={location ? location : elevation?.geometry}
      setLocation={setLocation}
      onNext={() => handleNextStep()}
    />
    <ElevationEditQuadrilateralModal
      elevation={elevation}
      open={open && currentStep === 1.5}
      onBack={handlePreviousStep}
      onClose={handleClose}
      onNext={() => handleNextStep()}
      setQuadrilateral={setQ}
      topAGL={topAGL}
      setTopAGL={setTAGL}
      bottomAGL={bottomAGL}
      setBottomAGL={setBAGL}
      name={name}
      isSaving={isSaving}
    />
    <ElevationBgMethod
      open={open && currentStep === 2}
      mode={mode}
      setMode={setMode}
      onBack={handlePreviousStep}
      onClose={handleClose}
      onNext={() => handleNextStep()}
    />
    <ElevationViewModal
      facility={facility}
      mode={mode}
      location={location}
      open={open && currentStep === 3}
      onBack={handlePreviousStep}
      onClose={handleClose}
      onNext={() => handleNextStep()}
      name={name}
      file={file}
      setFile={file => setFile(file)}
      quadrilateral={quadrilateral}
      setQuadrilateral={setQ}
      topAGL={topAGL}
      setTopAGL={setTAGL}
      bottomAGL={bottomAGL}
      setBottomAGL={setBAGL}
      onSave={handleSave}
    />
    <ElevationQuadrilateralModal
      elevationImg={file}
      open={open && currentStep === 4}
      onBack={handlePreviousStep}
      onClose={handleClose}
      onNext={() => handleNextStep()}
      quadrilateral={quadrilateral}
      setQuadrilateral={setQ}
      topAGL={topAGL}
      setTopAGL={setTAGL}
      bottomAGL={bottomAGL}
      setBottomAGL={setBAGL}
      name={name}
      isSaving={isSaving}
    />
  </>)
}

export default ElevationModal