import { toast } from 'react-toastify'
import { useGetInspectionsQuery } from '../../../api/inspectionOps'

// export function useInspections({ facility_ids }) {
//   if (facility_ids) {
//     return useFacilityInspections({ facility_ids })
//   } else {
//     return useAllInspections()
//   }
// }

export function useAllInspections() {
  const { data, error, isLoading } = useGetInspectionsQuery()

  if (!isLoading) {
    if (error) {
      console.log('Error loading inspections:', error)
      toast.error('Error loading inspections')
    }
    else if (data.status !== 'success') {
      console.log('Failed loading inspections:', data)
      toast.warning('Failed loading inspections')
    }
  }
  return {
    inspections:        data?.data?.inspections || [],
    inspectionsLoading: isLoading,
  }
}

export function useFacilityInspections({ facility_ids }) {
  const { data, error, isLoading } = useGetInspectionsQuery({ facility_ids }, {
    // skip: !facility_ids
  })

  if (!isLoading) {
    if (error) {
      console.log(`Error loading inspections (facility ids ${facility_ids})`, error)
      toast.error('Error loading inspections for this facility')
    }
    else if (data.status !== 'success') {
      console.log(`Failed loading inspections (facility ids ${facility_ids})`, data)
      toast.warning('Failed loading inspections for this facility')
    }
  }
  return {
    inspections:        data?.data?.inspections || [],
    inspectionsLoading: isLoading,
  }
}
