import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { standardHeaders, makeQueryString } from './api-utils'

const GEO_AI_CONFIG_API = new URL(process.env.REACT_APP_GEO_AI_CONFIG_API).href

export const geoAiConfigApi = createApi({
  reducerPath: 'geoAiConfigApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GEO_AI_CONFIG_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['ML Model', 'ML Model Version'],
  endpoints: (builder) => ({

    // #### ML MODEL ####
    getMLModels: builder.query({
      query: (params) => (`/ml_model${makeQueryString(params, [
        'status',
      ])}`),
      providesTags: ['ML Model'],
    }),
    getMLModelById: builder.query({
      query: (ml_model_id) => `/ml_model/${ml_model_id}`,
      providesTags: ['ML Model'],
    }),

    // #### ML MODEL VERSION ####
    getMLModelVersions: builder.query({
      query: (params) => (`/ml_model_version${makeQueryString(params, [
        'ml_model_id',
        'status',
      ])}`),
      providesTags: (result, error, ml_model_id) => [{type: 'ML Model Version', ml_model_id}]
    }),
  }),
})

export const {

  useGetMLModelsQuery,
  useGetMLModelByIdQuery,

  useGetMLModelVersionsQuery,
  useLazyGetMLModelVersionsQuery,

} = geoAiConfigApi
