import { useEffect, useRef, useCallback, useState } from 'react'

import { useAccessToken } from '../../api/accounts'
import { getMediaURL, useGetMediaByIdQuery } from '../../api/media'
import { useLocateDefectQuery } from '../../api/inspectionOps'

import { Box } from '@material-ui/core'

function ElevationImage({
  coords,
  detection,
  editable,
  elevation,
  fullWidth,
  inspection_id,
  navigateable,
  setCoords,
  showDefects,
}) {
  const access_token = useAccessToken()
  const canvasRef = useRef(null)
  const backgroundRef = useRef(null)
  const [ picked, setPicked ] = useState(null)
  const [ temporaryCoord, setTemporaryCoord ] = useState(coords)

  const { data: mediaData } = useGetMediaByIdQuery(elevation?.images[0]?.media_id, {
    skip: !elevation?.images[0]?.media_id
  })

  useEffect(() => {
    setTemporaryCoord(coords)
  }, [coords])
      
  const { data: defectLocationData } = useLocateDefectQuery({
    elevation_id: elevation._id,
    label_media: [],
    inspection_id: inspection_id
  })

  const handleMouseDown = useCallback((event) => {
    event.preventDefault()
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const dotSize = 5

    const clickX = (event.clientX - rect.left) / rect.width
    const clickY = (event.clientY - rect.top) / rect.height

    function pointHitTest(x, y, point) {
      return (x >= point.x - .5 * dotSize / rect.width &&
        x <= point.x + .5 * dotSize / rect.width &&
        y >= point.y - .5 * dotSize / rect.height &&
        y <= point.y + .5 * dotSize / rect.height)
    }

    const detectionLocations = defectLocationData?.status === 'success' ? defectLocationData.data : []

    for (let d of detectionLocations) {
      const point = temporaryCoord?.[d.defect_id] ? temporaryCoord[d.defect_id] : d
      
      if (pointHitTest(clickX, clickY, point)) {
        if (d.defect_id === detection?._id) {
          setPicked(d)
          break
        }

        if (navigateable) {
          window.open(`/inspection/${inspection_id}/detections/${d.defect_id}`)
          break
        }
      }
    }
  }, [detection, temporaryCoord, defectLocationData, navigateable, inspection_id])

  const handleMouseMove = useCallback((event) => {
    if (!picked) return

    event.preventDefault()
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const clickX = (event.clientX - rect.left) / rect.width
    const clickY = (event.clientY - rect.top) / rect.height
    
    temporaryCoord[picked.defect_id] = { x: clickX, y: clickY }
    setTemporaryCoord({ ...temporaryCoord })
  }, [picked, temporaryCoord])

  const handleMouseUp = useCallback((event) => {
    event.preventDefault()
    setCoords({...temporaryCoord})
    setPicked(null)
  }, [temporaryCoord, setCoords])

  const handleMouseOut = useCallback((event) => {
    event.preventDefault()
    setCoords({...temporaryCoord})
    setPicked(null)
  }, [temporaryCoord, setCoords])

  const draw = useCallback(() => {
    // Show Quadrilateral
    const canvas = canvasRef.current
    if (canvas){
      const context = canvas.getContext('2d')
      const rect = canvas.getBoundingClientRect()
      
      context.clearRect(0, 0, rect.width, rect.height)
      context.drawImage(backgroundRef.current, 0, 0, rect.width, rect.height)

      const color = 'cyan'
      const dotSize = 5

      const vertices = elevation.images[0].label
      if (!vertices || vertices.length === 0) return

      context.beginPath()
      context.strokeStyle = color
      context.fillStyle = color
      context.fillRect(
        vertices[0][0] * rect.width - .5 * dotSize,
        vertices[0][1] * rect.height - .5 * dotSize,
        dotSize, dotSize
      )
      context.stroke()
      
      context.beginPath()
      context.lineWidth = 1
      context.moveTo(
        vertices[vertices.length - 1][0] * rect.width,
        vertices[vertices.length - 1][1] * rect.height
      )
      context.lineTo(vertices[0][0] * rect.width, vertices[0][1] * rect.height)
      context.stroke()

      context.fillStyle = 'rgba(0, 255, 255, 0.5)'
      context.beginPath()
      context.moveTo(vertices[0][0] * rect.width, vertices[0][1] * rect.height)
      for (let i = 1; i < vertices.length; i++) {
        context.fillRect(
          vertices[i][0] * rect.width - .5 * dotSize,
          vertices[i][1] * rect.height - .5 * dotSize,
          dotSize, dotSize
        )
        context.stroke()
        context.lineTo(vertices[i][0] * rect.width, vertices[i][1] * rect.height)
      }
      context.closePath()
      context.fill()

      const detectionLocations =  defectLocationData?.status === 'success' ? defectLocationData.data : []

      if (!detectionLocations || detectionLocations.length === 0 || !showDefects) return

      let thisDFL = null
      for (let i = 0; i < detectionLocations.length; i++) {
        const d = detectionLocations[i]
        if (detection && d.defect_id === detection._id) {
          thisDFL = d
          continue
        }

        context.beginPath()
        context.fillStyle = 'rgba(125,0,0,1)'
        context.fillRect(
          d.x * rect.width - .5 * dotSize, d.y * rect.height - .5 * dotSize,
          dotSize, dotSize
        )
      }

      if (thisDFL) {
        let x = thisDFL.x
        let y = thisDFL.y
        
        if (temporaryCoord && temporaryCoord[thisDFL.defect_id]) {
          x = temporaryCoord[thisDFL.defect_id].x
          y = temporaryCoord[thisDFL.defect_id].y
        }

        context.beginPath()
        context.fillStyle = 'red'
        context.fillRect(
          x * rect.width - .5 * dotSize, y * rect.height - .5 * dotSize,
          dotSize, dotSize
        )
      }
    }
  }, [detection, elevation, temporaryCoord, defectLocationData, showDefects])
    
  useEffect(() => {
    if (!elevation)
      return
    const ctx = canvasRef.current.getContext('2d')

    const handleResize = (width, height) => {
      if (canvasRef?.current){
        ctx.canvas.width = canvasRef.current.parentElement.clientWidth
        ctx.canvas.height = ctx.canvas.width * height / width
      }
    }

    const background = new Image()
    background.onload = (e) => {
      const media = mediaData?.data?.media
      if (media)
        handleResize(media.exif.image_width, media.exif.image_height)
      draw()
    }
    background.src = getMediaURL(elevation.images[0].media_id, fullWidth ? 'fullscreen' : 'preview', access_token)
    backgroundRef.current = background
    
  }, [mediaData, elevation, draw, fullWidth, access_token])

  useEffect(() => {
    draw()
  }, [picked, temporaryCoord, draw])

  useEffect(() => {
    const canvas = canvasRef.current

    if (editable || navigateable) {
      canvas.addEventListener('mousedown', handleMouseDown)
      if (editable) {
        canvas.addEventListener('mousemove', handleMouseMove)
        canvas.addEventListener('mouseup', handleMouseUp)
        canvas.addEventListener('mouseout', handleMouseOut)
      }
    }
    else {
      canvas.removeEventListener('mousedown', handleMouseDown)
      canvas.removeEventListener('mousemove', handleMouseMove)
      canvas.removeEventListener('mouseup', handleMouseUp)
      canvas.removeEventListener('mouseout', handleMouseOut)
    }

    return () => {
      canvas?.removeEventListener('mousedown', handleMouseDown)
      canvas?.removeEventListener('mousemove', handleMouseMove)
      canvas?.removeEventListener('mouseup', handleMouseUp)
      canvas?.removeEventListener('mouseout', handleMouseOut)
    }
  }, [navigateable, editable, handleMouseDown, handleMouseMove, handleMouseUp, handleMouseOut])

  return (
    <Box>
      <canvas ref={canvasRef}></canvas>
    </Box>
  )
}

export default ElevationImage
