import { useMemo, useRef, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import {
  useMyUserId,
  useMyCompanyId,
  useGetUserByIdQuery,
  useGetSubscriptionsQuery,
} from '../api/accounts'

// Useful wrapper by react-router v5
// https://v5.reactrouter.com/web/example/query-parameters
export function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

// Get the Facilities 4.0 app role for the logged in user
export function useFacilitiesAppRoles() {
  const user_id = useMyUserId()
  const { data, error, isLoading } = useGetUserByIdQuery(user_id)

  if (!isLoading) {
    if (error) {
      console.log('Error loading user:', error)
      toast.error('Error loading user')
    } else if (data.status !== 'success') {
      console.log('Failed loading user:', data)
      toast.error('Failed loading user')
    }
  }
  return useMemo(() => data?.data?.user?.app_roles?.facilities || [], [data])
}

// Get the Account's Facilities 4.0 Subscription for the logged in user
// + guarantee a reverse chronological order of end dates
export function useFacilitiesSubscription() {
  const company_id = useMyCompanyId()
  const { data, error, isLoading } = useGetSubscriptionsQuery({
    app_key: 'facilities',
    company_id,
  }, { skip: !company_id })

  if (!isLoading) {
    if (error) {
      console.log('Error loading subscription:', error)
    } else if (data?.status !== 'success') {
      console.log('Failed loading subscription:', data)
    }
  }
  return useMemo(() => {
    const subs = data?.data?.subscriptions
    if (subs?.length > 0)
      return [...subs].sort((a, b) => b.end_date - a.end_date)
    return []
  }, [data])
}


export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}


export function useScript(src, id) {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (document.querySelector(id)) {
      return
    }
    // Create a script element
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.id = id

    // Set up a callback to be called when the script has loaded
    script.onload = () => {
      setScriptLoaded(true)
    }

    // Append the script to the document
    document.body.appendChild(script)

    // Clean up the script element when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
  }, [src, id])

  return scriptLoaded
}
