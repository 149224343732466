import { useCallback, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useQuery } from '../../../utils/hooks'

import { history } from '../../../utils/store'
import { dd_mmm_yyyyEpoch } from '../../../utils'

import NavBar from '../../shared/NavBar'
import Company from '../../shared/text/Company'
import { useAllFacilities }  from '../../shared/hooks/facilities'
import { useFacilityInspections } from '../../shared/hooks/inspections'
import { InspectionStatusIconWithToolTip } from '../../shared/Icons'
import MetaDialog           from '../../shared/MetaDialog'

import EmptyInspections     from './Components/EmptyInspections'
import DefaultControls      from './Components/DefaultControls'
import FilterControls       from './Components/FilterControls'
import InspectionActions    from './Components/InspectionActions'
import GenerateReportButton from './Components/GenerateReportButton'

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  AssignmentTurnedIn,
  CheckCircle,
  CheckCircleOutline,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    padding: theme.spacing(1),
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
  },
  gridContainer: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  name: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  select: {
    color: theme.palette.primary.main
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  total: {
    textAlign:    'center',
    fontSize:     14,
    color:        theme.palette.common.black,
  },
  iconBackground: {
    position: 'absolute',
    top:      '100px',
    right:    0,
    width:    '620px',
    height:   '620px',
    color:    theme.palette.grey[200],
    zIndex:   -100,
  },
})

function InspectionList({ classes }) {
  const query = useQuery()

  const { facilities  } = useAllFacilities()
  const { inspections } = useFacilityInspections({ facility_ids: query.get('facilityId') })
  
  const action = query.get('action')

  const [ imageCount,        setImageCount        ] = useState(0)
  const [ imageCountUpdated, setImageCountUpdated ] = useState(false)
  let _imageCount = 0
  let _imageRows = 0

  function updateImageArray (count) {
    _imageRows  = _imageRows  + 1
    _imageCount = _imageCount + count

    setImageCountUpdated(true)
    setImageCount(_imageCount)
  }
  const [ page,        setPage        ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ inspectionDetail, setInspectionDetail] = useState({
    openDialog: false,
    inspection: {},
    isUpdate: false
  })
  const [ selectEnabled, setSelectEnabled ] = useState(false)
  const [ selectedArray, setSelectedArray ] = useState([])

  const [ autoOpened, setAutoOpened ] = useState(false)

  const addToSelectedArr = (inspectionId) => {
    setSelectedArray(selectedArray => { return [...selectedArray, inspectionId] })
  }

  const removeToSelectedArr = (inspectionId) => {
    setSelectedArray((selectedArray) => {
      return selectedArray.filter(item => {
        if (item !== inspectionId) {
          return true
        }
        return false
      })
    })
  }

  const selectAllInspections = (select) => {
    if (select) {
      const idsInspections = inspections.map((item) => {
        return item._id
      })
      setSelectedArray(idsInspections)
    } else {
      setSelectedArray([])
    }
  }

  const handleNewInspection = useCallback(() => {
    const facs = query.get('facilityId') ? [query.get('facilityId')] : []
    setInspectionDetail({
      inspection: {
        name: '',
        type: '',
        start_date: '',
        end_date: '',
        status: 'draft',
        facilities: facs,
        personnel: [],
        notes: '',
        // cancel_reason: '',
        // properties: { purpose: '' }
      },
      isUpdate: false,
      openDialog: true,
      restrictFacility: !!query.get('facilityId')
    })
  }, [query])

  const handleClose = () => {
    setInspectionDetail({ ...inspectionDetail, openDialog: false })
  }

  const handlePageChange = (e, newPage) => {
    setSelectEnabled(false)
    setSelectedArray([])
    setPage(newPage)
    setImageCount(0)
    setImageCountUpdated(false)
  }

  const handleRowsPerPageChange = e => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
    setImageCount(0)
    setImageCountUpdated(false)
  }

  useEffect(() => {
    if (action === 'new'
        && handleNewInspection
        && !inspectionDetail.openDialog
        && !autoOpened) {
      handleNewInspection()
      setAutoOpened(true)
    }
  }, [action, handleNewInspection, inspectionDetail.openDialog, autoOpened])

  function facilityName(facilityId) {
    return facilities ? facilities[facilities.findIndex(facility => facility._id === facilityId)]?.name : '-'
  }

  const
    start = page * rowsPerPage,
    end = (page + 1) * rowsPerPage,
    inspectionsPerPage = inspections.slice(start, inspections.length > end ? end : inspections.length)

  const facilityIds = [...new Set(inspections.map(inspection => inspection.facilities[0]))]
  const inspectionsManagedBy = facilities
    .filter(facility => facilityIds.includes(facility._id))
    .map(f => f.company_id)
    .filter((c, i, self) => self.indexOf(c) === i)
    .map(c => <Company key={c} id={c} name />)
    .reduce((acc, x) => acc === null ? [x] : [acc, ', ', x], null)

  // console.log('inspections', inspections)

  return (<>
    <NavBar
      subtitleOverline='inspections managed by'
      subtitle={inspectionsManagedBy}
    />
    <div className={classes.container}>
      <Grid container className={classes.gridContainer}>
        <Grid item>
          { inspections.length > 0 &&
            <Typography variant='h4'>
              { query.get('facilityId')
                ? `${facilityName(query.get('facilityId'))}'s Inspections`
                : 'My Inspections'
              }
            </Typography>
          }
        </Grid>
        <Grid item>
          { selectEnabled ?
            <FilterControls
              onSelectAll={() => { selectAllInspections(true) }}
              onUnselectAll={() => { selectAllInspections(false) }}
              onExitSelect={() => {
                setSelectEnabled(false)
                setSelectedArray([])
              }}
            />
          : <DefaultControls
              inspections={inspections}
              onNew={handleNewInspection}
              onSelect={() => { setSelectEnabled(true) }}
            />
          }
        </Grid>
      </Grid>
      { inspections.length === 0 &&
        <EmptyInspections />
      }
      { (selectedArray.length > 0) &&
        <Grid container direction='row' justifyContent='flex-end' spacing={3}>
          <Grid item>
            <Typography variant='body1'>
              { selectedArray.length || 0 } inspection(s) selected
            </Typography>
          </Grid>
          <Grid item>
            <GenerateReportButton
              inspectionIdArray={selectedArray}
              onSuccess={() => {
                setSelectEnabled(false)
                setSelectedArray([])
                toast.success('Report will be sent to your email')
              }}
              onError={(message) => {toast.error(`Failed. ${message}`)}}
            />
          </Grid>
        </Grid>
      }
      { inspections.length > 0 &&
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width='10%'>Status</TableCell>
                <TableCell width='15%'>Start</TableCell>
                <TableCell width='15%'>End</TableCell>
                <TableCell>Inspection Name</TableCell>
                <TableCell width='20%'>Images / Detections / Reports</TableCell>
                { selectEnabled &&
                  <TableCell width='8%'>Select<br />Inspections</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              { inspectionsPerPage.map((inspection, index) => (
                <TableRow key={inspection._id}>
                  <TableCell>
                    <InspectionStatusIconWithToolTip status={inspection.status} />
                  </TableCell>
                  <TableCell>
                    <Typography variant='body1'>
                      {dd_mmm_yyyyEpoch(inspection.start_date)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body1'>
                      {dd_mmm_yyyyEpoch(inspection.end_date)}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => history.push(`/inspection/${inspection._id}`)}>
                    <Typography variant='body1' className={classes.name}>
                      {inspection.name}
                    </Typography>
                    <Typography variant='body2'>
                      {facilityName(inspection.facilities[0])}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <InspectionActions
                      inspection_id={inspection._id}
                      updateImageArray={updateImageArray}
                      imageCountUpdated={imageCountUpdated}
                    />
                  </TableCell>
                  { selectEnabled &&
                    <TableCell>
                      <div style={{ padding: '2px', textAlign:'center' }}>
                        { selectedArray.includes(inspection._id) ?
                          <CheckCircle
                            className={classes.select}
                            onClick={() => { removeToSelectedArr(inspection._id) }}
                          />
                        : <CheckCircleOutline
                            className={classes.select}
                            onClick={() => { addToSelectedArr(inspection._id) }}
                          />
                        }
                      </div>
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell width='10%'></TableCell>
                <TableCell width='15%'></TableCell>
                <TableCell width='15%'></TableCell>
                <TableCell></TableCell>
                <TableCell width='20%'>
                  <p className={classes.total}>
                    Total images in the current view {imageCount}
                  </p>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <TablePagination
            component='div'
            count={inspections.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      }
      { inspectionDetail.openDialog && (
        <MetaDialog isUpdate={false}
          open={inspectionDetail.openDialog}
          onClose={handleClose}
          restrictFacility={inspectionDetail.restrictFacility}
          inspection={inspectionDetail.inspection}
        />
      )}
    </div>
    <AssignmentTurnedIn className={classes.iconBackground} />
  </>)
}

export default withStyles(styles)(InspectionList)
