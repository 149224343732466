
import {
  Chip,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core'
import {
  Close,
} from '@material-ui/icons'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  drawer: {
    width:   '350px',
    padding: theme.spacing(2),
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  chip: {
    marginBottom: '5px',
    marginRight: '5px'
  },
  formControlLabel: {
    marginLeft: 0,
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiFormControlLabel-label': {
      flexGrow: 1,
    },
  },
})

function GalleryFilterDrawer({
  classes,
  open,
  onClose,

  elevations,
  elevationFilter,
  onElevationFilter,

  favouriteFilter,
  setFavouriteFilter,

  showHiddenFilter,
  setShowHiddenFilter,
}) {
  return (
    <Drawer open={open} anchor='left' onClose={onClose}>
      <Grid container spacing={2} className={classes.drawer}>
        <Grid item xs={12}>
          <Typography variant='h6'>Filters</Typography>
          <IconButton onClick={onClose} className={classes.close}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Chip className={classes.chip} label='All'
                onClick={() => setFavouriteFilter(false)} clickable
                color={!favouriteFilter ? 'primary' : 'default'}/>
          <Chip className={classes.chip} label='Favourites Only'
                onClick={() => setFavouriteFilter(true)} clickable
                color={favouriteFilter ? 'primary' : 'default'}/>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1'><b>Elevation</b></Typography>
        </Grid>
        <Grid item xs={12}>
          { elevations.map((elevation, index) => (
            <Chip key={index} className={classes.chip} label={elevation.name}
                  onClick={() => onElevationFilter(elevation._id)} clickable
                  color={elevationFilter.includes(elevation._id) ? 'primary' : 'default'}/>
          )) }
          { elevations.length === 0 &&
            <Typography>No elevations available</Typography>
          }
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1'><b>Images</b></Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            className={classes.formControlLabel}
            checked={showHiddenFilter}
            onClick={() => setShowHiddenFilter(!showHiddenFilter)}
            control={<Switch color='primary' />}
            label='Show Hidden Images'
            labelPlacement='start'
          />
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default withStyles(styles)(GalleryFilterDrawer)
