import {
  Container,
  Typography,
} from '@material-ui/core'
import LogoBanner from '../shared/LogoBanner'

function Loading({ message }) {
  return (
    <Container maxWidth='md'>
      <LogoBanner />
      <br />
      <Typography variant='body1'>{ message || 'Loading ...' }</Typography>
    </Container>
  )
}

export default Loading
