
import {
  Grid,
} from '@material-ui/core'

import DirectoryCard  from './components/DirectoryCard'

function DirectoryGrid({ directory, company_id, facilityUpdatingImageId }) {

  return (
    <Grid container spacing={2}>
      { directory.map(facility => (
        <Grid item md={3} sm={4} xs={12} key={facility._id}>
          <DirectoryCard facility={facility}
            ownFacility={facility.company_id === company_id}
            facilityUpdatingImageId={facilityUpdatingImageId} />
        </Grid>
        ))
      }
    </Grid>
  )
}

export default DirectoryGrid