import { allInspectionTypes } from '../../../../utils/enums'

import Image from '../../../shared/Image'
import {
  Typography,
} from '@material-ui/core'
import {
  FacilityTypeIcon,
} from '../../../shared/Icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  facility: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  facImg: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
})

function FacilityPreview({ classes, facility, inspection }) {
  return (
    <div className={classes.facility}>
      { facility.profile_image ?
        <Image media_id={facility.profile_image} size='preview' alt='fac'
          className={classes.facImg} />
      :
        <FacilityTypeIcon type={facility.type} className={classes.facImg} />
      }
      <div>
        <Typography variant='body2'>
          {inspection.type ? allInspectionTypes[inspection.type]?.label : 'Unknown inspection type'}
        </Typography>
        <Typography variant='h6'>
          {facility.name || 'Untitled Facility'}
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(FacilityPreview)