import TopXCloseButton from '../../../shared/TopXCloseButton'
import {
  Dialog,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Typography,
} from '@material-ui/core'

function DownloadDialog({
  dlState,
  dlCount,
  dlTotal,
  onClose,
  message,
  onDownload,
}) {
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={dlState !== 'stop'}
      onClose={onClose}>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>
        { dlState === 'prepare'  ? 'Download Originals' :
          dlState === 'download' ? 'Download Originals in progress' : ''
        }
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {message}
        </Typography>
        <Box display='flex' alignItems='center'>
          { dlState === 'download' && <>
            <Box width='100%' mr={1}>
              <LinearProgress
                variant='determinate'
                value={dlCount / dlTotal * 100}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant='body2' color='textSecondary'>
                {dlCount} / {dlTotal}
              </Typography>
            </Box>
          </> }
        </Box>
      </DialogContent>
      <DialogActions>
        { dlState === 'prepare' &&
          <Button
            autoFocus
            variant='contained'
            color='primary'
            onClick={onDownload}>
            Continue
          </Button>
        }
      </DialogActions>
     </Dialog>
   )
 }

 export default DownloadDialog
