import {
  Typography,
} from '@material-ui/core'
import {
  PictureInPicture,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(0, 2, 8, 4),
  },
  subtitle: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})

function EmptyDetection({ classes }) {

  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>
        <PictureInPicture className={classes.icon} />
        <Typography variant='h6'>
          No Detections Found
        </Typography>
      </div>
      <Typography variant='h3' gutterBottom color='primary'>
        Let's Get Started
      </Typography>
      <Typography variant='h5' gutterBottom>Manually annotate defects</Typography>
      <Typography variant='body1' gutterBottom>
        To manually annotate defect, you will need to use an offline annotation
        tool. Get your originals handy, and click LAUNCH OFFLINE ANNOTATE to get started.
      </Typography>
      <Typography variant='body1' gutterBottom>
        Once you have completed the manual annotations, upload them by clicking
        UPLOAD OFFLINE ANNOTATIONS.
      </Typography>
      <br />
      <br />
      <Typography variant='h5' gutterBottom>Automatically annotate defects</Typography>
      <Typography variant='body1' gutterBottom>
        You account allows for detection using FaultFinder&trade; AI algorithm.
        Click LABEL USING AI ALGORITHM to get started.
      </Typography>
    </div>

  )
}


export default withStyles(styles)(EmptyDetection)
