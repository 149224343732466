import { useEffect } from 'react'
import {
  Chip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  sectionContainer: {
    marginTop:    theme.spacing(2),
    marginBottom: theme.spacing(4),
    minHeight:    theme.spacing(8),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(1),
    border:       '1px dashed black',
    overflow:     'hidden',
  },
  elevationChip: {
    marginRight: theme.spacing(2)
  },
})

function TagElevations({ classes, elevations, tags, setTags, onChange, facilityId }) {

  const handleClick = (elevation) => {
    if (tags.includes(elevation)) {
      setTags(prevState => prevState.filter(tag => tag !== elevation))
    } else {
      setTags(prevState => [elevation])
    }
  }

  useEffect(() => {
    onChange && onChange()
  }, [tags, onChange])

  return (
    <div className={classes.sectionContainer}>
      { elevations.length > 0 ?
        elevations.map((elevation, i) => (
          <Chip key={i}
            label={elevation.name}
            onClick={() => handleClick(elevation._id)}
            clickable
            color={tags.includes(elevation._id) ? 'primary' : 'default'}
            className={classes.elevationChip} />
        ))
      : <Typography variant='body2'>
          No elevations found. Contact the Facility Manager to add elevations.
        </Typography>
      }
    </div>
  )
}

export default withStyles(styles)(TagElevations)
