import {
  Container,
  Typography,
} from '@material-ui/core'
import LogoBanner from './LogoBanner'

function NotFound() {
  return (
    <Container maxWidth='md'>
      <LogoBanner />
      <br />
      <Typography variant='h5'>Page Not Found.</Typography>
    </Container>
  )
}

export default NotFound
