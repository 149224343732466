import { useRef, useEffect, useState } from 'react'
import { useMediaURL } from '../../api/media'

// Either give src, or give media_id and size
function LabelledImage({ imageSrc, media_id, size, labels, scaleWidth = 260, color = 'cyan', onImageLoad, zoomLevel = 1, enablePan = false }) {
  const media_url = useMediaURL(media_id, size)
  const canvasRef = useRef(null)
  const backgroundRef = useRef(null)

  // State variables for panning
  const [panX, setPanX] = useState(0)
  const [panY, setPanY] = useState(0)
  const [dragging, setDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)

  useEffect(() => {
    const lineWidth = Math.floor(Math.log10(scaleWidth - 1)) // thumb 1 preview 2 fullscreen 3
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const background = new Image()

    function drawRectangle(x, y, w, h) {
      context.beginPath()
      context.lineWidth = lineWidth
      context.strokeStyle = color
      context.rect(x, y, w, h)
      context.stroke()
    }

    function drawPolygon(polygon) {
      context.beginPath()
      context.lineWidth = lineWidth
      context.strokeStyle = color
      context.moveTo(polygon[polygon.length - 1][0], polygon[polygon.length - 1][1])
      for (const [x, y] of polygon) {
        context.lineTo(x, y)
      }
      context.stroke()
    }

    function draw() {
      const canvasWidth = scaleWidth * zoomLevel
      const imgWidth = backgroundRef.current.naturalWidth
      const imgHeight = backgroundRef.current.naturalHeight
      const canvasHeight = (imgHeight / imgWidth) * canvasWidth

      canvas.width = canvasWidth
      canvas.height = canvasHeight

      // Clear canvas
      context.clearRect(0, 0, canvasWidth, canvasHeight)

      // Apply pan transformation
      context.translate(panX, panY)

      context.drawImage(backgroundRef.current, 0, 0, canvasWidth, canvasHeight)

      labels.forEach(params => {
        const { shape, imgW, imgH } = params
        const imgW_ = scaleWidth * zoomLevel
        const imgH_ = imgW_ * imgH / imgW

        if (shape === 'yolo-poly') {
          const { polygon } = params
          const polygon_ = polygon.map(([x, y]) => ([ x * imgW_, y * imgH_ ]))
          drawPolygon(polygon_)
        }
        else if (shape === 'yolo-bbox') {
          const { x, y, w, h } = params // x, y is center
          const x_ = Math.round((x - (w / 2)) * imgW_)
          const y_ = Math.round((y - (h / 2)) * imgH_)
          const w_ = Math.round(w * imgW_)
          const h_ = Math.round(h * imgH_)
          drawRectangle(x_, y_, w_, h_)
        }
        else { // shape === 'rect'
          const { x, y, w, h } = params // x, y is top left
          const x_ = Math.round(x * imgW_ / imgW)
          const y_ = Math.round(y * imgH_ / imgH)
          const w_ = Math.round(w * imgW_ / imgW)
          const h_ = Math.round(h * imgH_ / imgH)
          drawRectangle(x_, y_, w_, h_)
        }
      })

      context.setTransform(1, 0, 0, 1, 0, 0) // Reset transformation

      // Call the passed onImageLoad function if available
      if (onImageLoad && typeof onImageLoad === 'function') {
        onImageLoad()
      }
    }

    // Event listeners for panning
    function handleMouseDown(event) {
      if (enablePan) {
        setDragging(true)
        setStartX(event.clientX - panX)
        setStartY(event.clientY - panY)
      }
    }

    function handleMouseMove(event) {
      if (dragging) {
        setPanX(event.clientX - startX)
        setPanY(event.clientY - startY)
        draw()
      }
    }

    function handleMouseUp() {
      setDragging(false)
    }

    canvas.addEventListener('mousedown', handleMouseDown)
    canvas.addEventListener('mousemove', handleMouseMove)
    canvas.addEventListener('mouseup', handleMouseUp)

    background.onload = () => {
      draw()
      // Call the passed onImageLoad function if available
      if (onImageLoad && typeof onImageLoad === 'function') {
        onImageLoad()
      }
    }

    background.src = imageSrc || media_url
    backgroundRef.current = background

    // Cleanup event listeners
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown)
      canvas.removeEventListener('mousemove', handleMouseMove)
      canvas.removeEventListener('mouseup', handleMouseUp)
    }

  }, [imageSrc, media_url, labels, color, scaleWidth, onImageLoad, zoomLevel, panX, panY, dragging, startX, startY, enablePan])

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
    </div>
  )
}

export default LabelledImage
