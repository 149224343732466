import { useEffect, useRef } from 'react'
import {
  Button,
  Fab,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  Add,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  floatRight: {
    float: 'right',
  },
  sectionContainer: {
    marginTop:    theme.spacing(2),
    marginBottom: theme.spacing(4),
    minHeight:    theme.spacing(8),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(1),
    border:       '1px dashed black',
    overflow:     'hidden',
  },
})

function FileDropArea({ classes, files, setFiles, onChange }) {

  const inputFileRef = useRef(null)

  function handleChooseFiles() {
    inputFileRef.current.click()
  }

  function handleRemoveFile(removedFileIndex) {
    let curFiles = [...files]
    curFiles.splice(removedFileIndex, 1)
    setFiles(curFiles)
  }

  function handleAddFile(selectedFiles) {
    const curFiles = []
    curFiles.push.apply(curFiles, selectedFiles)
    setFiles(prevState => ([...prevState, ...curFiles]))
  }

  function handleFileChosen(e) {
    const selectedFiles = e.target.files
    handleAddFile(selectedFiles)
    e.target.value = ''
  }

  function handleFilesDrop(e) {
    e.preventDefault()
    const selectedFiles = e.dataTransfer.files
    handleAddFile(selectedFiles)
  }

  function handleDragOver(e) {
    e.preventDefault()
  }

  useEffect(() => {
    onChange && onChange()
  }, [files, onChange])

  return (
    <div className={classes.sectionContainer}
      onDrop={e => handleFilesDrop(e)}
      onDragOver={e => handleDragOver(e)}>
      <Tooltip title='Choose Files'>
        <Fab size='medium' color='primary' onClick={handleChooseFiles} className={classes.floatRight}>
          <Add />
        </Fab>
      </Tooltip>
      <input type='file' onChange={handleFileChosen} hidden multiple ref={inputFileRef} />
      { files.length > 0 ?
        <ol>
          {files.map((file, idx) => (
            <li key={idx}><Typography>{file.name}
              <Button size='small' color='secondary' onClick={() => handleRemoveFile(idx)}>
                Remove
              </Button>
            </Typography></li>
          ))}
        </ol>
        : <Typography variant='body2'>No files chosen.</Typography>
      }
    </div>
  )
}

export default withStyles(styles)(FileDropArea)
