//
// TODO: Load tiles metadata dynamically from Map Service
//       For now OSM is compulsory, everything else optional
//
const OSM_SERVER = process.env.REACT_APP_OSM_TILE_SERVER
if (!OSM_SERVER)
  throw Error('Environment Variable REACT_APP_OSM_TILE_SERVER is missing')

const OSM_ATTRIBUTION = process.env.REACT_APP_OSM_TILE_ATTRIBUTION
if (!OSM_ATTRIBUTION)
  throw Error('Environment Variable REACT_APP_OSM_TILE_ATTRIBUTION is missing')

const ONEMAP_SERVER = process.env.REACT_APP_ONEMAP_TILE_SERVER
const ONEMAP_ATTRIBUTION = process.env.REACT_APP_ONEMAP_TILE_ATTRIBUTION

// Add further useful tiles here //

//
// TODO: Dynamically change default map center based on customer's home location
//
const defaultMapCenter = process.env.REACT_APP_DEFAULT_MAP_CENTER
const DEFAULT_MAP_CENTER = defaultMapCenter?.split(',') || [ 1.29027, 103.851959 ]

export {
  OSM_SERVER,
  OSM_ATTRIBUTION,
  ONEMAP_SERVER,
  ONEMAP_ATTRIBUTION,
  DEFAULT_MAP_CENTER,
}
