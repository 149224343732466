import { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import Image from '../../shared/Image'
import {
  useGetImagesQuery,
  useLazyGetImagesQuery,
  useFavouriteImagesMutation,
  useUnfavouriteImagesMutation,
  useHideImagesMutation,
  useUnhideImagesMutation,
  useGetElevationsQuery,
  useGetElevationByIdQuery,
} from '../../../api/inspectionOps'

import EmptyGallery    from './Components/EmptyGallery'
import ImageDialog     from './Components/ImageDialog'
import GalleryControls from './Components/GalleryControls'
import AnnotateDefectDialog from '../Detections/Components/AnnotateDefectDialog'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  CheckCircle,
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  Favorite,
  FavoriteBorder,
  PhotoLibrary,
  VisibilityOff,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    marginTop: '8px',
    padding: theme.spacing(1)
  },
  wait: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pagination: {
    position: 'fixed',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardContainer: {
    maxWidth: '260px',  // preview size cards
  },
  cardMedia: {
    width:          '100%',
    maxHeight:      '147px', // assume to be landscape 16:9 (bad assumption, to review)
    overflow:       'hidden',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
  },
  iconBackground: {
    position: 'absolute',
    top:      '100px',
    right:    0,
    width:    '620px',
    height:   '620px',
    color:    theme.palette.grey[200],
    zIndex:   -100,
  },
  rightFloatingChips: {
    float: 'right',
    margin: theme.spacing(1)
  },
  selectIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
  },
  favouriteIconEnabled: {
    color: theme.palette.favourites.main,
    padding: theme.spacing(1),
    borderRadius: '50%',
    // transform: 'translate(25%, 50%)',
    fontSize: '2.5rem',
  },
  favouriteIconDisabled: {
    color: theme.palette.favourites.inactive,
    padding: theme.spacing(1),
    borderRadius: '50%',
    fontSize: '2.5rem',
  },
  imageCardContentArea: {
    padding: 0,
  },
  favIconButton: {
    margin: 0,
    padding: 0
  },
  hiddenOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '147px',  // match existing value for cardMedia for now
    backgroundColor:'rgba(255, 255, 255, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hiddenIcon: {
    fontSize: theme.spacing(10),
    color: theme.palette.grey[500],
    position: 'absolute',
  },
})

const IMAGES_PER_PAGE = 100

const Gallery = ({
  classes,
  facilityId,
  facilities,
  inspection,
  inspections,
  facilityName,
  facilityType,
}) => {
  const inspectionId = inspection?._id

  const [ page,             setPage             ] = useState(1) // Page Numbers to be 1 based
  const [ sort,             setSort             ] = useState('exif_created_time_asc')

  const [ elevationFilter,  setElevationFilter  ] = useState([])
  const [ favouriteFilter,  setFavouriteFilter  ] = useState(false)
  const [ showHiddenFilter, setShowHiddenFilter ] = useState(false)

  const [ multiSelect,      setMultiSelect      ] = useState(false)
  const [ imageArraySelect, setImageArraySelect ] = useState([])

  const [ noImagesUploaded, setNoImagesUploaded ] = useState(false)
  const [ noImagesUploadedLoading, setNoImagesUploadedLoading ] = useState(true)

  const [ favouriteImage ] = useFavouriteImagesMutation()
  const [ unfavouriteImage ] = useUnfavouriteImagesMutation()

  const [ hideImage ] = useHideImagesMutation()
  const [ unhideImage ] = useUnhideImagesMutation()

  const { data, error, isLoading } = useGetImagesQuery({
    inspection_ids: [inspectionId],
    page:         page,
    limit:        IMAGES_PER_PAGE,
    sort:         sort,
    elevations:   elevationFilter,
    favourite:    favouriteFilter ? true : null,
    hidden:       showHiddenFilter ? null : false,
  }, {
    skip: !inspectionId
  })

  const { data: elevData } = useGetElevationsQuery({
    facility_id: facilityId,
  }, {
    skip: !facilityId
  })

  // For determining if there are any images without filtering - temporary fix
  const [ getImages ] = useLazyGetImagesQuery()

  if (!isLoading) {
    if (error) {
      console.log('Error loading images', error)
      toast.error('Error loading images')
    } else if (!data.status === 'success') {
      console.log('Failed loading images', data)
      toast.warning('Failed loading images')
    }
  }

  const imageArray = useMemo(() => (data?.data?.images || []), [data])

  useEffect(() => {
    if (imageArray?.length > 0)
      setImageArraySelect(new Array(imageArray.length).fill(false))
    else
      setImageArraySelect([])
  }, [imageArray])

  const pagination = data?.data?.pagination || {
    total:        1,
    current_page: 1,
    last_page:    1,
    limit:        1,
    from:         0,
    to:           0,
  }

  const elevations = elevData?.data?.elevations || []

  const [ imageDialogOpen,    setImageDialogOpen    ] = useState(false)
  const [ selectedImageIndex, setSelectedImageIndex ] = useState(-1)
  const [ annotateDialogOpen, setAnnotateDialogOpen ] = useState(false)

  const [ lastSelectionAction, setLastSelectionAction ] = useState({
    isSelect: true, 
    lastIdx: 0 
  })

  function applySort(e) {
    setSort(e.target.value)
    setPage(1)
  }

  const handlePrev = () => {
    if (page > 1)
      setPage(page - 1)
  }
  const handleNext = () => {
    if (page < pagination.last_page)
      setPage(page + 1)
  }

  useEffect(() => {
    if (isLoading)
      return

    if (!noImagesUploadedLoading) 
      return

    getImages({
      inspection_ids: [inspectionId],
      page:         1,
      limit:        1,
      sort:         sort,
    }).unwrap()
    .then(res => {
      if (res.data.images.length === 0) {
        setNoImagesUploaded(true)
      } else {
        setNoImagesUploaded(false)
      }
      setNoImagesUploadedLoading(false)
    })
  }, [getImages, inspectionId, noImagesUploadedLoading, sort, imageArray?.length, isLoading])

  if (isLoading || noImagesUploadedLoading) {
    return (
      <div className={classes.root}>
        <div className={classes.wait}>
          <CircularProgress />
          <Typography>Loading images...</Typography>
        </div>
        <PhotoLibrary className={classes.iconBackground} />
      </div>
    )
  }

  if (noImagesUploaded) {
    return (
      <div className={classes.root}>
        <GalleryControls inspection={inspection} />
        <EmptyGallery />
        <PhotoLibrary className={classes.iconBackground} />
      </div>
    )
  }

  function handleCardClick(imageIndex) {
    setSelectedImageIndex(imageIndex)
    setImageDialogOpen(true)
  }

  async function handleSetFavourites(imageIds, value) {
    const isMultiple = imageIds.length > 1
    try {
      let res
      if (value) {
        res = await favouriteImage(imageIds).unwrap()
      } else {
        res = await unfavouriteImage(imageIds).unwrap()
      }
      if (res.status === 'success') {
        toast.success(`Image${isMultiple ? 's' : ''} `
                      + `${value ? 'added to' : 'removed from'} favourites`)
      } else {
        toast.error(`Failed to ${value ? 'add images to' : 'remove images from'} favourites`)
      }
    } catch {
      toast.error(`Error ${value ? 'adding images to' : 'removing images from'} favourites`)
    }
  }

  async function handleHideImages(imageIds, value) {
    const isMultiple = imageIds.length > 1
    try {
      let res
      if (value) {
        res = await hideImage(imageIds).unwrap()
      } else {
        res = await unhideImage(imageIds).unwrap()
      }
      if (res.status === 'success') {
        toast.success(`Image${isMultiple ? 's' : ''} `
                      + `${value ? 'hidden' : 'no longer hidden'}`)
      } else {
        toast.error(`Failed to ${value ? 'hide' : 'unhide'} images`)
      }
    } catch {
      toast.error(`Error ${value ? 'hiding' : 'unhiding'} images`)
    }
  }

  // Calls the respective function for single or multiple images with imageIds
  // and a value
  async function multiAction(action) {
    const selectedImages = imageArray.filter((img, i) => imageArraySelect[i])
    const imageIds       = selectedImages.map(img => img._id)
    await action(imageIds)
  }

  const favouriteButton = ({ classes, value, setValue }) => {
    function handleClick(e) {
      e.stopPropagation()
      setValue(!value)
    }

    function handleMouseDown(e) {
      e.stopPropagation()
    }

    return (
      <Box position='absolute' bottom={56} right={18} zIndex={2}>
        <IconButton onClick={handleClick} onMouseDown={handleMouseDown}
                    className={classes.favIconButton}>
          { value
            ? <Favorite className={classes.favouriteIconEnabled} fontSize='large' />
            : <FavoriteBorder className={classes.favouriteIconDisabled} fontSize='large' />
          }
        </IconButton>
      </Box>
    )
  }



  const imageCard = (image, i) => (
    <Card className={classes.cardContainer}>
      <CardActionArea onClick={() => handleCardClick(i)}>
        <CardMedia title={image.media_id} className={classes.cardMedia}>
          { multiSelect && image.hidden && (
            <div className={classes.hiddenOverlay}>
              <VisibilityOff className={classes.hiddenIcon} />
            </div>
          )}
          <Image media_id={image.media_id} size='preview' alt={image.filename} />
          { multiSelect && favouriteButton({ classes, value: image.favourite, setValue: (value) => handleSetFavourites([image._id], value) }) }
          { multiSelect ? (imageArraySelect[i] ? selectedIcon(i) : notSelectedIcon(i)) : null}
        </CardMedia>
        <CardContent className={classes.imageCardContentArea}>
          { image.facility_elevations?.map((elevationId, i) =>
            <ElevationChip key={i} elevationId={elevationId} className={classes.rightFloatingChips} />) }
            {/* <Chip key={i} label={elevation} className={classes.rightFloatingChips} />) } */}
          <Typography variant='body2' color='textSecondary' style={{ padding: '16px' }}>
            {image.filename || '-'}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )

  const handleToggleMultiSelect = (prevIdx, clickedIdx, isSelect) => {
    const lowerIdx = Math.min(prevIdx, clickedIdx)
    const upperIdx = Math.max(prevIdx, clickedIdx)
    setImageArraySelect(prevArray => {
      const nextArray = [ ...prevArray ]

      const swapMode = isSelect ? nextArray[clickedIdx] : !nextArray[clickedIdx]
      for (let i = lowerIdx; i <= upperIdx; i++) {
        if ((isSelect && !swapMode) || (!isSelect && swapMode)) {
          nextArray[i] = true
        } else {
          nextArray[i] = false
        }
      }
      return nextArray
    })
  }

  const selectedIcon = (i) => <Avatar
    className={classes.selectIcon}
    onClick={e => {
      e.stopPropagation()
      console.log('selection event', e)
      if (e.shiftKey) {
        // const { isSelect, lastIdx } = lastSelectionAction
        const isSelect = lastSelectionAction.isSelect
        const lastIdx = lastSelectionAction.lastIdx
        handleToggleMultiSelect(lastIdx, i, isSelect)
      } else {
        setImageArraySelect(prevArray => {
          const nextArray = [ ...prevArray ]
          nextArray[i] = false
          return nextArray
          })
        }
        setLastSelectionAction({ isSelect: false, lastIdx: i })
      }
    }
    onMouseDown={e => e.stopPropagation()}>
      <CheckCircle />
  </Avatar>

  const notSelectedIcon = (i) => <Avatar
    className={classes.selectIcon}
    onClick={e => {
      e.stopPropagation()
      console.log('selection event', e)
      if (e.shiftKey) {
        // const { isSelect, lastIdx } = lastSelectionAction
        console.log('lastSelectionAction', lastSelectionAction)
        const isSelect = lastSelectionAction.isSelect
        const lastIdx = lastSelectionAction.lastIdx
        handleToggleMultiSelect(lastIdx, i, isSelect)
      } else { 
        setImageArraySelect(prevArray => {
          const nextArray = [ ...prevArray ]
          nextArray[i] = true
          return nextArray
        })
      }
      setLastSelectionAction({ isSelect: true, lastIdx: i })
    }}
    onMouseDown={e => e.stopPropagation()}>
    <CheckCircleOutline />
  </Avatar>

  const selectAll = (all) => {
    setImageArraySelect(prevArray => {
      if (all)
        return new Array(imageArray.length).fill(true)
      else
        return new Array(imageArray.length).fill(false)
    })
  }

  const paginationWidget = <div className={classes.pagination}>
    <Button variant='contained'
      color='primary'
      disabled={pagination.current_page === 1}
      startIcon={<ChevronLeft />}
      onClick={handlePrev}>
      Prev
    </Button>
    <div style={{ padding: '4px 16px', backgroundColor: '#eeeeee' }}>
      Page {pagination.current_page} of {pagination.last_page}
    </div>
    <Button variant='contained'
      color='primary'
      disabled={pagination.current_page === pagination.last_page}
      endIcon={<ChevronRight />}
      onClick={handleNext}>
      Next
    </Button>
  </div>

  return (
    <div className={classes.root}>
      <GalleryControls
        inspection={inspection}
        sort={sort}
        onSort={applySort}
        elevations={elevations}
        elevationFilter={elevationFilter}
        setElevationFilter={setElevationFilter}
        favouriteFilter={favouriteFilter}
        setFavouriteFilter={setFavouriteFilter}
        showHiddenFilter={showHiddenFilter}
        setShowHiddenFilter={setShowHiddenFilter}
        multiSelect={multiSelect}
        setMultiSelect={setMultiSelect}
        multiFavourite={() => multiAction(ids => handleSetFavourites(ids, true))}
        multiUnfavourite={() => multiAction(ids => handleSetFavourites(ids, false))}
        multiHide={() => multiAction(ids => handleHideImages(ids, true))}
        multiUnhide={() => multiAction(ids => handleHideImages(ids, false))}
        selectAll={selectAll}
        imageArray={imageArray}
        imageArraySelect={imageArraySelect}
        hasUploadedImages={!noImagesUploaded}
        facilityName={facilityName}
      />
      <br />
      <Grid container spacing={1}>
          { imageArray?.map((image, i) => (
            <Grid item xl={2} key={i} style={{ position: 'relative' }}>
              { imageCard(image, i) }
            </Grid>
          ))}

          { imageArray?.length === 0 && (
            <EmptyGallery hideUploadMessage={true} />
          )}
      </Grid>
      <br /><br />
      { !multiSelect && paginationWidget }
      <PhotoLibrary className={classes.iconBackground} />
      { selectedImageIndex >= 0 &&
        <>
          <ImageDialog
            mediaId={imageArray[selectedImageIndex]?.media_id}
            open={imageDialogOpen}
            onPrev={() => setSelectedImageIndex(selectedImageIndex > 0 ? selectedImageIndex - 1 : selectedImageIndex) }
            onNext={() => setSelectedImageIndex(selectedImageIndex < imageArray.length - 1 ? selectedImageIndex + 1 : selectedImageIndex) }
            onClose={() => setImageDialogOpen(false)}
            onAnnotate={() => {
              setImageDialogOpen(false)
              setAnnotateDialogOpen(true)
            }}
          />
          <AnnotateDefectDialog
            open={annotateDialogOpen}
            onClose={() => setAnnotateDialogOpen(false)}
            inspectionId={inspectionId}
            facilityType={facilityType}
            selectedImage={imageArray[selectedImageIndex]}
          />
        </>
      }
    </div>
  )
}

const ElevationChip = ({ className, elevationId }) => {
  const { data, error, isLoading } = useGetElevationByIdQuery(elevationId)
  if (error) console.error(error)
  return (
    isLoading
    ? <Chip label='Loading...' className={className} />
    : <Chip key={elevationId} label={data?.data?.elevation?.name} className={className} />
  )
}

export default withStyles(styles)(Gallery)
