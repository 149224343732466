import { useState } from 'react'
import {
  Box,
  Button,
  withStyles
} from '@material-ui/core'

import Image from '../../../shared/Image'
import ProfileMinimap from './ProfileMinimap'

import defaultBuildingProfileImage  from '../../../../assets/png/facility_placeholder_building.png'
import defaultPowerlineProfileImage from '../../../../assets/png/facility_placeholder_powerline.png'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    minHeight: theme.spacing(30),
    
    // For map
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),

    marginBottom: theme.spacing(2),
  },
  button: {
    width: '50%',
    borderRadius: 0,
    zIndex: 1,
    opacity: 1,
  },
  buttonLeft: {
    borderBottomLeftRadius: theme.spacing(0.5),
  },
  buttonRight: {
    borderBottomRightRadius: theme.spacing(0.5),
  },
  profileImage: {
    width: '100%',
    height: 'fill-available',
    maxHeight: '100%',
    objectFit: 'cover',

    // For image
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
})

const ProfileGraphic = ({ classes, facility }) => {
  const [ mode, setMode ] = useState('map')

  return (
    <div className={classes.root}>
      <Box display='flex' flex='1'>
        { mode === 'map' && (
          <ProfileMinimap facility={facility} />
        )}
        { mode === 'image' && (
          facility.profile_image ?
            <Image alt={facility.name} className={classes.profileImage}
              media_id={facility.profile_image} size='fullscreen' />
          : <Image alt={facility.name} className={classes.profileImage}
              src={facility.type === 'powerline' ?
                defaultPowerlineProfileImage :
                defaultBuildingProfileImage} />
        )}
      </Box>

      <Box display='flex' flexDirection='row' className={classes.buttons}>
        <Button
          className={classes.button + ' ' + classes.buttonLeft}
          color='primary'
          variant={mode === 'map' ? 'contained' : 'outlined'}
          onClick={() => setMode('map')}
        >
            Map
        </Button>
        <Button
          className={classes.button + ' ' + classes.buttonRight}
          color='primary'
          variant={mode === 'image' ? 'contained' : 'outlined'}
          onClick={() => setMode('image')}
        >
          Image
        </Button>
      </Box>
    </div>
  )
}

export default withStyles(styles)(ProfileGraphic)