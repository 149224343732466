import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { dd_mmm_yyyyEpoch } from '../../../../utils'
import { InspectionStatusIconWithToolTip } from '../../../shared/Icons'
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  AssignmentTurnedIn,
  OpenInBrowser as Launch,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiCardHeader-action': {
      marginRight: 'auto',
      marginTop: 'auto',
    }
  },
  subcard: {
    minWidth: '300px',
    height: '100%',
    width: '100%',
  },
  inspectionName: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  inspectionList: {
    overflow: 'auto',
    maxHeight: theme.spacing(66)
  },
})

function EmptyInspection() {
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h5" gutterBottom>
        No Inspections Found
      </Typography>
      <Typography variant='body1' gutterBottom>
        An <b>Inspection</b> is a type of task where information about the
        facility is collected to verify its condition.
      </Typography>
      <Typography variant='body1'>
        Examples of Inspections includes
      </Typography>
      <ol>
        <li>Facade Visual Inspection</li>
        <li>Facade Tactile Inspection</li>
        <li>Facade Thermal Inspection</li>
        <li>Radio Interference Inspection</li>
      </ol>
      <Typography variant='body1' gutterBottom>
        To create an Inspection, click on
        NEW INSPECTION button above or go to
        Inspections Page by clicking the icon
        on top right
      </Typography>
    </Box>
  )
}

function InspectionCard({ push, classes, facilityId, inspections }) {
  return <Card className={classes.subcard}>
    <CardHeader
      avatar={<Avatar><AssignmentTurnedIn /></Avatar>}
      action={<IconButton href={'/inspections?facilityId=' + facilityId}><Launch /></IconButton>}
      title={`Inspections`}
      titleTypographyProps={{ variant: 'h5' }}
      className={classes.cardHeader}
    />
    <CardContent>
      { inspections.length === 0 ? <EmptyInspection /> :
        <List className={classes.inspectionList}>
          { inspections.map((inspection, i) =>
            <ListItem key={i}
              onClick={() => push(`/inspection/${inspection._id}`)}>
              <ListItemIcon>
                <InspectionStatusIconWithToolTip status={inspection.status} />
              </ListItemIcon>
              <ListItemText
                primary={inspection.name}
                primaryTypographyProps={{ className: classes.inspectionName }}
                secondary={`${dd_mmm_yyyyEpoch(inspection.start_date)} - ${dd_mmm_yyyyEpoch(inspection.end_date)}`} />
            </ListItem>
          ) }

        </List>
      }
    </CardContent>
  </Card>
}

export default connect(null, { push })(withStyles(styles)(InspectionCard))
