import { useState, useRef, useEffect, useMemo, useContext } from 'react'
import { AccountsContext } from '../../AccountsContextProvider'
import { useQuery } from '../../../utils/hooks'
import {
  useGetDocumentTypesQuery,
  useCreateDocumentMutation,
} from '../../../api/inspectionOps'
import { dateRangeEpoch } from '../../../utils'
import { useAllFacilities  } from '../../shared/hooks/facilities'
import { useAllInspections } from '../../shared/hooks/inspections'
import TopXCloseButton from '../../shared/TopXCloseButton'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  MenuItem,
  ListItemText,
  Select,
  TextField,
  Tooltip,
  Typography,
  Grid,
} from '@material-ui/core'
import {
  Add,
} from '@material-ui/icons'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionContainer: {
    marginTop:    theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight:    theme.spacing(8),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(1),
    border:       '1px dashed grey',
    overflow:     'hidden',
    display:      'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  floatRight: {
    float: 'right',
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  error: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
})

function UploadDialog({ classes, open, onClose, inspection, facility }) {
  const { company_id } = useContext(AccountsContext)
  const query = useQuery()
  const { facilities:  allFacilities  } = useAllFacilities()
  const { inspections: allInspections } = useAllInspections()

  const { data: docTypesData } = useGetDocumentTypesQuery()
  const allDocTypes = useMemo(() => docTypesData?.data?.document_types || [], [docTypesData])

  const [ name,        setName        ] = useState('')
  const [ description, setDescription ] = useState('')
  const [ type,        setType        ] = useState('')
  const [ files,       setFiles       ] = useState([]) // TODO: make it one file by one file?
  const [ inspections, setInspections ] = useState([])
  const [ facilities,  setFacilities  ] = useState([])

  const [ error,          setError         ] = useState('')
  const [ isUploading,    setIsUploading   ] = useState(false)
  const [ disableUpload,  setDisableUpload ] = useState(true)
  const [ createDocument, createResult     ] = useCreateDocumentMutation()

  const presetInspectionId = query.get('inspection')
  useEffect(() => {
    if (allInspections.length > 0 && presetInspectionId) {
      setInspections(prev => [
        ...prev,
        ...allInspections.filter(i => i._id === presetInspectionId)
      ])
    }
  }, [allInspections, presetInspectionId])
  const presetType = query.get('type')
  useEffect(() => {
    if (allDocTypes.length > 0 && presetType) {
      const docType = allDocTypes.find(t => t.code === presetType)
      if (docType)
        setType(docType._id)
    }
  }, [allDocTypes, presetType])

  function handleUpload() {
    if (!type)
      return setError('Document Type is required')
    if (!name)
      return setError('Document Name is required')

    setDisableUpload(true)
    setIsUploading(true)

    for (let i = 0; i < files.length; i++) {
      const fd = new FormData()
      fd.append('name', name)
      fd.append('company_id', company_id)
      fd.append('description', description)
      fd.append('type_id', type)
      fd.append('original_file', files[i])
      inspections.forEach((value) => {
        fd.append('inspections[]', value._id)
      })
      facilities.forEach((value) => {
        fd.append('facilities[]', value._id)
      })

      createDocument(fd)
      .unwrap()
      .then(fulfilled => {
        console.log('create fulfilled', fulfilled)
        console.log('create result', createResult)
        if (fulfilled.status === 'success') {
          setIsUploading(false)
          setFiles([])
          onClose() // TODO: assuming one file for now
        } else {
          setIsUploading(false)
          setError('Failed to upload document.')
        }
      })
      .catch(rejected => {
        console.log('create rejected', rejected)
        setIsUploading(false)
        setError('Error uploading document.')
      })
    }
    setDisableUpload(false)
  }

  return (
    <Dialog maxWidth='md' fullWidth open={open} onClose={onClose}>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>Upload Document</DialogTitle>
      <DialogContent>
        <TextField select fullWidth
          label='Document Type'
          variant='outlined'
          value={type}
          onChange={evt => setType(evt.target.value)}>
          { allDocTypes.map(t =>
            <MenuItem key={t._id} value={t._id} className={classes.menu}>
              <Typography variant='body1'>{t.name}</Typography>
              <Typography variant='body2'>{t.description}</Typography>
            </MenuItem>
          ) }
        </TextField>
        <FileDropArea
          files={files}
          setFiles={setFiles}
          onChange={(curFiles) => setDisableUpload(curFiles.length === 0)}
        />
        <TextField fullWidth
          label='Name'
          variant='outlined'
          value={name}
          onChange={evt => setName(evt.target.value)}
          className={classes.inputField} />
        <TextField fullWidth
          label='Description'
          variant='outlined'
          value={description}
          onChange={evt => setDescription(evt.target.value)}
          className={classes.inputField} />
        <div>
          <div style={{ padding: '8px' }}>
            <Typography variant='body1'>Related Inspections / Facilities:</Typography>
          </div>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Select multiple style={{width: '49%'}}
              variant='outlined'
              renderValue={(selected) => selected.map(i => i.name).join(', ')}
              value={inspections}
              onChange={evt => setInspections(evt.target.value)}>
              { allInspections.map(inspection =>
                <MenuItem key={inspection._id} value={inspection}>
                  <Checkbox checked={inspections.some(i => i._id === inspection._id)} />
                  <ListItemText
                    primary={inspection.name}
                    secondary={dateRangeEpoch(inspection.start_date, inspection.end_date)}
                  />
                </MenuItem>
              ) }
            </Select>
            <Select multiple style={{width: '49%'}}
              variant='outlined'
              renderValue={(selected) => selected.map(f => f.short_name).join(', ')}
              value={facilities}
              onChange={evt => setFacilities(evt.target.value)}>
              { allFacilities.map(facility =>
                <MenuItem key={facility._id} value={facility}>
                  <Checkbox checked={facilities.some(f => f._id === facility._id)} />
                  <ListItemText
                    primary={`${facility.name} (${facility.short_name})`}
                    secondary={facility.address}
                  />
                </MenuItem>
              ) }

              <MenuItem value='facility 1'>
                <Checkbox checked={facilities.indexOf('facility 1') > -1} />
                <ListItemText primary='Facility 1 1' />
              </MenuItem>
              <MenuItem value='facility 2'>
                <Checkbox checked={facilities.indexOf('facility 2') > -1} />
                <ListItemText primary='Facility 2' />
              </MenuItem>
            </Select>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        { error && <Typography variant='body1' className={classes.error}>{error}</Typography> }
        { isUploading && <CircularProgress /> }
        <Button variant='contained' color='primary'
          disabled={disableUpload || isUploading}
          onClick={handleUpload}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function FileDropArea({ files, setFiles, onChange }) {
  const classes = makeStyles(styles)()
  const inputFileRef = useRef(null)

  function handleChooseFiles() {
    inputFileRef.current.click()
  }

  function handleRemoveFile(removedFileIndex) {
    let curFiles = [...files]
    curFiles.splice(removedFileIndex, 1)
    setFiles(curFiles)
    onChange(curFiles)
  }

  function handleAddFile(selectedFiles) {
    const curFiles = []
    curFiles.push.apply(curFiles, selectedFiles)
    setFiles(prevState => ([...prevState, ...curFiles]))
    onChange(curFiles)
  }

  function handleFileChosen(e) {
    const selectedFiles = e.target.files
    handleAddFile(selectedFiles)
    e.target.value = ''
  }

  function handleFilesDrop(e) {
    e.preventDefault()
    const selectedFiles = e.dataTransfer.files
    handleAddFile(selectedFiles)
  }

  function handleDragOver(e) {
    e.preventDefault()
  }

  return (
    <div className={classes.sectionContainer}
      onDrop={e => handleFilesDrop(e)}
      onDragOver={e => handleDragOver(e)}>
      <input type='file' onChange={handleFileChosen} hidden multiple ref={inputFileRef} />
      { files.length > 0 ?
        <ol>
          {files.map((file, idx) => (
            <li key={idx}><Typography>{file.name}
              <Button size='small' color='secondary' onClick={() => handleRemoveFile(idx)}>
                Remove
              </Button>
            </Typography></li>
          ))}
        </ol>
      :
        <Typography variant='body1' style={{display: 'flex', alignItems: 'center'}}>
          No files chosen. Drag and drop files here, or click on <Add /> to select files.
        </Typography>
      }
      <Tooltip title='Choose Files'>
        <Fab size='medium' color='primary' onClick={handleChooseFiles} className={classes.floatRight}>
          <Add />
        </Fab>
      </Tooltip>
    </div>
  )
}

export default withStyles(styles)(UploadDialog)
