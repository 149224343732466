import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  Help,
  KeyboardArrowUp,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import { history } from '../../../../../utils/store'

import CardHeaderTitle from './CardHeaderTitle'
import { InspectionStatusIconWithToolTip } from '../../../../shared/Icons'
import { dd_mmm_yyyyEpoch } from '../../../../../utils'

const styles = theme => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiCardHeader-action': {
      marginRight: 'auto',
      marginTop: 'auto',
    }
  },
  inspectionName: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  inspectionList: {
    overflow: 'auto',
    maxHeight: theme.spacing(66)
  },
  subcard: {
    marginBottom: theme.spacing(1),
    flexGrow: 3
  },
})

function UpcomingInspectionsCard({ classes, inspections }) {
  const futureInspections = inspections.filter(insp => insp.start_date > Date.now())
  const totalCount = futureInspections.length

  return <Card className={classes.subcard}>
    <CardHeader
      avatar={<Avatar><KeyboardArrowUp /></Avatar>}
      title={<CardHeaderTitle count={totalCount} label='Upcoming Inspections' />}
      titleTypographyProps={{ variant: 'h6' }}
      className={classes.cardHeader}
    />
    <CardContent>
      <List className={classes.inspectionList}>
        {/* { inspections.map((inspection, i) =>
          <ListItem key={i}
            onClick={() => push(`/inspection/${inspection._id}`)}>
            <ListItemIcon>
              <InspectionStatusIconWithToolTip status={inspection.status} />
            </ListItemIcon>
            <ListItemText
              primary={inspection.name}
              primaryTypographyProps={{ className: classes.inspectionName }}
              secondary={`${dd_mmm_yyyyEpoch(inspection.start_date)} - ${dd_mmm_yyyyEpoch(inspection.end_date)}`} />
          </ListItem>
        ) } */}

        { futureInspections.length === 0 &&
          <ListItem>
            <ListItemIcon><Help /></ListItemIcon>
            <ListItemText primary='NO BOOKED INSPECTIONS' />
          </ListItem>
        }

        { futureInspections.map((inspection, i) =>
          <ListItem key={i}
            onClick={() => history.push(`/inspection/${inspection._id}`)}>
            <ListItemIcon>
              <InspectionStatusIconWithToolTip status={inspection.status} />
            </ListItemIcon>
            <ListItemText
              primary={inspection.name}
              primaryTypographyProps={{ className: classes.inspectionName }}
              secondary={`${dd_mmm_yyyyEpoch(inspection.start_date)} - ${dd_mmm_yyyyEpoch(inspection.end_date)}`} />
          </ListItem>
        ) }

      </List>
    </CardContent>
  </Card>
}

export default withStyles(styles)(UpcomingInspectionsCard)