import { useAllFacilities } from '../shared/hooks/facilities'
import { useAllInspections } from '../shared/hooks/inspections'

import NavBar       from '../shared/NavBar'
import NotFound     from '../shared/NotFound'

import Gallery      from './Gallery'
import Upload       from './Upload'
import Detections   from './Detections'
import SingleDefect from './SingleDefect'

const Inspection = ({ match, history, location }) => {

  const { facilities  } = useAllFacilities()
  const { inspections } = useAllInspections()

  const inspectionId = match.params.inspectionId
  if (inspections && inspectionId) {

    // One inspection is related to one facility. Select first facility ID in an inspection
    const inspection = inspections.find(inspection => inspection._id === inspectionId)
    if (!inspection) {
      return <>
        <NavBar />
        Loading Inspections ...
      </>
    }

    const facilityId = inspection.facilities[0]

    const facilityType = facilities[facilities.findIndex(facility => facility._id === facilityId)]?.type
    const facilityName = facilities[facilities.findIndex(facility => facility._id === facilityId)]?.name

    function inspectionNavBar(highlight) {
      return <NavBar
        breadcrumb={highlight}
        subtitleOverline='inspection'
        subtitle={inspection.name}
        subtitleLink={`/inspection/${inspectionId}`}
        inspectionId={inspectionId}
      />
    }

    switch (match.params.menu) {
      case 'gallery':
        return <>
          {inspectionNavBar('images')}
          <Gallery
            inspection={inspection}
            inspections={inspections}
            facilityId={facilityId}
            facilityName={facilityName}
            facilityType={facilityType}
            facilities={facilities}
          />
        </>
      case 'upload':
        return <>
          {inspectionNavBar('images')}
          <Upload
            inspectionId={inspectionId}
            facilityId={facilityId}
            facilityType={facilityType}
          />
        </>
      case 'detections':
        if (match.params.defectId) {
          return <>
            {inspectionNavBar('detections')}
            <SingleDefect
              inspectionId={inspectionId}
              defectId={match.params.defectId}
              facilityId={facilityId}
              facilityType={facilityType}
            />
          </>
        } else {
          return <>
            {inspectionNavBar('detections')}
            <Detections
              inspectionId={inspectionId}
              facilityId={facilityId}
              facilityType={facilityType}
            />
          </>
        }
      default:
        return <NotFound />
    }
  }
}

export default Inspection
