import { toast } from 'react-toastify'
import { useGetFacilitiesQuery } from '../../../api/inspectionOps'

export function useAllFacilities() {
  const { data, error, isLoading } = useGetFacilitiesQuery()

  if (!isLoading) {
    if (error) {
      console.log('Error loading facilities:', error)
      toast.error('Error loading facilities')
    }
    else if (data.status !== 'success') {
      console.log('Failed loading facilities:', data)
      toast.warning('Failed loading facilities')
    }
  }
  return {
    facilities:        data?.data?.facilities || [],
    facilitiesLoading: isLoading
  }
}
