import {
  Box,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  Archive,
  Unarchive,
  Create,
  Delete
} from '@material-ui/icons'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  facName: {
    fontWeight: 'bold'
  }
})

const Header = ({
  classes,
  name,
  shortName,
  archived,
  onEdit,
  onArchive,
  onDelete
}) => {
  return (
    <div className={classes.root}>
      <Box>
        <Typography variant='h4' className={classes.facName}>
          {name}
        </Typography>
        { shortName && <Typography variant='body1'>&quot;{shortName}&quot;</Typography> }
      </Box>
      <Box>
        <IconButton onClick={onEdit}>
          <Create />
        </IconButton>
        <IconButton onClick={onArchive}>
          { archived ? <Unarchive /> : <Archive />}
        </IconButton>
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </Box>
    </div>
  )
};

export default withStyles(styles)(Header)