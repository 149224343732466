import { useEffect } from 'react'
import { history } from '../../../../utils/store'

import {
  useGetInspectionSummaryByIdQuery,
} from '../../../../api/inspectionOps'

import { StyledToolTip } from '../../../shared/Icons'

import {
  Badge,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  Assessment,
  PhotoLibrary,
  Report,
} from '@material-ui/icons'

function InspectionActions({ classes, inspection_id, updateImageArray, imageCountUpdated }) {

  const { data, isLoading } = useGetInspectionSummaryByIdQuery(inspection_id)

  // TODO Redo this really bad state transition. Counting should be done in parent in
  //      a single call to backend. Potentially need to expand backend to take an array
  //      of inspection IDs.
  useEffect(() => {
    const count = data?.data?.summary?.images_count
    if (count && count > -1 && !imageCountUpdated)
      updateImageArray(count)
  }, [data, updateImageArray, imageCountUpdated])

  const summary = data?.data?.summary || {}

  return (<>
    <StyledToolTip title={<Typography variant='body1'><b>Image Gallery</b></Typography>}>
      <Badge badgeContent={summary.images_count}
        color='primary' overlap='rectangular' showZero invisible={isLoading}>
        <IconButton onClick={() => { history.push(`/inspection/${inspection_id}/gallery`) }}>
          <PhotoLibrary />
        </IconButton>
      </Badge>
    </StyledToolTip>
    <StyledToolTip title={<Typography variant='body1'><b>Detections</b></Typography>}>
      <Badge badgeContent={summary.defects_count}
        color='primary' overlap='rectangular' showZero invisible={isLoading}>
        <IconButton onClick={() => {history.push(`/inspection/${inspection_id}/detections`)}}>
          <Report />
        </IconButton>
      </Badge>
    </StyledToolTip>
    <StyledToolTip title={<Typography variant='body1'><b>Reports</b></Typography>}>
      <Badge badgeContent={summary.documents_count}
        color='primary' overlap='rectangular' showZero invisible={isLoading}>
        <IconButton onClick={() => { history.push(`/reports?inspection=${inspection_id}`) }}>
          <Assessment />
        </IconButton>
      </Badge>
    </StyledToolTip>
  </>)
}

export default InspectionActions

