export function standardHeaders(headers, { getState }) {
  const user = getState().oidc?.user
  if (user)
    headers.set('Authorization', `Bearer ${user.access_token}`)
  return headers
}

// For now, works for strings and arrays (pass in strings for booleans)
export function makeQueryString(queryParams = {}, searchFields, customCallback) {
  const paramsArray = []
  searchFields.forEach(field => {
    if (queryParams[field] !== undefined && queryParams[field] !== null) {
      const data = encodeURIComponent(queryParams[field])
      if (data) // so that backend does not see any empty strings
        paramsArray.push(`${field}=${data}`)
    }
  })
  if (customCallback)
    paramsArray.push(customCallback())
  const result = paramsArray.length > 0 ? ('?' + paramsArray.join('&')) : ''
  return result
}
