
import { IconButton } from '@material-ui/core'
import { ExitToApp }  from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  logout: {
    zIndex: 1,
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.common.white,
  }
})

function LogoutButton({ classes }) {
  return (
    <IconButton className={classes.logout}
      onClick={() => { window.location.href = '/logout' }}>
      <ExitToApp />
    </IconButton>
  )
}

export default withStyles(styles)(LogoutButton)
