import {
  Chip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useGetElevationByIdQuery } from '../../../../api/inspectionOps'

const styles = theme => ({
  container: {
    minWidth:  theme.spacing(12),
  },
  middle: {
    display: 'flex',
    alignItems: 'top'
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  errorChip: {
    margin: theme.spacing(0, 1),
    color: theme.palette.secondary.main,
  }
})

function DefectElevation({ classes, elevationIds }) {
  return (
    <div className={classes.container}>
      <Typography variant='overline'>Elevation</Typography>
      <div className={classes.middle}>
        { elevationIds?.length > 0 ?
          elevationIds.map(e => <ElevationChip classes={classes} elevationId={e} key={e}/>)
        :
          <Chip label='No Elevation' className={classes.errorChip} />
        }
        {/* {image.facility_elevations} */}
      </div>
    </div>
  )
}

function ElevationChip({ classes, elevationId }) {
  // return (<Chip key={elevationId} label={elevationId} className={classes.chip} />)
  const { data, error, isLoading } = useGetElevationByIdQuery(elevationId)
  return (
    isLoading
    ? <Chip label='Loading...' />
    : error
    ? <Chip key={elevationId} label={elevationId} className={classes.chip} />  /* workaround for older elevations */
    : <Chip key={elevationId} label={data?.data?.elevation?.name} className={classes.chip} />
  )
}

export default withStyles(styles)(DefectElevation)
