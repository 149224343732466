import { useState, useContext } from 'react'
import { useAccessToken } from '../../../../api/accounts'
import { AccountsContext } from '../../../AccountsContextProvider'
import {
  useGetMLModelsQuery,
  useGetMLModelVersionsQuery,
} from '../../../../api/geoAiConfig'
import {
  useCreateFaultFinderMutation,
  useGetImagesQuery
} from '../../../../api/inspectionOps'

import TopXCloseButton from '../../../shared/TopXCloseButton'
import AIApplyLabelsDialog from './AIApplyLabelsDialog'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  ImageSearch,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

const styles = theme => ({
  errMsg: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  icon: {
    width:  theme.spacing(10),
    height: theme.spacing(10),
    color:  theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  successMsg: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
  inputField: {
    marginBottom: 10,
  },
  actions: {
    marginTop: theme.spacing(8),
    textAlign: 'center',
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
})

function AIApplyDialog({ classes, onClose, inspectionId }) {
  const { user, userLoading } = useContext(AccountsContext)
  const token = useAccessToken()

  const [ labelsOpen, setLabelsOpen ] = useState(false)

  const [ selectedMlModel,    setSelectedMlModel    ] = useState('')
  const [ selectedMlmVersion, setSelectedMlmVersion ] = useState('')

  const { data: imgData  } = useGetImagesQuery({ inspection_id: inspectionId })
  const { data: mlmData  } = useGetMLModelsQuery()
  const { data: mlmvData } = useGetMLModelVersionsQuery({ ml_model_id: selectedMlModel?._id }, {
    skip: !selectedMlModel
  })
  const [ createFaultFinder ] = useCreateFaultFinderMutation()

  const inspImages  = imgData?.data?.images            || []
  const mlModels    = mlmData?.data?.ml_model          || []
  const mlmVersions = mlmvData?.data?.ml_model_version || []

  function handleSelectMlm(e) {
    const mlm = mlModels.find(m => m._id === e.target.value)
    setSelectedMlModel(mlm)
  }

  function handleSelectMlmv(e) {
    const mlmv = mlmVersions.find(v => v._id === e.target.value)
    setSelectedMlmVersion(mlmv)
  }

  function handleStart() {
    const metadata_map = {}
    inspImages.forEach(image => {
      metadata_map[image.media_id] = {
        inspection_image_id: image._id
      }
    })
    const msg = {
      metadata: {
        inspection_id: inspectionId,
        algorithm:     `${selectedMlModel.name} ${selectedMlmVersion.semver}`,
        ml_model_version_id: selectedMlmVersion._id
      },
      metadata_map,
      labels_media_id:  selectedMlModel.labels_media_id,
      weights_media_id: selectedMlmVersion.weights_media_id,
      configs_media_id: selectedMlmVersion.configs_media_id,
      images_media_id:  inspImages.map(image => image.media_id),
      user: {
        email: user.email,
        name: user.name,
        token,
      }
    }

    createFaultFinder(msg)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('create failed', fulfilled)
        toast.error('Failed to send request')
      } else {
        console.log('create success', fulfilled)
        toast.success('Request sent successfully')
        onClose()
      }
    })
    .catch(rejected => {
      console.error('create rejected', rejected)
      toast.error('Error sending request')
    })
  }

  function handleClose() {
    setSelectedMlModel('')
    onClose && onClose()
  }

  return (<>
    <Dialog open onClose={onClose} fullWidth maxWidth='lg'>
      <TopXCloseButton onClick={handleClose} />
      <DialogTitle className={classes.title}>Apply AI Algorithm</DialogTitle>
      <DialogContent>
        <Typography variant='body1' gutterBottom>
          Choose model to apply to {inspImages.length} images in this inspection
        </Typography>
        { !selectedMlModel &&
          <TextField
            select
            fullWidth
            variant='outlined'
            label='Machine Learning Model'
            value={selectedMlModel?._id || ''}
            onChange={handleSelectMlm}>
            { mlModels.map(mlm =>
              <MenuItem key={mlm._id} value={mlm._id}>
                {mlm.name}
              </MenuItem>
            ) }
          </TextField>
        }
        { selectedMlModel &&
          <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex' }}>
              <ImageSearch className={classes.icon} />
              <div style={{ flexGrow: 1 }}>
                <Typography variant='overline'>
                  ML Model
                </Typography>
                <Typography variant='h4'>
                  {selectedMlModel.name}
                </Typography>
                <Typography variant='body1'>
                  {selectedMlModel.description}
                </Typography>
                <br />
                <TextField
                  select
                  fullWidth
                  variant='outlined'
                  label='Version'
                  value={selectedMlmVersion?._id || ''}
                  onChange={handleSelectMlmv}>
                  { mlmVersions.map(v =>
                    <MenuItem key={v._id} value={v._id}>
                      {v.semver} {v.release_notes}
                    </MenuItem>
                  ) }
                </TextField>
              </div>
            </div>
          </div>
        }
      </DialogContent>
      <DialogActions>
        <Button variant='outlined'
          disabled={!selectedMlModel}
          onClick={() => setLabelsOpen(true)}>
          See Labels
        </Button>
        <br />
        <br />
        <Button variant='contained' color='primary'
          disabled={!(selectedMlModel && selectedMlmVersion._id && !userLoading)}
          onClick={handleStart}>
          Start
        </Button>
      </DialogActions>
    </Dialog>
    { labelsOpen &&
      <AIApplyLabelsDialog
        open={labelsOpen}
        onClose={() => { setLabelsOpen(false) }}
        mlModelId={selectedMlModel._id}
      />
    }
  </>)
}

export default withStyles(styles)(AIApplyDialog)
