import { useState } from 'react'
import useCompanies from '../shared/useCompanies'
import NavBar       from '../shared/NavBar'
import AIIntro from './components/AIIntro'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import {
  ImageSearch,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  iconBackground: {
    position: 'absolute',
    top:      '100px',
    right:    0,
    width:    '620px',
    height:   '620px',
    color:    theme.palette.grey[200],
    zIndex:   -100,
  },
  panel: {
    padding: theme.spacing(2),
  },
})

function Algorithm({ classes }) {
  const { my_company } = useCompanies()
  const isEnterprise = my_company?.properties?.tier === 'enterprise'

  const [ menu, setMenu ] = useState('intro')

  return (<>
    <NavBar
      subtitleOverline='algorithms owned by'
      subtitle={ my_company?.name || '-' }
    />
    <div className={classes.root}>
      <Grid container>
        <Grid item sm={2} xs={12}>
          <List>
            <ListItem button selected={menu === 'intro'}
              onClick={e => setMenu('intro')}>
              <ListItemText primary='Learn about ML' />
            </ListItem>
          </List>
        </Grid>
        <Grid item sm={10} xs={12} className={classes.panel}>
          { menu === 'intro' &&
            <AIIntro isEnterprise={isEnterprise} />
          }
        </Grid>
      </Grid>

      <ImageSearch className={classes.iconBackground} />
    </div>
  </>)
}

export default withStyles(styles)(Algorithm)
