import { Route, Redirect } from 'react-router-dom'
import { useDecodedAccessToken } from '../api/accounts'

function UnauthorizedAccess() {
  return (
    <div style={{ padding: '24px' }}>
      <img src='/facilities4.0_logo_black.png' width='320' alt='logo' />
      <br />
      <br />
      <p>Your Garuda Plex account does not have access to Facilities 4.0.</p>
      <br />
      <p>Please contact your Company Administrator to request for access.</p>
      <br />
      <p>Click <a href='/'>here</a> to login again with another account.</p>
    </div>
  )
}

function ProtectedRoute({ path, ProtectedComponent }) {
  const token = useDecodedAccessToken()

  const secureRender = (props) => {
    if (token?.user_id) {
      if (token?.app_roles?.facilities?.length > 0)
        return <ProtectedComponent {...props} />
      else
        return <UnauthorizedAccess />
    } else
      return <Redirect to='/' />
  }

  return (<Route exact path={path} render={secureRender} />)
}

export default ProtectedRoute
