import { useEffect, useState } from 'react'

import LabelledImage from '../../../shared/LabelledImage'
import DefectElevation from './DefectElevation'

import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Controls from './Controls'

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  titleContainer: {
    display: 'flex',
  },
  defectDetails: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  imageContainer: {
    height: '70vh', //Approx. This should be exactly 100vh - top navigation height
    overflow: 'hidden',
  }
})

function DefectDetails({
  classes,
  imageLabelIndex,
  setImageLabelIndex,
  defect,
  inspectionId,
}) {
  const STEP = 0.2
  const [imageWidth, setImageWidth] = useState(0)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [zoom, setZoom] = useState(1)

  const label = defect.instances ? defect.instances[imageLabelIndex] : null

  const setIndex = (index) => {
    setImageLabelIndex(index)
  }

  useEffect(() => {
    const handleResize = () => {
      const containerElement = document.getElementById('image-container')
      if (containerElement) {
        const containerWidth = containerElement.offsetWidth
        setImageWidth(containerWidth)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [imageLabelIndex, defect])

  const handleImageLoad = () => {
    setImageLoaded(true)
  }
  // console.log('defect', defect)

  // console.log('label', imageLabelIndex, defect?.instances?.length)
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Controls
          id={defect._id}
          inspectionId={inspectionId}
          name={label ? label.media_metadata.original_name : ''}
          index={imageLabelIndex}
          maxIndex={defect.instances ? (defect.instances.length - 1) : -1}
          setIndex={setIndex}
          zoom={zoom}
          addZoom={() => {
            setZoom(zoom + (STEP / 2))
          }}
          minusZoom={() => {
            if (zoom > 1)
            setZoom(zoom - (STEP / 2))
          }}
          hasLabels={!!label}
        />
      </div>

      {label?.media_id &&
        <div id={'image-container'} className={classes.imageContainer}>
          {!imageLoaded && ( // Display the spinner if the image is not loaded
            <div className={classes.spinnerContainer}>
              <CircularProgress />
            </div>
          )}
          <LabelledImage
            media_id={label?.media_id}
            size='fullscreen'
            labels={[{
              ...label,
              imgH: label.media_metadata.exif.image_height,
              imgW: label.media_metadata.exif.image_width
            }]}
            scaleWidth={imageWidth}
            onImageLoad={handleImageLoad}
            zoomLevel={zoom}
            enablePan={zoom > 1}
          />
        </div>
      }

      { label &&
        <div className={classes.defectDetails}>
          <DefectElevation
            elevationIds={label.facility_elevations}
          />
        </div>
      }
    </div>
  )
}

export default withStyles(styles)(DefectDetails)
