import { connectRouter, routerMiddleware } from 'connected-react-router'
import { reducer as oidcReducer } from 'redux-oidc'
import { createBrowserHistory } from 'history'
import axios                    from 'axios'

import { configureStore }   from '@reduxjs/toolkit'
import { accountsApi }      from '../api/accounts.js'
import { geoAiConfigApi }   from '../api/geoAiConfig.js'
import { geospatialApi }    from '../api/geospatial.js'
import { inspectionOpsApi } from '../api/inspectionOps.js'
import { mediaApi }         from '../api/media.js'
import { tasksApi }         from '../api/tasks.js'

import paginationReducer from './paginationSlice.js'

export const history = createBrowserHistory()

// Redux DevTools Extension comes free with Redux Toolkit, install from Chrome Store to use
const store = configureStore({
  reducer: {
    router: connectRouter(history),
    oidc: oidcReducer,
    [accountsApi.reducerPath]:      accountsApi.reducer,
    [geoAiConfigApi.reducerPath]:   geoAiConfigApi.reducer,
    [geospatialApi.reducerPath]:    geospatialApi.reducer,
    [inspectionOpsApi.reducerPath]: inspectionOpsApi.reducer,
    [mediaApi.reducerPath]:         mediaApi.reducer,
    [tasksApi.reducerPath]:         tasksApi.reducer,
    pagination: paginationReducer,
  },
  // default middleware already included thunk, immutableStateInvariant, serializableStateInvariant
  // https://redux-toolkit.js.org/api/getDefaultMiddleware
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // oidc.user is a class, issue still open, muting
      // https://github.com/maxmantz/redux-oidc/issues/169
      ignoredActions: ['redux-oidc/USER_FOUND'],
      ignoredPaths: ['oidc.user']
    },
  })
  .concat(routerMiddleware(history))
  .concat(accountsApi.middleware)
  .concat(inspectionOpsApi.middleware)
  .concat(mediaApi.middleware)
  .concat(geoAiConfigApi.middleware)
  .concat(geospatialApi.middleware)
  .concat(tasksApi.middleware)
})

axios.interceptors.request.use(function(config) {
  const { access_token } = store.getState().oidc.user
  config.headers.Authorization = `Bearer ${access_token}`
  return config
})

export default store
