import { FacilityTypeIcon } from '../../shared/Icons'
import {
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(0, 2, 8, 4),
  },
  subtitle: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})

function EmptyDirectory({ classes }) {
  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>
        <FacilityTypeIcon type='building' className={classes.icon} />
        <Typography variant='h6'>
          No Facilities Found
        </Typography>
      </div>
      <Typography variant='h3' gutterBottom color='primary'>
        Let's Get Started
      </Typography>
      <Typography variant='h5' gutterBottom>Create a Facility</Typography>
      <Typography variant='body1' gutterBottom>
        A <b>Facility</b> can be a Building, a Powerline, a Telco tower,
        or any place, structure, utility, or environment you are managing.
      </Typography>
      <Typography variant='body1' gutterBottom>
        To create a Facility, click on NEW FACILITY on the top right.
      </Typography>
      <Typography variant='h5' gutterBottom>Add Elevations</Typography>
      <Typography variant='body1' gutterBottom>
        An <b>Elevation</b> or <b>Facade</b> is a face, side, or plane of
        your facility. You can use it to tag sections of your facility.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(EmptyDirectory)
