import { toast } from 'react-toastify'
import { useGetTasksQuery } from '../../api/tasks'
import useCompanies from '../shared/useCompanies'
import NavBar       from '../shared/NavBar'
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@material-ui/core'
import {
  FormatListNumbered,
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  iconBackground: {
    position: 'absolute',
    top:      '100px',
    right:    0,
    width:    '620px',
    height:   '620px',
    color:    theme.palette.grey[200],
    zIndex:   -100,
  },
})

function Tasks({ classes }) {
  const { my_company } = useCompanies()
  const { data, error } = useGetTasksQuery({
    type: 'facade-visual' // TODO: enumerate task type with EMS
  })
  if (error) {
    console.log('Error loading tasks', error)
    toast.error('Error loading tasks')
  }
  const tasks = data?.data?.tasks || []
  return (<>
    <NavBar
      subtitleOverline='tasks assigned to'
      subtitle={ my_company?.name || '-' }
    />
    <div className={classes.root}>
      { tasks.length === 0 ?
        <EmptyTasks />
      : <>
          <Typography variant='h4'>My Tasks</Typography>
          <List>
          { tasks.map(t => (
            <ListItem>
              <ListItemIcon><FormatListNumbered /></ListItemIcon>
              <ListItemText
                primary={t.subject || 'Unnamed Task'}
                secondary={`Due: ${t.due_date ? new Date(t.due_date).toLocaleDateString() : '-'}`}
              />
            </ListItem>
          )) }
          </List>
        </>
      }
      <FormatListNumbered className={classes.iconBackground} />
    </div>
  </>)
}

const emptyStyles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(0, 2, 8, 4),
  },
  subtitle: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})

function EmptyTasks() {
  const classes = makeStyles(emptyStyles)()
  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>
        <FormatListNumbered className={classes.icon} />
        <Typography variant='h6'>
          No Tasks Found
        </Typography>
      </div>
      <Typography variant='h3' gutterBottom color='primary'>
        Let's Get Started
      </Typography>
      <Typography variant='h5' gutterBottom>Create a Task</Typography>
      <Typography variant='body1' gutterBottom>
        A task can be to inspect, to patrol, to map, to guard (sentry),
        or even to spray or wash a facility.
      </Typography>
      <Typography variant='body1' gutterBottom>
        Your account does not allow for task creation. Instead, create
        task using your integrated Workforce Management or Task Dispatch
        System.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Tasks)
