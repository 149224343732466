import { pretty } from '../../../../utils'
import { DefectStatusIconWithToolTip } from '../../../shared/Icons'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  center: {
    textAlign: 'center',
  },
  filename: {
    whiteSpace: 'no-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const DefectProperties = ({ defect, classes }) => {
  if (!defect)
    return null

  return (<div>
    <List style={{ padding: 0 }}>
      <ListItem>
        <ListItemIcon>
          <DefectStatusIconWithToolTip status={defect.status} />
        </ListItemIcon>
        <ListItemText
          className={classes.filename}
          primary={defect.description}
          secondary={defect.instance?.filename}
        />
      </ListItem>
    </List>
    <Grid container>
      <Grid item xs={6} className={classes.center}>
        <Typography variant='overline'>Surface</Typography>
        <Typography variant='body2'>
          { defect.surface && defect.surface !== 'blank' ?
            pretty(defect.surface) : 'Unclassified' }
        </Typography>
      </Grid>
      <Grid item xs={6} className={classes.center}>
        <Typography variant='overline'>Type</Typography>
        <Typography variant='body2'>
          { defect.type && defect.type !== 'blank' ?
            pretty(defect.type) : 'Unclassified' }
        </Typography>
      </Grid>
    </Grid>
  </div>)
}

export default withStyles(styles)(DefectProperties)
