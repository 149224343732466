import { useRef, useState, useCallback } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import TopXCloseButton from '../../../../shared/TopXCloseButton'
import ImageZoomInOut from '../../../../shared/ImageZoomInOut'
import { DEFAULT_MAP_CENTER } from  '../../../../../utils/site-maps'

import { Viewer, CameraFlyTo } from 'resium'
import { Ion, Color, Cartesian2, Cartesian3, Cartographic } from 'cesium'
import ElevationQuadrilateralCanvasOverlay from './components/ElevationQuadrilateralCanvasOverlay'
import DialogActionButtons from './components/DialogActionButtons'
import GoogleMapsTileset from '../../../../shared/tilesets/GoogleMapsTileset'
import OneMapTileset from '../../../../shared/tilesets/OneMapTileset'
import { useEffect } from 'react'

const styles = (theme) => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  fileUpload: {
    width: theme.spacing(27),
    overflow: 'hidden'
  },
  content: {
    height: theme.breakpoints.values.sm,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  button: {
    width: '50%',
    borderRadius: 0,
    zIndex: 1,
    opacity: 1,
  },
  buttonLeft: {
    borderBottomLeftRadius: theme.spacing(1),
  },
  buttonRight: {
    borderBottomRightRadius: theme.spacing(1),
  },
  imageUploadPreview: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  imageUploadContainer: {
    width: '100%',
    height: '100%',
  }
})

function ElevationViewModal({
  facility,
  mode,
  location,
  classes,
  open,
  onClose,
  onNext,
  name,
  file,
  setFile,
  onBack,
  quadrilateral,
  setQuadrilateral,
  topAGL,
  setTopAGL,
  bottomAGL,
  setBottomAGL,
  onSave
}) {
  const [ isDrawingQuadrilateral, setIsDrawingQuadrilateral ] = useState(false)
  const [ tileProvider, setTileProvider ] = useState('onemap')
  const [ errorMessage, setErrorMessage ] = useState('')
  const inputRef = useRef(null)
  const center = location?.features.length > 0 ? [(location.features[0].geometry.coordinates[0][0]+location.features[0].geometry.coordinates[1][0])/2,
  (location.features[0].geometry.coordinates[0][1]+location.features[0].geometry.coordinates[1][1])/2] : [DEFAULT_MAP_CENTER[1],DEFAULT_MAP_CENTER[0]]

  useEffect(() => {
    if (!open) return
    if (quadrilateral.length === 0) {
      setTopAGL(0)
      setBottomAGL(0)
      return
    }

    if (quadrilateral.length < 4) return
    
    let top = 0
    if (quadrilateral[0].height) top = quadrilateral[0].height
    if (quadrilateral[1].height && quadrilateral[1].height > quadrilateral[0].height) top = quadrilateral[1].height
    setTopAGL(top)

    let bottom = 0
    if (quadrilateral[2].height) bottom = quadrilateral[2].height
    if (quadrilateral[3].height && quadrilateral[3].height < quadrilateral[2].height) bottom = quadrilateral[3].height
    setBottomAGL(bottom)
  }, [quadrilateral, quadrilateral.length, setBottomAGL, setTopAGL, open])

  function uploadFile(file) {
    if (!file) {
      setFile(null)
      return
    }
    setFile(file)
  }

  const validAGL = (agl) => {
    return /^-?[0-9]+(\.[0-9]+)?$/.test(agl)
  }

  return (
    <Dialog classes={{ paper: classes.dialogPaper}} open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        { mode === 'map' && !isDrawingQuadrilateral && 'Step 2a: Create an Elevation by snapping an image using OneMap 3D' }
        { mode === 'map' && isDrawingQuadrilateral && 'Step 3: Draw elevation boundary' }
        { mode === 'image' && 'Step 2b: Create an Elevation by manually uploading an image you took of the Elevation' }
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant='h4'>Elevation: {name}</Typography>
        <Grid container alignItems='center' spacing={3}>
          <Grid item xs={5}>
              { mode === 'map' && !isDrawingQuadrilateral && (
                <Typography variant='body1'>
                  Rotate the building until you can visually see the entire Elevation and then click “Capture”.
                  <br/><br/>
                  Tip: Works best if you can face the elevation without being blocked by trees. <br/>
                </Typography>
              )}
              { mode === 'map' && !isDrawingQuadrilateral && tileProvider === 'onemap' && (<>Additional tip: You can click on a building in OneMap to isolate it.</>)}
              { mode === 'map' && isDrawingQuadrilateral && (
                <Box>
                  <Typography variant='body1'>
                    Click 4 points to define the boundary of the Elevation. Click “Save” if you’re satisfied, or “Clear” to redo. <br/><br/>
                    Tip: The top 2 points and the bottom 2 points should be at the same height. <br/><br/>
                  </Typography>
                  <TextField 
                    style={{marginTop: '16px'}}
                    variant={'outlined'}
                    label='Top Altitude (AGL)'
                    value={topAGL}
                    onChange={(e) => setTopAGL(e.target.value)}/><br/>
                  <TextField 
                    style={{marginTop: '16px'}}
                    variant={'outlined'}
                    label='Bottom Altitude (AGL)'
                    value={bottomAGL}
                    onChange={(e) => setBottomAGL(e.target.value)}/>
                  {
                    errorMessage &&
                    <Typography variant='body1' style={{color: 'red'}}>
                        {errorMessage}
                    </Typography>
                  }
                </Box>
              )}
              { mode === 'image' && 'Click “Upload Image” to choose the file to upload' }
          </Grid>
          <Grid item xs={7}>
            <Box display='flex' flex='1' flexGrow='1' justifyContent='center' alignItems='center'>
              { mode === 'map' && (
                <Elevation3DMap facility={facility} location={location} center={center} file={file} setFile={setFile} onNext={onNext}
                  setQuadrilateral={setQuadrilateral}
                  isDrawingQuadrilateral={isDrawingQuadrilateral} setIsDrawingQuadrilateral={setIsDrawingQuadrilateral}
                  tileProvider={tileProvider} setTileProvider={setTileProvider}/>
              )}
              { mode === 'image' && (<>
                <input hidden ref={inputRef} type='file' onChange={e => uploadFile(e.target.files[0])} />

                <Box className={classes.imageUploadContainer} display='flex' flexDirection='column'>
                  <Button variant='outlined' fullWidth color='primary' onClick={() => inputRef.current.click()}>
                    Upload Image
                  </Button>
                  <Box flexGrow={1} style={{maxHeight: '580px', height: '50vh'}}>
                    { file && (
                      <ImageZoomInOut imageUrl={URL.createObjectURL(file)} />
                    )}
                  </Box>
                </Box>
              </>)}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionButtons
          onNext={mode === 'map' ? () => {
            if (!validAGL(topAGL)) {
              setErrorMessage('Invalid top altitude')
              return
            }
            if (!validAGL(bottomAGL)) {
              setErrorMessage('Invalid bottom altitude')
              return
            }
            if (Number(topAGL) < Number(bottomAGL)) {
              setErrorMessage('Top altitude must be above bottom altitude')
              return
            }
            onSave()
          } : onNext}
          disableNext={!file || (mode === 'map' && quadrilateral.length < 4)}
          onCancel={() => {
            setFile(null)
            onBack()
          }}
          nextText={mode === 'map' ? 'Save' : 'Next'} />
      </DialogActions>
    </Dialog>
  )
}

function Elevation3DMap({facility, location, center, file, setFile, setQuadrilateral, isDrawingQuadrilateral, setIsDrawingQuadrilateral, tileProvider, setTileProvider}) {
  const [ isClipping, setIsClipping ] = useState(false)
  
  Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_ACCESS_TOKEN
  const viewerRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    if (file === null) {
      setIsDrawingQuadrilateral(false)
      setQuadrilateral([])
    }
    else {
      setIsDrawingQuadrilateral(true)
    }
  }, [file, setQuadrilateral, setIsDrawingQuadrilateral])

  useEffect(() => {
    setIsClipping(false)
  }, [tileProvider])

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n)
    while(n--)
      u8arr[n] = bstr.charCodeAt(n)
    return new File([u8arr], filename, {type:mime})
  } 

  const takeScreenshot = () => {
    if (!viewerRef) return
    const prevTerrain = viewerRef.current.cesiumElement.scene.terrainProvider
    viewerRef.current.cesiumElement.scene.terrainProvider = false
    viewerRef.current.cesiumElement.scene.skyAtmosphere.show = false
    viewerRef.current.cesiumElement.scene.skyBox.show = false
    viewerRef.current.cesiumElement.scene.context.options.webgl.alpha = true
    viewerRef.current.cesiumElement.scene.backgroundColor = new Color(255,255,255,0)
    viewerRef.current.cesiumElement.render()
    const screenshot = dataURLtoFile(viewerRef.current.cesiumElement.canvas.toDataURL())

    setFile(screenshot)
    setIsDrawingQuadrilateral(true)
    setQuadrilateral([])

    viewerRef.current.cesiumElement.scene.terrainProvider = prevTerrain
    viewerRef.current.cesiumElement.scene.skyAtmosphere.show = true
    viewerRef.current.cesiumElement.scene.skyBox.show = true
    viewerRef.current.cesiumElement.scene.context.options.webgl.alpha = false
  }

  const handleRecapture = () => {
    setFile(null)
    setIsDrawingQuadrilateral(false)
    setQuadrilateral([])
  }

  const getLocation = useCallback((coord) => {
    const cart2 = viewerRef.current.cesiumElement.scene.pickPosition(new Cartesian2(coord[0],coord[1]))
    if (!cart2) return null
    return Cartographic.fromCartesian(cart2)
  }, [])

  const calculateBearing = (src, dest) => {
    const x = Math.cos(dest[1] * Math.PI / 180) * Math.sin((dest[0] - src[0]) * Math.PI / 180)
    const y = Math.cos(src[1] * Math.PI / 180) * Math.sin(dest[1] * Math.PI / 180) 
      - Math.sin(src[1] * Math.PI / 180) * Math.cos(dest[1] * Math.PI / 180) * Math.cos((dest[0] - src[0]) * Math.PI / 180)
    return Math.atan2(x,y)
  }

  const determineCameraBearing = (location, facilityCenter) => {
    if (!location || location?.features.length === 0) return 0
    
    const elevationPoint1 = [
      location.features[0].geometry.coordinates[0][0],
      location.features[0].geometry.coordinates[0][1]
    ]
    const elevationPoint2 = [
      location.features[0].geometry.coordinates[1][0],
      location.features[0].geometry.coordinates[1][1]
    ]

    let bearingOfElevation = calculateBearing(elevationPoint1, elevationPoint2)
    let bearingFromPoint1toFacility = facilityCenter ? calculateBearing(elevationPoint1, facilityCenter.coordinates) : 0

    let cameraBearing = bearingOfElevation
    let perpendicular = 0
    bearingFromPoint1toFacility = (bearingFromPoint1toFacility + 2 * Math.PI) % (2 * Math.PI)
    bearingOfElevation = (bearingOfElevation + 2 * Math.PI) % (2 * Math.PI)
    
    if (bearingFromPoint1toFacility > bearingOfElevation )
      perpendicular = Math.PI/2
    else if (bearingFromPoint1toFacility < bearingOfElevation )
      perpendicular = -Math.PI/2

    cameraBearing += perpendicular

    return cameraBearing
  }

  const switchProvider = (provider) => {
    viewerRef.current.cesiumElement.scene.primitives.removeAll()
    setTileProvider(provider)
  }

  return (
    <Box style={{position: 'relative', width: '100%', maxWidth: '600px', maxHeight: '600px', aspectRatio: 1, marginTop: '-32px'}} ref={containerRef}>
      <Button
          style={{position: 'relative', display: 'inline-block', top: '48px', left: '12px', zIndex: '2'}}
          variant='contained'
          color='primary'
          onClick={takeScreenshot}>
        Capture
      </Button>
      <Button
          style={{position: 'relative', display: 'inline-block', top: '48px', left: '24px', zIndex: '2', border: (tileProvider === 'onemap' ? '3px solid #246ff1' : '')}}
          variant='contained'
          onClick={() => {switchProvider('onemap')}}>
        OneMap
      </Button>
      <Button
          style={{position: 'relative', display: 'inline-block', top: '48px', left: '24px', zIndex: '2', marginLeft: '2px', border: (tileProvider === 'google' ? '3px solid #246ff1' : '')}}
          variant='contained'
          onClick={() => {switchProvider('google')}}>
          Google Maps
      </Button>
      { tileProvider === 'google' && 
        <Button
          variant='contained'
          style={{position: 'absolute', display: isClipping ? 'none' : 'block', top: '96px', left: '12px', zIndex: '3'}}
          onClick={() => {setIsClipping(!isClipping)}}>
            { isClipping ? <>Disable Clipping</> : <>Clip to elevation</>}
        </Button>
      }
      {
        isDrawingQuadrilateral ?
        <ElevationQuadrilateralCanvasOverlay style={{height: '100%'}} imageSrc={file} containerRef={viewerRef} getLocation={getLocation} recapture={handleRecapture} setQuadrilateral={setQuadrilateral}/>
        : null
      }
      <Viewer
        style={{height: '100%'}}
        animation={false} 
        timeline={false} 
        ref={viewerRef}  
        fullscreenButton={false}
        homeButton={false}
        sceneModePicker={false}>
        <CameraFlyTo
          destination={Cartesian3.fromDegrees(center[0], center[1], 50)}
          orientation={{
            heading: determineCameraBearing(location,facility.centroid),
            pitch: - Math.PI / 2,
            roll: 0
          }}
          duration={.5}
          once={true}/>
        { tileProvider === 'onemap' ? <OneMapTileset /> : <GoogleMapsTileset isClipping={isClipping} facilityCenter={facility.centroid} elevation_line={location}/> }
      </Viewer>

    </Box>
  )
}

export default withStyles(styles)(ElevationViewModal)