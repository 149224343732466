import { useCesium } from 'resium'
import {
  createGooglePhotorealistic3DTileset,
  Cartesian3,
  Cartographic, 
  ClippingPlane,
  ClippingPlaneCollection,
  Color,
  GoogleMaps,
  Transforms
} from 'cesium'
import { useCallback, useEffect, useState } from 'react'
import * as t from '@turf/turf'

GoogleMaps.defaultApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const getGoogleMaps = async () => {
  try {
    const googleMaps3DTileset = await createGooglePhotorealistic3DTileset()
    return googleMaps3DTileset
  } catch {
    console.error('failed to load tiles')
  }
}

const GoogleMapsTileset = ({facilityCenter, isClipping, elevation_line}) => {
  const { viewer } = useCesium()
  const [ tileset, setTileset ] = useState(null)

  const clip = useCallback((elevation_line) => {
    if (!elevation_line || !tileset) return
    
    if (!isClipping) {
      tileset.clippingPlanes = undefined
      return
    }
    
    const position = Cartographic.toCartesian(new Cartographic.fromDegrees(facilityCenter.coordinates[0], facilityCenter.coordinates[1]))

    const center = t.point(facilityCenter.coordinates)
    const ep1 = t.point([elevation_line.features[0].geometry.coordinates[0][0], elevation_line.features[0].geometry.coordinates[0][1]])
    const ep2 = t.point([elevation_line.features[0].geometry.coordinates[1][0], elevation_line.features[0].geometry.coordinates[1][1]])
    const cdistance = t.pointToLineDistance(center, elevation_line.features[0]) * 1000 // default unit is kilometers

    const bearing_ep1_to_ep2 = t.bearing(ep1, ep2)

    let bearing_perpendicular_to_elev = bearing_ep1_to_ep2 + 90
    
    const elevationLength = t.distance(elevation_line.features[0].geometry.coordinates[0], elevation_line.features[0].geometry.coordinates[1]) * 1000
  
    const clippingPlaneCollection = new ClippingPlaneCollection({
      modelMatrix: Transforms.eastNorthUpToFixedFrame(position),
      planes: [
        new ClippingPlane(
          new Cartesian3(Math.sin(bearing_perpendicular_to_elev * Math.PI/180), Math.cos(bearing_perpendicular_to_elev * Math.PI/180), 0.0),
          cdistance + 10
        ),
        new ClippingPlane(
          new Cartesian3(-Math.sin(bearing_perpendicular_to_elev * Math.PI/180), -Math.cos(bearing_perpendicular_to_elev * Math.PI/180), 0.0),
          cdistance + 10
        ),

        new ClippingPlane(
          new Cartesian3(Math.sin(bearing_ep1_to_ep2 * Math.PI/180), Math.cos(bearing_ep1_to_ep2 * Math.PI/180), 0.0),
          elevationLength + 10
        ),
        new ClippingPlane(
          new Cartesian3(-Math.sin(bearing_ep1_to_ep2 * Math.PI/180), -Math.cos(bearing_ep1_to_ep2 * Math.PI/180), 0.0),
          elevationLength + 10
        )
      ],
      unionClippingRegions: true,
      edgeWidth: 1.0,
      edgeColor: Color.WHITE,
      enabled: true,
    })
    
    tileset.clippingPlanes = clippingPlaneCollection
    tileset.showSkirts = true;
    
  }, [facilityCenter, isClipping, tileset])

  useEffect(() => {
    if (!elevation_line) return

    clip(elevation_line)
  }, [elevation_line, clip])
  
  useEffect(() => {
    if (!viewer?.scene) return
    getGoogleMaps().then((response) => {
      viewer.scene.sun.show = false
      viewer.scene.primitives.removeAll()
      viewer.scene.primitives.add(response)
      viewer.camera.moveBackward(40)

      setTileset(response)
    })
  },[viewer])
}

export default GoogleMapsTileset