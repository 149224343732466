import { useQuery } from '../../utils/hooks'

import {
  useGetInspectionByIdQuery,
  useGetDocumentsQuery,
  useCreateReportMutation,
} from '../../api/inspectionOps'

import ExternalLink from '../shared/ExternalLink'
import NavBar from '../shared/NavBar'
import {
  Button,
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  Assessment,
  Description,
  ErrorOutline,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

const styles = theme => ({
  root: {
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  leftColumn: {
    minWidth: theme.spacing(60),
  },
  rightColumn: {
    maxWidth: theme.spacing(60),
    padding:  theme.spacing(4),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  iconBackground: {
    position: 'absolute',
    top:      '100px',
    right:    0,
    width:    '620px',
    height:   '620px',
    color:    theme.palette.grey[200],
    zIndex:   -100,
  },
  title: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})

function Reports({ classes, history, getState }) {
  const query = useQuery()
  const inspectionId = query.get('inspection')
  const {
    data: inspectionData,
    isLoading: inspectionLoading,
  } = useGetInspectionByIdQuery(inspectionId, {
    skip: !inspectionId
  })
  const inspection = inspectionData?.data?.inspection || {}

  const {
    data: documentData,
    isLoading: documentLoading,
  } = useGetDocumentsQuery({ inspectionId }, {
    skip: !inspectionId
  })
  const documents = documentData?.data?.documents || []

  const [ createReport, createResult ] = useCreateReportMutation()

  // TODO: Only show reports (not other types of documents)
  // TODO: Default to the latest reports first, no need any sorting / filtering

  return (<>
    <NavBar />
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        <Typography variant='h4'>Inspection Reports</Typography>
        <Typography variant='body1'>
          <Link href={`/inspection/${inspectionId}`}>
            {inspection.name || 'Unknown Inspection'}
          </Link>
        </Typography>
        <List>
          { inspectionLoading || documentLoading ?
            <ListItem>
              <ListItemIcon><CircularProgress /></ListItemIcon>
              <ListItemText primary='Loading...' />
            </ListItem>
          : documents.length === 0 ?
            <ListItem>
              <ListItemIcon><ErrorOutline /></ListItemIcon>
              <ListItemText primary='No documents found' />
            </ListItem>
          : documents.map((doc, i) => (
            <ListItem key={i} style={{ cursor: 'pointer' }}
              onClick={() => { history.push(`/document/${doc.document_id}`) }}>
              <ListItemIcon><Description /></ListItemIcon>
              <ListItemText
                primary={doc.name}
                secondary={doc.description}
              />
            </ListItem>
          )) }
        </List>
      </div>
      <div className={classes.rightColumn}>
        <Assessment className={classes.icon} />
        <Typography variant='h6'>Create a new report</Typography>
        <Typography variant='body2'>To create reports, we recommend that you download templates
          (in docx format), fill them in, and re-upload them.<br /><br />
          For Singapore BCA PFI, you can download an example of a PFI report that's BCA TR-78 compliant
          for reference <ExternalLink href='https://garuda.io/facilities'>here</ExternalLink>.
        <br /><br />
        Create prefilled report with all your annotated defects:</Typography>
        <Button variant='contained' color='primary'
          onClick={() => {
            createReport({inspection_ids: [inspectionId]})
              .unwrap()
              .then(fulfilled => {
                console.log('create report fulfilled', fulfilled)
                console.log('create report result', createResult)
                if (fulfilled.status === 'success') {
                  toast.success('Report will be sent to your email')
                } else {
                  toast.error(`Failed. Please try again later`)
                }
              })
              .catch(rejected => {
                console.log('create report rejected', rejected)
                toast.error(`Failed. Please try again later`)
              })
              .finally(() => history.push(`/documents?inspection=${inspectionId}`))
          }}>
          Create Prefilled Template
        </Button>
        <br /><br />
        <Typography variant='body2'>You can further edit the report using Micrsoft Word, Google Docs,
          Pages, OpenOffice, or LibreOffice.<br /><br />Once completed, we suggest you "Print to PDF" /
          "Export to PDF" first, before uploading:</Typography>
        <Button variant='contained' color='primary'
          onClick={() => history.push(`/documents?inspection=${inspectionId}&upload=true&type=bca-tr78-inspection-report`)}>
          Upload Completed Report
        </Button>
      </div>
    </div>
    <Assessment className={classes.iconBackground} />
  </>)
}

export default withStyles(styles)(Reports)
