import { useState } from 'react'
import { useMediaURL } from '../../../../api/media'
import TopXCloseButton from '../../../shared/TopXCloseButton'
import ElevationImage from '../../../shared/ElevationImage'
import ElevationModal  from './ElevationModal'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  Add,
  BurstMode,
  Edit,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiCardHeader-action': {
      marginRight: 'auto',
      marginTop: 'auto',
    }
  },
  subcard: {
    minWidth: '300px',
    height: '100%',
    width: '100%',
    minHeight: theme.spacing(40),
    display: 'flex',
    flexDirection: 'column',
  },
  elevList: {
    height: theme.spacing(36),
  },
  content: {
    // overflow: 'scroll'
  }
})

function EmptyElevation() {
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h5" gutterBottom>
        No Elevations found
      </Typography>
      <Typography variant='body1' gutterBottom>
        An <b>Elevation</b> is a  two-dimensional representation of 
        facades of a structure as viewed from a specific vantage point.
      </Typography>
      <Typography variant="body1">
        Click the "Add" elevation button above to create a new elevation for this facility.
      </Typography>
    </Box>
  )
}

function ElevationCard({ facility, classes, elevations }) {
  const [ editElev, setEditElev ] = useState(null)
  const [ eleMedia, setEleMedia ] = useState('')

  return <>
    <Card className={classes.subcard}>
      <CardHeader
        avatar={<Avatar><BurstMode /></Avatar>}
        action={
        <Button
          variant='outlined'
          color='primary'
          startIcon={<Add />}
          onClick={() => setEditElev('new')}
        >
          Add
        </Button>
        }
        title='Elevations'
        titleTypographyProps={{ variant: 'h5' }}
        className={classes.cardHeader}
      />
      <CardContent className={classes.content}>
        { elevations?.length === 0 ? <EmptyElevation /> :
          <>
          { elevations.map((elevation, index) =>
            elevation.images?.[0]?.media_id &&
            <Box key={index} style={{position: 'relative'}}>
              <Box style={{cursor: 'pointer'}} onClick={() => setEleMedia(elevation.images[0].media_id)}>
                <ElevationImage elevation={elevation}/>
                </Box>
              <Box style={{position: 'absolute', zIndex: '2', bottom: '4px', width: '100%', color: '#ffffff', backgroundColor: 'rgba(0,0,0,0.5)', padding: '4px 0px 4px 12px'}}>
                <Typography variant='h6'>{elevation.name}</Typography>
                <IconButton style={{cursor: 'pointer', position: 'absolute', zIndex: '3', color: '#ffffff', right: '0px', top: '-8px'}} onClick={() => setEditElev(elevation)}><Edit /></IconButton>
              </Box>
            </Box>
          )}
          </>
        }
      </CardContent>
    </Card>
    <ElevationModal
      facility={facility}
      open={!!editElev}
      onClose={() => setEditElev(null)}
      elevation={editElev}
    />
    { eleMedia &&
      <Dialog open={true} onClose={() => { setEleMedia(false) }} fullWidth maxWidth='lg'>
        <TopXCloseButton onClick={() => { setEleMedia(false) }} />
        <DialogContent>
          <Img src={eleMedia} />
        </DialogContent>
      </Dialog>
    }
  </>
}

function Img({ src }) {
  const url = useMediaURL(src, 'fullscreen')
  return <img src={url} alt='Elevation' />
}

export default withStyles(styles)(ElevationCard)
