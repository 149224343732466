import { useState } from 'react'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import JSZip from 'jszip'
import { useAccessToken } from '../../../../api/accounts'
import {
  getMediaURL,
  useLazyGetMediaQuery,
} from '../../../../api/media'
import {
  commonFormat,
  dateFormat,
  generateRandomString,
} from '../../../../utils'

import DownloadDialog from './DownloadDialog'
import {
  Button,
} from '@material-ui/core'
import {
  GetApp,
} from '@material-ui/icons'

function DownloadButton({
  className,
  imageArray,
  imageArraySelect,
  inspection,
  facilityName,
}) {
  const access_token = useAccessToken()
  const [ dlState, setDlState ] = useState('stop') // stop, prepare, download
  const [ dlCount, setDlCount ] = useState(0)
  const [ message, setMessage ] = useState('')
  const [ getMedia ] = useLazyGetMediaQuery()

  const selectedImages = imageArray.filter((img, i) => imageArraySelect[i])
  const dlTotal = selectedImages.length

  function handlePrepare() {
    if (!Array.isArray(imageArray))
      return

    setDlState('prepare')
    const media_list = selectedImages.map(m => m.media_id)

    getMedia({ media_list })
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('Failed to get media', fulfilled)
        toast.warn('Failed to get media')
        return
      }
      // TODO: Old files has no file_size in metadata. To update all all files with file_size
      //       and undo this check.
      const file_size_arr = fulfilled.data?.media?.map(elem => {
        const size_in_mb = Number(elem?.file_size || 0) / (1024 * 1024)
        return size_in_mb
      })
      if (file_size_arr.includes(0)) {
        setMessage('This may take considerable amount of time to download. Do you want to proceed?')
      }
      else {
        const sum = file_size_arr.reduce((a, v) => (a + v), 0)
        const eta_minutes = Math.ceil(sum * 8 / 10 / 60) // MB over 10Mbps

        if (eta_minutes <= 1)
          return handleDownload() // one minute or less no need to wait.
        else
          setMessage(`This will download ${media_list.length} images in its original resolution,
            with an estimated total size of ${Math.ceil(sum)} MB. This is expected to take
            ${eta_minutes} minutes on a 10Mbps network. Do you want to proceed?`)
      }
      setDlCount(0)
    })
    .catch(rejected => {
      console.log('Error getting media', rejected)
      toast.error('Error getting media')
      setDlState('stop')
    })
  }

  async function handleDownload() {
    setMessage(`Zipping ${dlTotal} images...`)
    setDlState('download')

    const work_items = selectedImages.map(m => ({
      url: getMediaURL(m.media_id, 'original', access_token),
      filename: m.filename
    }))
    const blob_array = []
    for (const i in work_items) {
      let response = await fetch(work_items[i].url)
      response = await response.blob()
      blob_array.push(response)
      setDlCount(i)
    }
    zipBlobArray(blob_array, work_items)
  }

  async function zipBlobArray(blob_array, work_items) {
    const date_start = format(new Date(inspection.start_date), dateFormat)
    const now = format(new Date(), commonFormat)

    try {
      const zip = new JSZip()
      const folderName = inspection.name.replaceAll('/', '-')
      const folder = zip.folder(`${folderName} inspection on ${date_start}`)
      blob_array.forEach((blob, i) => {
        folder.file(`${work_items[i].filename || generateRandomString(10)}`,
          blob, { binary: true, createFolders: true })
      })
      const zipBlob = await zip.generateAsync({ type: 'blob' })
      const aElement = document.createElement('a')
      aElement.href = URL.createObjectURL(zipBlob)
      aElement.download = `${facilityName} inspection images downloaded on ${now}.zip`
      aElement.click()
      setDlState('stop')
      setDlCount(0)
    }
    catch (err) {
      console.log('dl failed', err)
      setMessage('Downloading failed')
      setDlCount(0)
    }
  }

  function handleClose() {
    setDlState('stop')
    setDlCount(0)
  }

  return (<>
    <Button
      className={className}
      variant='contained'
      color='primary'
      startIcon={<GetApp />}
      onClick={handlePrepare}>
      Download
    </Button>
    { dlState !== 'stop' &&
      <DownloadDialog
        dlState={dlState}
        dlCount={dlCount}
        dlTotal={dlTotal}
        onClose={handleClose}
        message={message}
        onDownload={handleDownload}
      />
    }
  </>)
}

export default DownloadButton
