import { Box, Button } from '@material-ui/core'

function DialogActionButtons({ onNext, onCancel, disableNext, backText = 'Back', nextText = 'Next' }) {
  return (
    <Box display='flex' justifyContent='space-between' width='100%'>
      <Button
        variant='outlined'
        onClick={onCancel}>
        {backText}
      </Button>
      { nextText ? 
        <Button
          variant='contained'
          color='primary'
          disabled={disableNext}
          onClick={onNext}>
          {nextText}
        </Button>
        : null
      }
    </Box>
  )
}

export default DialogActionButtons