import { Box, Button, Chip, Grid, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'

import InspectionReportsCard from './LastInspection/InspectionReportsCard'
import DetectionsCard from './LastInspection/DetectionsCard'
import ImagesCard from './LastInspection/ImagesCard'
import UpcomingInspectionsCard from './LastInspection/UpcomingInspectionsCard'

import { useFacilityInspections } from '../../../shared/hooks/inspections'
import { dateFormat } from '../../../../utils'
import { history } from '../../../../utils/store'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  chip: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1)
  }
})

const LastInspectionBar = ({ classes, facility }) => {
  const { inspections } = useFacilityInspections({ facility_ids: facility?._id })

  // add in when able to get last inspection marked as completed
  const lastCompletedInspection = inspections?.[0]

  return (
    lastCompletedInspection != null && <>
    <Box display='flex' style={{marginBottom: '8px'}}>
      <Typography variant='h5'>From your last inspection</Typography>
      <Chip
        className={classes.chip}
        color='primary'
        label={format(new Date(lastCompletedInspection.start_date), dateFormat)}
        onClick={() => history.push('/inspection/' + lastCompletedInspection._id) }
      />
    </Box>
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={3}>
        <InspectionReportsCard inspection={lastCompletedInspection} />
      </Grid>
      <Grid item xs={3}>
        <DetectionsCard inspection={lastCompletedInspection}/>
      </Grid>
      <Grid item xs={3}>
        <ImagesCard inspection={lastCompletedInspection} />
      </Grid>
      <Grid item xs={3}>
        <Box height='100%' display='flex' flexDirection='column'>
          <UpcomingInspectionsCard inspections={inspections} />
          <Button
            variant='contained'
            color='secondary'
            fullWidth
            startIcon={<Add />}
            onClick={() => history.push('/inspections?facilityId=' + facility._id + '&action=new')}
          >
            New Inspection
          </Button>
        </Box>
      </Grid>
    </Grid>
  </>)
}

export default withStyles(styles)(LastInspectionBar)