
import TopXCloseButton from '../../../shared/TopXCloseButton'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { useGetMLModelByIdQuery } from '../../../../api/geoAiConfig'

const styles = theme => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  }
})

function AIApplyLabelsDialog({ classes, open, onClose, mlModelId }) {

  const { data, isLoading } = useGetMLModelByIdQuery(mlModelId, {
    skip: !mlModelId
  })

  const labels = data?.data?.ml_model?.labels || []

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>Valid classes</DialogTitle>
      <DialogContent>
        { labels.map(l =>
          <Typography variant='body1' gutterBottom>{l}</Typography>
        ) }
        { isLoading &&
          <Typography variant='body1'>
            Loading Labels... <CircularProgress />
          </Typography>
        }
        { !isLoading && labels.length === 0 &&
          <Typography variant='body1' className={classes.error}>
            No Labels Found
          </Typography>
        }
      </DialogContent>
    </Dialog>
  )
}


export default withStyles(styles)(AIApplyLabelsDialog)
