import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { useAccessToken } from './accounts'
import { standardHeaders } from './api-utils'

const MEDIA_API = new URL(process.env.REACT_APP_MEDIA_API).href

export function useMediaURL(media_id = '', size = 'preview') {
  const access_token = useAccessToken()

  // if (!['original', 'thumb', 'preview', 'fullscreen', 'upnorth'].includes(size))
  //   throw Error('Invalid size for Media:', size)

  // Temporary construct, specifically to feed <img /> tags which can't take headers
  // TODO: Solve the problem where this link doesn't create a filename for the download
  return MEDIA_API + '/m/' + media_id + '/' + size + '?token=' + access_token
}

// Workaround for un-hook-able code
export function getMediaURL(media_id = '', size = 'preview', access_token) {
  if (!access_token)
    throw Error('Access Token required')

  // if (!['original', 'thumb', 'preview', 'fullscreen', 'upnorth'].includes(size))
  //   throw Error('Invalid size for Media:', size)

  // Temporary construct, specifically to feed <img /> tags which can't take headers
  // TODO: Solve the problem where this link doesn't create a filename for the download
  return MEDIA_API + '/m/' + media_id + '/' + size + '?token=' + access_token
}

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: MEDIA_API,
    prepareHeaders: standardHeaders,
  }),
  endpoints: (builder) => ({

    getMediaById: builder.query({
      query: (mediaId) => `/m/${mediaId}`,
    }),

    getMedia: builder.query({
      query: ({ media_list }) => ({
        url: `/m/query`,
        method: 'POST',
        body: { media_list },
      }),
    })

  }),
})
// TODO: Upload, Upload Large

export const {
  useGetMediaQuery,
  useLazyGetMediaQuery,
  useGetMediaByIdQuery,
} = mediaApi
