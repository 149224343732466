import { Link } from 'react-router-dom'
import { useGetMediaByIdQuery } from '../../../../api/media'

import LabelledImage    from '../../../shared/LabelledImage'
import DefectProperties from './DefectProperties'

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tooltip,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  card: {
    marginTop: '16px',
    width: '260px',
  },
  content: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  severity: ({ defect }) => ({
    height: theme.spacing(2),
    backgroundColor: defect?.severity === 'high' ? theme.palette.error.main :
      defect?.severity === 'medium' ? theme.palette.secondary.main :
      defect?.severity === 'low' ? theme.palette.warning.main :
      theme.palette.common.white
  })
})

function DefectCard({ classes, defect, inspectionId }) {
  const mediaId = defect.instance.media_id
  const { data } = useGetMediaByIdQuery(mediaId, {
    skip: !mediaId
  })
  if (!defect)
    return

  const media = data?.data?.media

  const imgW = media?.exif?.image_width
  const imgH = media?.exif?.image_height

  const labels = [{
    shape: defect.instance.shape,
    x: defect.instance.x,
    y: defect.instance.y,
    w: defect.instance.w,
    h: defect.instance.h,
    polygon: defect.instance.polygon,
    imgW: imgW,
    imgH: imgH,
  }]

  return (
    <Card className={classes.card}>
      <CardActionArea
        component={Link}
        to={`/inspection/${inspectionId}/detections/${defect._id}`}>
        {media &&
          <CardMedia>
            <LabelledImage
              media_id={defect.instance.media_id}
              size='preview'
              labels={labels}
              scaleWidth={260}
            />
          </CardMedia>
        }
        <CardContent className={classes.content}>
          <DefectProperties defect={defect} />
        </CardContent>
        <Tooltip title={defect.severity ? `${defect.severity} severity` : ''}>
          <div className={classes.severity} />
        </Tooltip>
      </CardActionArea>
    </Card>
  )
}

export default withStyles(styles)(DefectCard)
