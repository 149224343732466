import { useState, useMemo } from 'react'
import {
  useGetDefectByIdQuery,
  useUpdateDefectMutation,
} from '../../../../api/inspectionOps'

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import { dd_mmm_yyyyEpoch, hh_mm_ssEpoch } from '../../../../utils'

const styles = theme => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    height: `calc(100% - ${theme.spacing(5)}px)`,
  },
  stickyContainer: {
    minWidth:     '50%',
    position:     'fixed',
    bottom:       theme.spacing(5),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  inputField: {
    flexGrow: 1,
  },
  inputIcons: {
    flexGrow: 0,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  }
})

function ActivityLog({ classes, defectId, oidc }) {

  const [ userNote,   setUserNote   ] = useState('')

  const { data } = useGetDefectByIdQuery(defectId, {
    skip: !defectId,
  })
  const defectLogs = useMemo(() => {
    const logs = data?.data?.defect.activity_logs
    if (logs) {
      setUserNote('')
      return logs
    }
    return []
  }, [data])

  const [ updateDefect, updateResult ] = useUpdateDefectMutation()
  const [ error, setError ] = useState('')

  const handleSubmit = async () => {
    if (!userNote)
        return null

    updateDefect({ _id: defectId, note: userNote })
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      console.log('update result', updateResult)
      if (fulfilled.status !== 'success') {
        setError('Failed to add note.')
      }
    })
    .catch(rejected => {
      console.log('update rejected', rejected)
      setError('Error adding notes.')
    })
  }

  return (
    <div>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Date, Time</b></TableCell>
              <TableCell><b>By</b></TableCell>
              <TableCell><b>Activity / Note</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { defectLogs?.map((defectLog, i) => (
              <TableRow key={i}>
                <TableCell>
                  {dd_mmm_yyyyEpoch(defectLog.created_on)}, {hh_mm_ssEpoch(defectLog.created_on)}
                </TableCell>
                <TableCell>{defectLog.created_by}</TableCell>
                <TableCell>{defectLog.data}</TableCell>
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.stickyContainer}>
        <div className={classes.inputContainer}>
          <TextField
            variant='outlined'
            margin='dense'
            placeholder='Add a note'
            multiline
            value={userNote}
            className={classes.inputField}
            onChange={(event) => setUserNote(event.target.value)}
          />
          {/* <IconButton>
              <AttachFileIcon fontSize='small' className={classes.inputIcons} />
          </IconButton> */}
          { error && <Typography className={classes.error}>{error}</Typography>}
          <IconButton onClick={handleSubmit}>
            <Send fontSize='small' className={classes.inputIcons} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(ActivityLog)
