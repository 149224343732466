import { dd_mmm_yyyyEpoch, hh_mm_ssEpoch } from '../../../../utils'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

function ImageMetadata({ classes, image }) {
  if (!image)
    return null

  const metadata = image.media_metadata

  return (<TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell><b>About {metadata.original_name}</b></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Latitude</TableCell>
          <TableCell>
          { metadata.exif.location.latitude || 'N/A' }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Longitude</TableCell>
          <TableCell>
          { metadata.exif.location.longitude || 'N/A' }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Altitude (AMSL)</TableCell>
          <TableCell>
          { metadata.exif.location.absolute_altitude ? (metadata.exif.location.absolute_altitude + ' m') : 'N/A' }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Date Taken</TableCell>
          <TableCell>
          { metadata.exif.date_taken ?
            dd_mmm_yyyyEpoch(metadata.exif.date_taken) + ', ' + hh_mm_ssEpoch(metadata.exif.date_taken) :
            'N/A'
          }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Original Image Height</TableCell>
          <TableCell>
          { metadata.exif.image_height || 'N/A' } px
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Original Image Width</TableCell>
          <TableCell>
          { metadata.exif.image_width || 'N/A' } px
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>)
}

export default withStyles(styles)(ImageMetadata)
