import {
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  sectionContainer: {
    overflow:     'hidden',
  },
})

function AdvancedOptions({
  classes,
  privacyMask,
  setPrivacyMask
}) {
  return (
    <div className={classes.sectionContainer}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Checkbox
            color='primary'
            value={privacyMask}
            checked={privacyMask}
            onChange={e => setPrivacyMask(e.target.checked)}
          />
        </Grid>
        <Grid item xs={11}>
          <Typography variant='body1'>
            Privacy Mask
          </Typography>
          <Typography variant='caption'>
            When enabled, Privacy Mask will ensure that all personally identifiable features from uploaded images are blurred. <b>This process is irreversible.</b>
            <br />
            <b>You cannot apply Privacy Mask after the images are uploaded.</b>
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(AdvancedOptions)
