import { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useGetDefectByIdQuery } from '../../../api/inspectionOps'

import DefectDetails from './Components/DefectDetails'
import HighResDefect from './Components/HighResDefect'
import ActivityLog   from './Components/ActivityLog'
import ImageMetadata from './Components/ImageMetadata'

import {
  Button,
  Divider,
  Tab,
  Tabs,
} from '@material-ui/core'
import {
  ChevronLeft,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    flexGrow: 1,
    maxWidth: '50%',
    height: '100%',
  },
  leftHeader: {
    backgroundColor: theme.palette.grey[200],
    height: theme.spacing(6),
    display: 'flex',
    alignItems: 'center'
  },
  rightHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  backButton: {
    margin: theme.spacing(1),
  },
  tabContent: {
    padding:  theme.spacing(1)
  }
})

const SingleDefect = ({
  classes,
  facilityId,
  facilityType,
  inspectionId,
  defectId,
}) => {

  const [ imageLabelIndex, setImageLabelIndex ] = useState(0)

  const [ curTab, setCurTab ] = useState(0)

  const { data } = useGetDefectByIdQuery(defectId, {
    skip: !defectId,
  })
  const defect = useMemo(() => (data?.data?.defect || {}), [data])

  return (
    <div style={{ display: 'flex' }}>
      <div className={classes.container}>
        <div className={classes.leftHeader}>
          <Button
            className={classes.backButton}
            startIcon={<ChevronLeft />}
            component={Link}
            to={`/inspection/${inspectionId}/detections`}>
            All defects
          </Button>
        </div>
        <DefectDetails
          defect={defect}
          inspectionId={inspectionId}
          facilityType={facilityType}
          imageLabelIndex={imageLabelIndex}
          setImageLabelIndex={setImageLabelIndex}
        />
      </div>
      <Divider orientation='vertical' flexItem />
      <div className={classes.container}>
        <div className={classes.rightHeader}>
          <Tabs
            value={curTab}
            indicatorColor='primary'
            variant='fullWidth'
            onChange={(e, newValue) => setCurTab(newValue)}>
            <Tab label='Defect' />
            <Tab label='Activity' />
            <Tab label='Metadata' />
          </Tabs>
        </div>
        <div className={classes.tabContent}>
          { curTab === 0 ? <HighResDefect defect={defect} facilityId={facilityId} facilityType={facilityType} imageLabelIndex={imageLabelIndex} image={defect.instances ? defect.instances[imageLabelIndex] : null} /> :
            curTab === 1 ? <ActivityLog defectId={defectId} /> :
            curTab === 2 ? <ImageMetadata image={defect.instances ? defect.instances[imageLabelIndex] : null} /> : null
          }
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(SingleDefect)
