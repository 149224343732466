import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
}

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    loadItems: (state, action) => {
      state.items = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { loadItems } = paginationSlice.actions

export default paginationSlice.reducer