import {
  Button,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  grid: {
    padding: '2px'
  }
})

function FilterControls({ classes, onSelectAll, onUnselectAll, onExitSelect }) {
  return (
    <Grid container direction='row' justifyContent='flex-end'>
      <Grid item className={classes.grid}>
        <Button
          variant='outlined'
          color='primary'
          onClick={onSelectAll} >
          Select All
        </Button>
      </Grid>
      <Grid item className={classes.grid}>
        <Button
          variant='outlined'
          color='primary'
          onClick={onUnselectAll} >
          Unselect All
        </Button>
      </Grid>
      <Grid item className={classes.grid}>
        <Button
          className={classes.button}
          variant='contained'
          onClick={onExitSelect}>
          Exit Select
        </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(FilterControls)
