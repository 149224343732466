import TopXCloseButton from './TopXCloseButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
  actions: {
    margin: 0,
    padding: theme.spacing(1),
  }
})

function MessageDialog({ classes, title, description, buttonText, onClose }) {

  const handleButtonClick = (behavior) => {
    //TODO: Implement generic behaviours if any
    onClose && onClose()
  }
  function handleClose() {
    onClose && onClose()
  }
  return (
    <Dialog open={!!title} onClose={handleClose}>
      <TopXCloseButton onClick={handleClose} />
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant='h6'>{title}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <Typography gutterBottom>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color='primary'
          onClick={() => handleButtonClick()}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(MessageDialog)
