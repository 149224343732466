import {
  Box,
  Card,
  CardContent,
  Link,
  withStyles
} from '@material-ui/core'
import {
  FilterHdr,
  Public,
  Room,
  Subject,
} from '@material-ui/icons'

import { FacilityTypeIcon, FacilityStatusIcon } from '../../../shared/Icons'

function toTitleCase(str) {
  if (!str) return str
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  icon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
})

const ProfileInfo = ({ classes, facility }) => {
  const mapsUrl = `https://maps.google.com/?q=`
                  + `${facility?.centroid?.coordinates?.[1]},${facility?.centroid?.coordinates?.[0]}`
  
  const IconText = ({ Icon, RawIcon, children }) => {
    return <Box display='flex' flexDirection='row' my={1}>
      { Icon && <Icon className={classes.icon} /> }
      { RawIcon && RawIcon }
      <Box ml={2}>{ children }</Box>
    </Box>
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <IconText RawIcon={<FacilityStatusIcon type={facility.status} className={classes.icon}/>}>
          {toTitleCase(facility.status)}
        </IconText>
        <IconText Icon={Subject}>
          {facility.notes || 'No notes'}
        </IconText>
        <IconText Icon={Room}>
          {facility.address || 'No address'}
        </IconText>
        <IconText RawIcon={<FacilityTypeIcon type={facility.type} className={classes.icon} />}>
          {facility?.height?.metres || '-'} metres, {facility?.height?.stories || '-'} stories
        </IconText>
        <IconText Icon={Public}>
          {facility?.centroid?.coordinates?.[1] || 'unknown latitude'},&nbsp;
          {facility?.centroid?.coordinates?.[0] || 'unknown longitude'}&nbsp;
          (view on <Link href={mapsUrl} target='_blank'>Google Maps</Link>)
        </IconText>
        <IconText Icon={FilterHdr}>
          { facility?.centroid?.coordinates?.[2]
            ? (facility?.centroid?.coordinates?.[2] + ' m')
            : 'unknown altitude (mean sea level)'
          }
        </IconText>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(ProfileInfo)