export const dateFormat   = 'dd MMM yyyy'         // 25 Jul 1980
export const timeFormat   = 'hh:mm:ss aaaa'       // 05:02:01 p.m.
export const commonFormat = 'yyyy-MM-dd HH:mm:ss' // 1980-07-25 17:02:01

// TODO: Replace the date functions here with date-fns format

export const dd_mmm_yyyy = (dateObj) => {
  if (!dateObj)
    return ''
  const year = dateObj.getFullYear()
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][dateObj.getMonth()]
  const date = dateObj.getDate()
  return `${date} ${month} ${year}`
}

export const dd_mmm_yyyyEpoch = (epoch) => {
  return dd_mmm_yyyy(new Date(epoch))
}

export const hh_mm_ss = (dateObj) => {
  if (!dateObj)
    return ''
  const hour = dateObj.getHours()
  const min  = dateObj.getMinutes()
  const sec  = dateObj.getSeconds()
  return hour + ':' + (min < 10 ? ('0' + min) : min) + ':' + (sec < 10 ? ('0' + sec) : sec)
}

export const hh_mm_ssEpoch = (epoch) => {
  return hh_mm_ss(new Date(epoch))
}

export const full_date = (dateObj) => {
  if (!dateObj)
    return ''
  return dd_mmm_yyyy(dateObj) + ' ' + hh_mm_ss(dateObj)
}

export const full_dateEpoch = (epoch) => {
  return full_date(new Date(epoch))
}

export const dateRange = (startDate, endDate, defaultStart = 'not set', defaultEnd = 'ongoing') => {
  return (startDate ? dd_mmm_yyyy(startDate) : defaultStart) +
    ' - ' + (endDate ? dd_mmm_yyyy(endDate) : defaultEnd)
}

export const dateRangeEpoch = (startEpoch, endEpoch) => {
  return dateRange(new Date(startEpoch), new Date(endEpoch))
}

export const startOfDay = (date) => {
  const d = date instanceof Date ? date : new Date(date)
  return new Date(d.getFullYear(), d.getMonth(), d.getDate())
}

export const endOfDay = (date) => {
  const d = date instanceof Date ? date : new Date(date)
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59)
}

// The 'eslint-disable-next-line' comment disables the warning message related to unnecessary escape characters
export const validEmail = (email) => {
  //eslint-disable-next-line
  return !!email.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/)
}

export const pretty = (string) => {
  let i, fragments = string.split('_')
  for (i = 0; i < fragments.length; i++) {
    fragments[i] = fragments[i].charAt(0).toUpperCase() + fragments[i].slice(1)
  }
  return fragments.join(' ')
}

export function generateRandomString(length) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

/**
 * TODO: To Deprecate. Use media service's metadata to determine size, not load the picture to determine size.
 * Get dimensions of any image by url
 * @param {string} url
 * @return {Promise<{ width: number, height: number }>}
 */
// export const imageSize = (url) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image()
//     img.addEventListener('load', () => { resolve(img) })
//     img.addEventListener('error', (event) => {
//       reject(`${event.type}: ${event.message}`)
//     })
//     img.src = url
//   })
// }


