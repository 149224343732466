import axios from 'axios'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { makeQueryString, standardHeaders } from './api-utils'

const INSPECTION_OPS_API = new URL(process.env.REACT_APP_INSPECTION_OPS_API).href
const OFFLINE_ANNOTATE_URL = new URL(process.env.REACT_APP_OFFLINE_ANNOTATE_URL).href

export const offlineAnnotateURL = OFFLINE_ANNOTATE_URL

export const inspectionOpsApi = createApi({
  reducerPath: 'inspectionOpsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: INSPECTION_OPS_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: [
    'Defects',
    'Document Types',
    'Documents',
    'Elevations',
    'Facilities',
    'Inspections',
    'Labels',
  ],

  // Note: query only takes 1 parameter (need to combine params into object)
  endpoints: (builder) => ({

    // ===== FACILITIES =====
    getFacilities: builder.query({
      query: (params) => (`/facilities${makeQueryString(params, [
        'status',
        'type',
        'keyword',
        'bound'
      ])}`),
      providesTags: ['Facilities']
    }),
    getFacilityById: builder.query({
      query: (facilityId) => `/facilities/${facilityId}`,
      providesTags: ['Facilities'],
    }),
    createFacility: builder.mutation({
      query: (facility) => ({
        url: `/facilities`,
        method: 'POST',
        body: facility
      }),
      invalidatesTags: ['Facilities'],
    }),
    updateFacility: builder.mutation({
      query: ({ _id, ...facility }) => ({
        url: `/facilities/${_id}`,
        method: 'PATCH',
        body: facility
      }),
      invalidatesTags: ['Facilities'],
    }),
    updateFacilityStatus: builder.mutation({
      query: ({ _id, ...status }) => ({
        url: `/facilities/${_id}/status`,
        method: 'PATCH',
        body: status
      }),
      invalidatesTags: ['Facilities'],
    }),
    deleteFacility: builder.mutation({
      query: (_id) => ({
        url: `facilities/${_id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Facilities'],
    }),
    updateFacilityProfileImage: builder.mutation({
      query: ({ facilityId, image }) => {
        const fd = new FormData()
        fd.append('file', image)
        return {
          url: `/facilities/profileimage/${facilityId}`,
          method: 'POST',
          // headers: { 'content-type': 'multipart/form-data' },
          // TODO: No hardcoding of content length in code, to handle in nginx
          maxContentLength: 268435456, // 256MB in bytes
          maxBodyLength: 268435456,
          body: fd
        }
      },
      invalidatesTags: ['Facilities'],
    }),
    createFacilityElevation: builder.mutation({
      query: ({ facilityId, formData }) => ({
        url: `/facilities/${facilityId}/elevation`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Facilities'],
    }),
    updateFacilityElevation: builder.mutation({
      query: ({ facilityId, oldName, formData }) => ({
        url: `/facilities/${facilityId}/elevation/${oldName}`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: ['Facilities'],
    }),
    deleteFacilityElevation: builder.mutation({
      query: ({ facilityId, name }) => ({
        url: `/facilities/${facilityId}/elevation/${name}`,
        method: 'DELETE',
        body: { force: true },
      }),
      invalidatesTags: ['Facilities'],
    }),

    // ===== ELEVATIONS ======
    getElevations: builder.query({
      query: (params) => (`/elevations${makeQueryString(params, ['facility_id'])}`),
      providesTags: ['Elevations']
    }),
    getElevationById: builder.query({
      query: (elevationId) => `/elevations/${elevationId}`,
      providesTags: ['Elevations']
    }),
    createElevation: builder.mutation({
      query: (elevation) => ({
        url: '/elevations',
        method: 'POST',
        body: elevation
      }),
      invalidatesTags: ['Elevations']
    }),
    updateElevation: builder.mutation({
      query: ({_id, elevation}) => ({
        url: `/elevations/${_id}`,
        method: 'PATCH',
        body: elevation
      }),
      invalidatesTags: ['Elevations']
    }),
    deleteElevation: builder.mutation({
      query: (_id) => ({
        url: `elevations/${_id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Elevations']
    }),
    updateElevationImage: builder.mutation({
      query: ({ elevationId, image}) => {
        const fd = new FormData()
        fd.append('file', image)
        return {
          url: `/elevations/${elevationId}/image`,
          method: 'POST',
          body: fd
        }
      },
      invalidatesTags: ['Elevations']
    }),

    // ===== INSPECTIONS =====
    getInspections: builder.query({
      query: (params) => (`/inspections${makeQueryString(params, [
        'status',
        'type',
        'facility_ids',
      ])}`),
      providesTags: ['Inspections'],
    }),
    getInspectionById: builder.query({
      query: (inspectionId) => `/inspections/${inspectionId}`,
      providesTags: ['Inspections'],
    }),
    getInspectionSummaryById: builder.query({
      query: (inspectionId) => `/inspections/${inspectionId}/summary`,
      providesTags: ['Inspections'],
    }),
    createInspection: builder.mutation({
      query: (inspection) => ({
        url: `/inspections`,
        method: 'POST',
        body: inspection,
      }),
      invalidatesTags: ['Inspections'],
    }),
    updateInspection: builder.mutation({
      query: ({ _id, ...inspection }) => ({
        url: `/inspections/${_id}`,
        method: 'PATCH',
        body: inspection
      }),
      invalidatesTags: ['Inspections'],
    }),
    updateInspectionStatus: builder.mutation({
      query: ({ _id, ...status }) => ({
        url: `/inspections/${_id}/status`,
        method: 'PATCH',
        body: status
      }),
      invalidatesTags: ['Inspections'],
    }),

    // ===== INSPECTION IMAGES =====
    getImages: builder.query({
      query: (params) => {
        const formattedParams = { ...params }
        if (formattedParams.inspection_id) {
          // Need to ensure backwards compatibility with the rest of the website
          formattedParams.inspection_ids = [params.inspection_id]
        }

        if (formattedParams.elevations?.length > 0) {
          // For backward compatibility, new calls should use facility_elevations
          const encodedElevations = JSON.stringify(formattedParams.elevations)
          formattedParams.facility_elevations = encodedElevations
          delete formattedParams.elevations
        }

        return (`/images${makeQueryString(formattedParams, [
          'inspection_ids',
          'size',
          'page',
          'limit',
          'sort',
          'favourite',
          'hidden',
          'facility_elevations',
        ])}`)
      },
      providesTags: ['Images'],
    }),
    createImage: builder.mutation({
      query: (formData) => ({
        url: '/images',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Images']
    }),
    createImageElevation: builder.mutation({
      query: ({ inspectionImageId, elevationId }) => ({
        url: `/images/${inspectionImageId}/elevation/${elevationId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Images', 'Elevations']
    }),
    deleteImageElevation: builder.mutation({
      query: ({ inspectionImageId, elevationId }) => ({
        url: `/images/${inspectionImageId}/elevation/${elevationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Images', 'Elevations']
    }),
    favouriteImages: builder.mutation({
      query: (imageIds) => ({
        url: `/images/favourite`,
        method: 'POST',
        body: { image_ids: imageIds },
      }),
      invalidatesTags: ['Images']
    }),
    unfavouriteImages: builder.mutation({
      query: (imageIds) => ({
        url: `/images/unfavourite`,
        method: 'POST',
        body: { image_ids: imageIds },
      }),
      invalidatesTags: ['Images']
    }),
    hideImages: builder.mutation({
      query: (imageIds) => ({
        url: `/images/hide`,
        method: 'POST',
        body: { image_ids: imageIds },
      }),
      invalidatesTags: ['Images', 'Elevations']
    }),
    unhideImages: builder.mutation({
      query: (imageIds) => ({
        url: `/images/unhide`,
        method: 'POST',
        body: { image_ids: imageIds },
      }),
      invalidatesTags: ['Images']
    }),

    // ===== INSPECTION IMAGE LABELS =====
    createLabelWithDefect: builder.mutation({
      query: (labelWithDefect) => ({
        url: `/labels?new_defect=true`,
        method: 'POST',
        body: labelWithDefect,
      }),
      invalidatesTags: ['Labels', 'Defects']
    }),
    deleteLabel: builder.mutation({
      query: ({ labelId, ...options }) => ({
        url: `/labels/${labelId}`,
        method: 'DELETE',
        body: options,
      }),
      invalidatesTags: ['Labels']
    }),
    updateLabel: builder.mutation({
      query: ({ labelId, ...newLabel }) => ({
        url: `/labels/${labelId}`,
        method: 'PATCH',
        body: newLabel,
      }),
      invalidatesTags: ['Labels']
    }),

    createFaultFinder: builder.mutation({
      query: (message) => ({
        url: `/faultfinder`,
        method: 'POST',
        body: message
      }),
      invalidatesTags: ['Labels']
    }),

    // ===== INSPECTION DEFECTS =====
    getDefectById: builder.query({
      query: (defectId) => `/defects/${defectId}`,
      providesTags: ['Defects']
    }),
    getDefects: builder.query({
      query: (params) => (`/defects${makeQueryString(params, [
        'status',
        'inspection_ids',
        'image_id',
        'generic',
        'surface',
        'type',
        'severity',
        'facility_elevations',
        'sort',
      ])}`),
      providesTags: ['Defects']
    }),
    getDefectCategoriesByFacilityType: builder.query({
      query: (facility_type) => `/defects/categories?facility_type=${facility_type}`,
      providesTags: ['Defects']
    }),
    locateDefect: builder.query({
      query: ({ elevation_id, label_media, inspection_id }) => ({
        url: `/defects/locate/${elevation_id}`,
        method: 'POST',
        body: { label_media, inspection_id }
      }),
      providesTags: ['Defects']
    }),
    relocateDefect: builder.query({
      query: ({ elevation_id, points }) => ({
        url: `/defects/2d-to-location/${elevation_id}`,
        method: 'POST',
        body: { points }
      }),
      providesTags: ['Defects']
    }),
    updateDefect: builder.mutation({
      query: ({ _id, ...body }) => ({
        url: `/defects/${_id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Defects'],
    }),

    // ===== DOCUMENTS =====
    getDocumentTypes: builder.query({
      query: () => '/document-types',
      providesTags: ['Document Types']
    }),
    getDocuments: builder.query({
      query: ({ inspectionId, facilityId, typeId, name }) => {
        const paramsArray = []
        if (inspectionId)
          paramsArray.push(`inspection_id=${inspectionId}`)
        if (facilityId)
          paramsArray.push(`facility_id=${facilityId}`)
        if (typeId)
          paramsArray.push(`type_id=${typeId}`)
        if (name)
          paramsArray.push(`name=${name}`)
        const params = paramsArray.length > 0 ? ('?' + paramsArray.join('&')) : ''
        return `/documents${params}`
      },
      providesTags: ['Documents'],
    }),
    getDocumentByDocumentId: builder.query({
      query: (documentId) => `/documents/${documentId}`,
      providesTags: ['Documents'],
    }),
    createDocument: builder.mutation({
      query: (formData) => ({
        url: '/documents',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Documents'],
    }),
    createReport: builder.mutation({
      query: ({inspection_ids}) => ({
        url: 'download-report',
        method: 'POST',
        body: {inspection_ids}
      }),
    })

    // ===== END =====
  }),
})

export const {
  // ===== FACILITIES =====
  useGetFacilitiesQuery,
  useGetFacilityByIdQuery,
  useCreateFacilityMutation,
  useUpdateFacilityMutation,
  useUpdateFacilityStatusMutation,
  useDeleteFacilityMutation,
  useUpdateFacilityProfileImageMutation,
  useCreateFacilityElevationMutation,
  useUpdateFacilityElevationMutation,
  useDeleteFacilityElevationMutation,

  // ===== ELEVATIONS =====
  useGetElevationsQuery,
  useGetElevationByIdQuery,
  useCreateElevationMutation,
  useUpdateElevationMutation,
  useDeleteElevationMutation,
  useUpdateElevationImageMutation,

  // ===== INSPECTIONS =====
  useGetInspectionsQuery,
  useGetInspectionByIdQuery,
  useGetInspectionSummaryByIdQuery,
  useCreateInspectionMutation,
  useUpdateInspectionMutation,
  useUpdateInspectionStatusMutation,

  // ===== INSPECTION IMAGES =====
  useGetImagesQuery,
  useLazyGetImagesQuery,
  useCreateImageMutation,
  useCreateImageElevationMutation,
  useDeleteImageElevationMutation,

  useFavouriteImagesMutation,
  useUnfavouriteImagesMutation,
  useHideImagesMutation,
  useUnhideImagesMutation,

  // ===== INSPECTION IMAGE LABELS =====
  useCreateLabelWithDefectMutation,
  useDeleteLabelMutation,
  useUpdateLabelMutation,

  // ===== DEFECTS =====
  useGetDefectByIdQuery,
  useGetDefectsQuery,
  useGetDefectCategoriesByFacilityTypeQuery,
  useLocateDefectQuery,
  useRelocateDefectQuery,
  useUpdateDefectMutation,

  // ===== FAULTFINDER AI =====
  useCreateFaultFinderMutation,

  // ===== DOCUMENTS =====
  useGetDocumentTypesQuery,
  useGetDocumentsQuery,
  useGetDocumentByDocumentIdQuery,
  useCreateDocumentMutation,
  useCreateReportMutation,

} = inspectionOpsApi

// TODO: This is orphaned - what is it supposed to do?
export function startDetect({ inspection_id, image_ids }) {
  return axios.post(`${INSPECTION_OPS_API}/detect`, {
    inspection_id,
    image_ids,
  })
  .catch(err => {
    console.log('Detection failed', err)
  })
}
