import { useState } from 'react'
import { hasValidSub } from '../../../../api/accounts'
import { history } from '../../../../utils/store'
import { useFacilitiesSubscription } from '../../../../utils/hooks'

import SubscriptionDialog  from '../../../shared/SubscriptionDialog'
import MessageDialog       from '../../../shared/MessageDialog'
import ElevationDialog     from './ElevationDialog'
import GalleryFilterDrawer from './GalleryFilterDrawer'
import DownloadButton      from './DownloadButton'
import {
  Button,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
} from '@material-ui/core'
import {
  BurstMode,
  CheckCircle,
  CheckCircleOutline,
  Close,
  CloudUpload,
  FilterList,
  Favorite,
  FavoriteBorder,
  MoreHoriz,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  control: {
    marginRight: theme.spacing(1)
  },
})

function GalleryControls({
  classes,
  inspection,
  sort,
  onSort,
  elevations = [],
  elevationFilter,
  setElevationFilter,
  favouriteFilter,
  setFavouriteFilter,
  showHiddenFilter,
  setShowHiddenFilter,
  multiSelect,
  setMultiSelect,
  selectAll,
  multiFavourite,
  multiUnfavourite,
  multiHide,
  multiUnhide,
  imageArray,
  imageArraySelect,
  hasUploadedImages,
  facilityName,
}) {
  const inspectionId = inspection?._id

  const [ drawerOpen, setDrawerOpen ] = useState(false)
  const [ elevMode,   setElevMode   ] = useState(null)  // null, 'Add', 'Remove'

  const [ message, setMessage ] = useState({
    title: '',
    description: '',
    buttonText: ''
  })

  const subscribed = hasValidSub(useFacilitiesSubscription())
  const [ openSub,    setOpenSub    ] = useState(false)

  const inspectionIsLoading = !inspection

  const handleUpload = () => {
    console.log('inspection', inspection)
    if (subscribed) {
      history.push(`/inspection/${inspectionId}/upload`) 
    } else {
      setOpenSub(true)
    }
  }

  function UploadButton() {
    return <Button
      variant='contained'
      color='secondary'
      startIcon={<CloudUpload />}
      disabled={inspectionIsLoading}
      onClick={handleUpload}>
      Upload
    </Button>
  }

  function handleElevFilter(elevation) {
    if (elevationFilter.includes(elevation)) {
      setElevationFilter(prevState => prevState.filter(elevationFilter => elevationFilter !== elevation))
    } else {
      setElevationFilter(prevState => [...prevState, elevation])
    }
  }

  function handleAddElev() {
    const filteredImages = imageArray.filter((image, index) => imageArraySelect[index])
    const hasFacilityElevation = filteredImages.some(image => image.facility_elevations.length > 0)
    console.log(filteredImages, hasFacilityElevation)
    if(hasFacilityElevation) {
      setMessage({
        title: 'Select images with no elevations',
        description: 'An image can only have one elevation. One or more of the images selected already has an elevation associated with it. Please select images with no elevations, or remove elevations from the images and retry.',
        buttonText: 'OK'
      })
    } else {
      setElevMode('Add')
    }
  }

  function handleMessageClose() {
    setMessage({
      title: '',
      description: '',
      buttonText: ''
    })
  }

  return <>
    <GalleryFilterDrawer
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      elevations={elevations}
      elevationFilter={elevationFilter}
      onElevationFilter={handleElevFilter}
      favouriteFilter={favouriteFilter}
      setFavouriteFilter={setFavouriteFilter}
      showHiddenFilter={showHiddenFilter}
      setShowHiddenFilter={setShowHiddenFilter}
    />
    <Grid container
      direction='row'
      justifyContent='space-between'
      alignItems='center'>
      <Grid item xs={9}>
        { hasUploadedImages && !multiSelect &&
          <>
            <OpenMultiSelectButton
              onClick={() => setMultiSelect(true)} />
            <FilterButton
              onClick={() => setDrawerOpen(true)} />
            <SortFormControl
              sort={sort} onSort={onSort} />
          </>
        }
        { hasUploadedImages && multiSelect &&
          <>
            <MultiSelectButtons
              onSelectAll={() => selectAll(true)}
              onUnselectAll={() => selectAll(false)}
            />
            { imageArraySelect?.includes(true) && <>
              {/* <AddElevationButton
                onClick={() => setElevMode('Add')} />
              <RemoveElevationButton
                onClick={() => setElevMode('Remove')} /> */}
              <DownloadButton
                className={classes.control}
                imageArray={imageArray}
                imageArraySelect={imageArraySelect}
                inspection={inspection}
                facilityName={facilityName}
              />
              <MoreButton
                onMultiFavourite={multiFavourite}
                onMultiUnfavourite={multiUnfavourite}
                onAddElevation={handleAddElev}
                onRemoveElevation={() => setElevMode('Remove')}
                onHideImages={multiHide}
                onUnhideImages={multiUnhide}
              />
            </> }
          </>
        }
      </Grid>
      <Grid item xs={3} style={{ textAlign: 'right' }}>
        { !multiSelect && <UploadButton /> }
        { multiSelect && <ExitMultiSelectButton onClick={() => setMultiSelect(false)} /> }
      </Grid>
    </Grid>
    <ElevationDialog
      mode={elevMode}
      open={!!elevMode}
      onClose={() => setElevMode(null)}
      elevations={elevations}
      imageArray={imageArray}
      imageArraySelect={imageArraySelect}
    />
    <MessageDialog 
      open={!!message.title}
      title={message.title}
      description={message.description}
      buttonText={message.buttonText}
      onClose={handleMessageClose}
    />
    { openSub && <SubscriptionDialog onClose={() => setOpenSub(false)} /> }
  </>
}

const controlStyles = theme => ({
  control: {
    marginRight: theme.spacing(1)
  },
  favouritesIcon: {
    color: theme.palette.favourites.main
  },
  primaryMenuIcon: {
    color: theme.palette.primary.main
  }
})

function FilterButton({ onClick }) {
  const classes = makeStyles(controlStyles)()
  return <Button
    className={classes.control}
    variant='contained'
    color='primary'
    startIcon={<FilterList />}
    onClick={onClick}>
    Filter
  </Button>
}

function SortFormControl({ sort, onSort }) {
  const classes = makeStyles(controlStyles)()
  return <FormControl
    variant='outlined'
    size='small'
    className={classes.control}>
    <Select
      name='sort'
      defaultValue='exif_created_time_desc'
      value={sort}
      onChange={e => onSort(e)}
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        getContentAnchorEl: null
      }}>
      <MenuItem disabled>Sort By</MenuItem>
      <MenuItem value='exif_created_time_desc'>Created Time (Most Recent)</MenuItem>
      <MenuItem value='exif_created_time_asc'>Created Time (Oldest)</MenuItem>
      <MenuItem value='filename_asc'>Filename (A to Z)</MenuItem>
      <MenuItem value='filename_desc'>Filename (Z to A)</MenuItem>
      <MenuItem value='relative_altitude_asc'>Relative Altitude (Lowest to Highest)</MenuItem>
      <MenuItem value='relative_altitude_desc'>Relative Altitude (Highest to Lowest)</MenuItem>
    </Select>
  </FormControl>
}

function OpenMultiSelectButton({ onClick }) {
  const classes = makeStyles(controlStyles)()
  return <Button
    className={classes.control}
    variant='contained'
    color='primary'
    startIcon={<CheckCircle />}
    onClick={onClick}>
    Select
  </Button>
}

function MultiSelectButtons({
  onSelectAll,
  onUnselectAll,
}) {
  const classes = makeStyles(controlStyles)()
  return <>
    <Button
      className={classes.control}
      variant='outlined'
      color='primary'
      startIcon={<CheckCircle />}
      onClick={onSelectAll}>
      Select All
    </Button>
    <Button
      className={classes.control}
      variant='outlined'
      color='primary'
      startIcon={<CheckCircleOutline />}
      onClick={onUnselectAll}>
      Unselect All
    </Button>

  </>
}

function ExitMultiSelectButton({ onClick }) {
  const classes = makeStyles(controlStyles)()
  return <Button
    className={classes.control}
    variant='contained'
    color='secondary'
    startIcon={<Close />}
    onClick={onClick}>
    Exit Select
  </Button>
}

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

function MoreButton({
  onMultiFavourite,
  onMultiUnfavourite,
  onAddElevation,
  onRemoveElevation,
  onHideImages,
  onUnhideImages
}) {
  const classes = makeStyles(controlStyles)()
  const [ anchorEl, setAnchorEl ] = useState(null)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (<>
    <Button
      className={classes.control}
      variant='outlined'
      color='primary'
      startIcon={<MoreHoriz />}
      onClick={handleClick}>
      More
    </Button>
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      keepMounted
      open={!!anchorEl}
      onClose={handleClose}
    >
      <MoreMenuItem
        icon={<Favorite className={classes.favouritesIcon}/>}
        text='Favourite'
        onClick={onMultiFavourite}
      />

      <MoreMenuItem
        icon={<FavoriteBorder className={classes.favouritesIcon}/>}
        text='Unfavourite'
        onClick={onMultiUnfavourite}
      />

      <MoreMenuItem
        icon={<BurstMode className={classes.primaryMenuIcon} />}
        text='Add Elevation'
        onClick={onAddElevation}
      />

      <MoreMenuItem
        icon={<BurstMode />}
        text='Remove Elevation'
        onClick={onRemoveElevation}
      />

      <MoreMenuItem
        icon={<VisibilityOff />}
        text='Hide'
        onClick={onHideImages}
      />

      <MoreMenuItem
        icon={<Visibility />}
        text='Unhide'
        onClick={onUnhideImages}
      />
    </Menu>
  </>)
}

function MoreMenuItem({ onClick, icon, text }) {
  return (
    <StyledMenuItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </StyledMenuItem>
  )
}

export default withStyles(styles)(GalleryControls)
