import { useState, useEffect } from 'react'
import {
  useMap,
  CircleMarker,
} from 'react-leaflet'
import theme from '../../../utils/site-theme'

function MyLocationMarker({ onStart, onSuccess, onFailure }) {
  const [ position, setPosition ] = useState(null)
  const map = useMap()

  useEffect(() => {
    console.log('my location marker 13', map, position)
    if (map && !position) {
      // navigator.permissions.query({ name: 'geolocation' })
      // https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
      if ('geolocation' in navigator) {
        onStart && onStart()
        navigator.geolocation.getCurrentPosition(position => {
          const latlng = [position.coords.latitude, position.coords.longitude]
          setPosition(latlng)
          map.flyTo(latlng, map.getZoom() + 1)
          onSuccess && onSuccess()
        }, err => console.warn('Error getting current position', err))
      } else {
        onFailure ? onFailure() : console.warn('No Geolocation API from browser')
      }
    }
  }, [map, position, onStart, onSuccess, onFailure])

  if (!position)
    return null

  return (
    <CircleMarker
      radius={10}
      center={position}
      pathOptions={{
        stroke: true,
        color: theme.palette.common.white,
        weight: 3,
        fill: true,
        fillColor: theme.palette.secondary.main,
        fillOpacity: 1,
      }}
    />
  )
}

export default MyLocationMarker
