
import {
  Typography,
} from '@material-ui/core'
import {
  ImageSearch,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(0, 2, 8, 4),
  },
  subtitle: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})
function AIIntro({ classes, isEnterprise }) {
  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>
        <ImageSearch className={classes.icon} />
        <Typography variant='h6'>
          Use Computer Vision algorithms to annotate defects
        </Typography>
      </div>
      <Typography variant='h3' gutterBottom color='primary'>
        Machine Learning (ML)
      </Typography>
      <Typography variant='h5' gutterBottom>
        About FaultFinder&trade;
      </Typography>
      <Typography variant='body1' gutterBottom>
        When you first use Facilities 4.0, you are using our FaultFinder
        algorithm to automatically label defects. This process will take
        several minutes to several hours depending on the number of images.
      </Typography>
      <br />
      <br />
      <Typography variant='h5' gutterBottom>
        Customise Facilties 4.0 using your own ML algorithms
      </Typography>
      { isEnterprise ? // This is a temporary way to differentiate GR/DHI/EMS from the rest
        <Typography variant='body1' gutterBottom>
          Your account (enterprise tier) allows you to build your own ML
          models and apply them to your images on in Facilities 4.0. What
          we do is to host your algorithms in our inference engine, and apply
          them to the images and videos that you uploaded or streamed onto
          the platform. Speak to our sales representatives to learn more.
        </Typography>
      : <Typography variant='body1' gutterBottom>
          Your account tier does not allow you to build your own ML models.
          Speak to our sales representatives to learn more about how you can
          utilise your own Machine Learning Models to find faults in your
          images on Facilities 4.0.
        </Typography>
      }
    </div>
  )
}


export default withStyles(styles)(AIIntro)
