import { useState } from 'react'

import {
  useCreateImageElevationMutation,
  useDeleteImageElevationMutation,
} from '../../../../api/inspectionOps'

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TopXCloseButton from '../../../shared/TopXCloseButton'

const styles = theme => ({
  chip: {
    marginBottom: '5px',
    marginRight: '5px'
  },
})

function ElevationDialog({
  classes,
  mode,
  open,
  onClose,
  elevations,
  imageArray,
  imageArraySelect
}) {
  const [ elevChanges, setElevChanges ] = useState([])
  const [ createImageElevation, createResult ] = useCreateImageElevationMutation()
  const [ deleteImageElevation, deleteResult ] = useDeleteImageElevationMutation()

  function handleAddElevation() {
    // TODO: Work with backend team to come up with a better endpoint that handles
    //       multiple inspection images and multiple elevations in one call
    imageArray.forEach((image, i) => {
      if (!imageArraySelect[i])
        return
      const inspectionImageId = image._id
      elevChanges.forEach(elevation => {
        console.log('createImageElevation', inspectionImageId, elevation)
        createImageElevation({ inspectionImageId, elevationId: elevation._id })
        .unwrap()
        .then(fulfilled => {
          console.log('create fulfilled', fulfilled)
          console.log('create result', createResult)
          onClose()
        })
        .catch(rejected => {
          console.log('create rejected', rejected)
        })
      })
    })
  }

  function handleRemoveElevation() {
    // TODO: Work with backend team to come up with a better endpoint that handles
    //       multiple inspection images and multiple elevations in one call
    imageArray.forEach((image, i) => {
      if (!imageArraySelect[i])
        return
      const inspectionImageId = image._id
      elevChanges.forEach(elevation => {
        console.log('deleteImageElevation', inspectionImageId, elevation)
        deleteImageElevation({ inspectionImageId, elevationId: elevation._id })
        .unwrap()
        .then(fulfilled => {
          console.log('delete fulfilled', fulfilled)
          console.log('delete result', deleteResult)
          onClose()
        })
        .catch(rejected => {
          console.log('delete rejected', rejected)
        })
      })
    })
  }

  function handleAdd(elevation, isRemove) {
    if (elevChanges.includes(elevation)) {
      setElevChanges(prevState => prevState.filter(e => e !== elevation))
    } else {
      if (isRemove) {
        setElevChanges(prevState => [...prevState, elevation])
      } else {
        setElevChanges(prevState => [elevation])
      }
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>{mode} Elevations for Selected Images</DialogTitle>
      <DialogContent>
        { elevations.map((elevation, index) => (
          <Chip key={index} label={elevation.name} className={classes.chip}
                onClick={() => handleAdd(elevation, mode === 'Remove')} clickable
                color={elevChanges.includes(elevation) ?
                  (mode === 'Add' ? 'primary' : 'secondary') : 'default'}/>
        )) }
        { elevations.length === 0 &&
          <Typography variant='body2'>No elevations available</Typography>
        }
        { elevChanges.length > 0 &&
          <Typography variant='body2'>
            { mode === 'Add' ? 'Adding' : 'Removing'} {elevChanges.length} elevations
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={mode === 'Add' ? handleAddElevation : handleRemoveElevation}>
          {mode}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ElevationDialog)
