import { Route, Switch }   from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { history }         from './utils/store'
import ProtectedRoute      from './components/ProtectedRoute'

import Home           from './components/Home'
import Logout         from './components/Home/Logout'
import LoginCallback  from './components/Home/LoginCallback'
import LogoutCallback from './components/Home/LogoutCallback'
import NotFound       from './components/shared/NotFound'

import FacDetails     from './components/Facility/Details'
import FacDirectory   from './components/Facility/Directory'
import Task           from './components/Task'
import Algorithm      from './components/Algorithm'
import Inspection     from './components/Inspection'
import InspectionList from './components/Inspection/List'
import InspectionSummary from './components/Inspection/Summary'

import Documents      from './components/Documents'
import Document       from './components/Documents/Single'
import Reports        from './components/Reports'

import Toast          from './components/shared/Toast'

import { CssBaseline }   from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import theme             from './utils/site-theme'

console.log('%c' + String.fromCodePoint(0x1F477) +
  ' Are you interested to help build UAV Facilities Management Systems? Join us - visit https://garuda.io/careers to learn more!',
  'font-size: 16px; text-shadow: #ddd 1px 1px;')

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toast />
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/callback' component={LoginCallback} />
          <ProtectedRoute exact path='/facilities' ProtectedComponent={FacDirectory} />
          <ProtectedRoute exact path='/facilities/:facilityId' ProtectedComponent={FacDetails} />
          <ProtectedRoute exact path='/tasks' ProtectedComponent={Task} />
          <ProtectedRoute exact path='/algorithms' ProtectedComponent={Algorithm} />
          <ProtectedRoute exact path='/inspections' ProtectedComponent={InspectionList} />
          <ProtectedRoute exact path='/inspection/:inspectionId' ProtectedComponent={InspectionSummary} />
          <ProtectedRoute exact path='/inspection/:inspectionId/:menu' ProtectedComponent={Inspection} />
          <ProtectedRoute exact path='/inspection/:inspectionId/:menu/:defectId' ProtectedComponent={Inspection} />
          <ProtectedRoute exact path='/documents' ProtectedComponent={Documents} />
          <ProtectedRoute exact path='/document/:documentId' ProtectedComponent={Document} />
          <ProtectedRoute exact path='/reports' ProtectedComponent={Reports} />
          <Route exact path='/logout' component={Logout} />
          <Route exact path='/logout/callback' component={LogoutCallback} />
          <Route component={NotFound} />
        </Switch>
      </ConnectedRouter>
    </ThemeProvider>
  )
}

export default App
