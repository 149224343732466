import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useMyCompanyId } from '../../api/accounts'
import {
  useCreateInspectionMutation,
  useUpdateInspectionMutation,
} from '../../api/inspectionOps'
import {
  dd_mmm_yyyyEpoch
} from '../../utils'
import {
  allInspectionStatuses,
  validInspectionStatuses,
  allInspectionTypes,
  validInspectionTypes,
} from '../../utils/enums'
import { useAllFacilities } from './hooks/facilities'
import {
  FacilityTypeIcon,
  InspectionStatusIcon,
} from './Icons'
import User from './text/User'
import TopXCloseButton from './TopXCloseButton'
import PersonnelDialog from './PersonnelDialog'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  selectField: {
    '& .MuiInputBase-input': {
      display: 'flex',
    },
  },
  selectFieldText: {
    '& span': {
      overflow: 'hidden', 
    },
    '& .MuiTypography-root': {
      textOverflow: 'ellipsis',
    }
  },
  listItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: theme.spacing(5),
  }
})

function toDateString(epoch) {
  if (!epoch) return ''
  const d = new Date(epoch)
  return `${d.getFullYear()}-${d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}`
}

const MetaDialog = ({
  classes,
  open,
  onClose,
  inspection,
  restrictFacility,
  isUpdate,
}) => {
  const company_id = useMyCompanyId()
  const { facilities } = useAllFacilities()

  // Local state for edits
  const [_inspection, setInspection] = useState(inspection || {
    name:       '',
    type:       '',
    start_date: '',
    end_date:   '',
    status:     'draft',
    facilities: [],
    personnel:  [],
    notes:      '',
    // cancel_reason: '',
    // properties: { purpose: '' }
  })
  // Fields that need special treatment
  const [ startDate, setStartDate ] = useState('')
  const [ endDate,   setEndDate   ] = useState('')

  const [ createInspection, createResult ] = useCreateInspectionMutation()
  const [ updateInspection, updateResult ] = useUpdateInspectionMutation()

  // adding validation
  const [ errorName,      setErrorName      ] = useState('')
  const [ errorType,      setErrorType      ] = useState('')
  const [ errorFacility,  setErrorFacility  ] = useState('')
  const [ errorStartDate, setErrorStartDate ] = useState('')
  const [ errorEndDate,   setErrorEndDate   ] = useState('')

  useEffect(() => {
    if (inspection) {
      // console.log('Populating inspection fields', inspection)
      setInspection(inspection)
      setStartDate(toDateString(inspection.start_date))
      setEndDate(toDateString(inspection.end_date))
    }
  }, [inspection])

  const handleInputChange = (event) => {
    const name = event.target.name
    let value = event.target.value

    switch (name) {
      case 'start_date':
        setStartDate(value)
        break
      case 'end_date':
        setEndDate(value)
        break
      case 'facilities':
        value = [value]
        break
      default:
        break
    }
    setInspection(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  function handleCreateInspection() {
    const newInspection = {
      ..._inspection,
      company_id,
      start_date: Date.parse(startDate),
      end_date: Date.parse(endDate)
    }
    console.log('handleCreateInspection', newInspection)
    const validatedInput = validateInput(newInspection)
    if (!validatedInput) {
      return 
    }
    createInspection(newInspection)
    .unwrap()
    .then(fulfilled => {
      console.log('create fulfilled', fulfilled)
      console.log('create result', createResult)
      if(fulfilled.status === 'success'){
        toast.success('Inspection created.')
        onClose()
      } else {
        toast.error('Inspection creation failed.')
      }
    })
    .catch(rejected => {
      console.log('create rejected', rejected)
      toast.error('Unable to create inspection. Try again later.')
    })
  }

  const validateInput = (input) => {
    let value = true
    if ( !input?.name || !input?.type || !input?.facilities?.length || !input?.start_date || !input?.end_date) {
      value = false
    }
    if (!input?.name || input?.name?.length === 0) {
      setErrorName('Please provide a name')
    }
    if (!input?.type ) {
      setErrorType('Please provide a type')
    }
    if (!input?.facilities?.length ) {
      setErrorFacility('Please provide a facility')
    }
    if (!input?.start_date ) {
      setErrorStartDate('Please provide a start date')
    }
    if (!input?.end_date ) {
      setErrorEndDate('Please provide an end date')
    }
    return value
  }

  function handleUpdateInspection() {
    // Collect all the fields that have changed and patch those only
    // TODO: figure out a better way to do this. This used to happen in the
    //       backend (see garudacloud/shared/couchdb/classes for example)

    const newInspection = { _id: _inspection._id }
    const stringKeys = ['name', 'type', 'notes']

    stringKeys.forEach(key => {
      if (inspection[key] !== _inspection[key])
        newInspection[key] = _inspection[key]
    })
    if (toDateString(inspection.start_date) !== startDate)
      newInspection.start_date = Date.parse(startDate)
    if (toDateString(inspection.end_date) !== endDate)
      newInspection.end_date = Date.parse(endDate)

    // arrays are more challenging - if any one of them differ, update it all
    if (inspection.facilities?.length !== _inspection.facilities?.length)
      newInspection.facilities = [ ..._inspection.facilities ]
    else
      for (let i = 0; i < inspection.facilities.length; i++) {
        if (inspection.facilities[i] !== _inspection.facilities[i]) {
          newInspection.facilities = [ ..._inspection.facilities ]
          break
        }
      }

    if (inspection.personnel?.length !== _inspection.personnel?.length)
      newInspection.personnel = [ ..._inspection.personnel ]
    else
      for (let i = 0; i < inspection.personnel.length; i++) {
        if (inspection.personnel[i].user_id !== _inspection.personnel[i].user_id) {
          newInspection.personnel = [ ..._inspection.personnel ]
          break
        }
      }

    console.log('handleUpdateInspection only fields that changed', newInspection)
    updateInspection(newInspection)
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      console.log('update result', updateResult)
      toast.success('Inspection updated.')
      onClose()
    })
    .catch(rejected => {
      console.error('udpate rejected', rejected)
      toast.error('Unable to update inspection. Try again later.')
    })
  }

  const nameTextField = (
    <TextField fullWidth
      error={!!errorFacility}
      helperText={errorName}
      label='Inspection Name'
      name='name'
      value={_inspection.name}
      variant='outlined'
      onChange={handleInputChange} />
  )

  const facilitySelectField = (
    facilities?.length > 0 ?
      <TextField select fullWidth
        helperText={errorFacility}
        error={!!errorFacility}
        label='Facility'
        name='facilities'
        value={_inspection.facilities[0] || ''}
        className={classes.selectField}
        variant='outlined'
        disabled={restrictFacility}
        onChange={handleInputChange}>
        { facilities.map(facility =>
          <MenuItem key={facility._id} value={facility._id}>
            <ListItemIcon className={classes.listItemIcon}>
              <FacilityTypeIcon type={facility?.type} />
            </ListItemIcon>
            <ListItemText
              className={classes.selectFieldText}
              primary={facility?.name}
            />
          </MenuItem>
        )}
      </TextField>
    :
      <TextField value='No Facility' variant='outlined' disabled />
  )
  const facType = _inspection.facilities[0] ?
    facilities.find(facility => facility._id === _inspection.facilities[0])?.type :
    ''
  const typeSelectField = (
    <TextField select fullWidth
      label={facType ? `Inspection Type for ${facType}` : 'Inspection Type'}
      name='type'
      value={_inspection.type}
      className={classes.selectField}
      variant='outlined'
      helperText={errorType}
      error={!!errorType}
      onChange={handleInputChange}>
      { facType ?
        validInspectionTypes[facType].map(type =>
          <MenuItem key={type} value={type}>
            <ListItemText primary={allInspectionTypes[type]?.label || 'Unknown inspection type' } />
          </MenuItem>
        )
        : <MenuItem value='-' disabled>
            <ListItemText primary='Select Facility First' />
          </MenuItem>
      }
    </TextField>
  )

  const startDateTextField = (
    <TextField fullWidth
      helperText={errorStartDate}
      error={!!errorStartDate}
      label='Start Date'
      InputLabelProps={{ shrink: true }}
      name='start_date'
      type='date'
      value={startDate}
      variant='outlined'
      onChange={handleInputChange} />
  )

  const endDateTextField = (
    <TextField fullWidth
      error={!!errorEndDate}
      helperText={errorEndDate}
      label='End Date'
      InputLabelProps={{ shrink: true }}
      name='end_date' 
      type='date'
      value={endDate}
      variant='outlined'
      onChange={handleInputChange} />
  )

  const statusSelectField = (
    <TextField select fullWidth
      label='Status'
      name='status'
      value={_inspection.status}
      className={classes.selectField}
      variant='outlined'
      onChange={handleInputChange}>
      { validInspectionStatuses.map(status =>
        <MenuItem key={status} value={status}>
          <ListItemIcon className={classes.listItemIcon}>
            <InspectionStatusIcon type={status} />
          </ListItemIcon>
          <ListItemText primary={allInspectionStatuses[status]?.label || 'Unknown inspection status' } />
        </MenuItem>
      )}
    </TextField>
  )

  const cancelReasonTextField = (
    <TextField multiline fullWidth
      label='Reason inspection was cancelled'
      value={_inspection.cancel_reason}
      variant='outlined'
      minRows={2}
      onChange={handleInputChange} />
  )

  const notesTextField = (
    <TextField multiline fullWidth
      label='Notes'
      name='notes'
      value={_inspection.notes}
      variant='outlined'
      minRows={4}
      onChange={handleInputChange} />
  )

  const facilityNames = (inspectionFacilityIds) => (
    facilities?.length > 0 ? <>
      <Typography variant='overline'>Facilities</Typography>
      { inspectionFacilityIds.map(facilityId => (
        <Typography key={facilityId} variant='body1'>
          <b>{ facilities[facilities.findIndex(facility => facility._id === facilityId)]?.name }</b>
        </Typography>
      )) }
    </> : ''
  )

  return (<>
    <Dialog open={open} onClose={onClose} scroll='paper' fullWidth maxWidth='md'>
      { !updateResult.isLoading && !createResult.isLoading && (
        <TopXCloseButton onClick={onClose} />
      )}
      <DialogTitle className={classes.title}>
        {isUpdate ? 'Edit Inspection' : 'Create New Inspection'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item md={6}>{ nameTextField }</Grid>
          <Grid item md={6}>
            { isUpdate ? facilityNames(_inspection.facilities) : facilitySelectField }
          </Grid>
          <Grid item md={6}>{ typeSelectField }</Grid>
          <Grid item md={6}>
            <Personnel
              inspection={_inspection}
              setInspection={setInspection} />
          </Grid>
          <Grid item md={6}>{ startDateTextField }</Grid>
          <Grid item md={6}>{ endDateTextField }</Grid>
          { !isUpdate &&
            <Grid item md={6}>
              { statusSelectField }
              { _inspection.status === 'cancelled' ? cancelReasonTextField : null }
            </Grid> 
          }
          <Grid item md={isUpdate ? 12 : 6}>{ notesTextField }</Grid>
        </Grid>
        <br />
        { isUpdate && <OtherDetails inspection={_inspection} /> }
      </DialogContent>
      <DialogActions >
        {/* TODO: Rewrite the disabled check with proper conditions */}
        { !updateResult.isLoading && !createResult.isLoading && <>
          <Button variant='contained' color='primary'
            disabled={!company_id}
            onClick={isUpdate ? handleUpdateInspection : handleCreateInspection } >
            { isUpdate ? 'Save' : 'Create' }
          </Button>
        </> }
        { updateResult.isLoading && <Typography>Updating... please wait</Typography> }
        { createResult.isLoading && <Typography>Creating... please wait</Typography> }
      </DialogActions>
    </Dialog>
  </>)
}

function Personnel({ inspection, setInspection }) {
  const [ open, setOpen ] = useState(false)

  const p = inspection.personnel || []
  let names = 'None'
  if (p.length > 0) {
    names = [<User key={0} id={p[0].user_id} name />]
    for (let i = 1; i < p.length; i++) {
      names.push(', ')
      names.push(<User key={i} id={p[i].user_id} name />)
    }
  }
  return (<>
    <Typography variant='overline'>Personnel</Typography>
    <Typography>{names}</Typography>
    <Link onClick={() => setOpen(true)}>View / Edit Personnel</Link>
    <PersonnelDialog
      open={open}
      onClose={() => setOpen(false)}
      personnel={inspection.personnel}
      setInspection={setInspection}  
    />
  </>)

}

function OtherDetails({ inspection }) {
  return (<>
    <Divider />
    <Grid container>
      <Grid item xs={3}>
        <Typography variant='overline'>Created By</Typography>
        <Typography variant='body1'>
          {inspection.created_by ? User({ id: inspection.created_by, name: true }) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='overline'>Created On</Typography>
        <Typography variant='body1'>
          {inspection.created_on ? dd_mmm_yyyyEpoch(inspection.created_on) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='overline'>Last Modified By</Typography>
        <Typography variant='body1'>
          {inspection.last_modified_by ? User({ id: inspection.last_modified_by, name: true }) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='overline'>Last Modified On</Typography>
        <Typography variant='body1'>
          {inspection.last_modified_on ? dd_mmm_yyyyEpoch(inspection.last_modified_on) : '-'}
        </Typography>
      </Grid>
    </Grid>
  </>)
}

export default withStyles(styles)(MetaDialog)
