import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import {
  useMyCompanyId,
  useGetCompanyByIdQuery,
  useLazyGetCompaniesQuery,
} from '../../api/accounts'

function useCompanies() {
  const company_id = useMyCompanyId()
  const [ companies, setCompanies ] = useState([]) // other people's companies (might include self)
  const [ getCompanies ] = useLazyGetCompaniesQuery()
  const { data: myCompanyData } = useGetCompanyByIdQuery(company_id, { skip: !company_id })
  const my_company = myCompanyData?.data?.company || {}
  const prefs = my_company.properties || {}
  const isEms = prefs.pref_enable_ems === true

  useEffect(() => {
    const my_company = myCompanyData?.data?.company || {}
    const company_id = my_company.company_id
    if (!company_id)
      return

    // Queries are always AND conditions. To get everything, must call one by one
    const queries = []
    if (isEms)
      queries.push({ ems_enabled: 'true' })

    Promise.all(queries.map(getCompanies))
    .then(results => {
      const newCompanies = { [company_id]: my_company }
      results.forEach((r, i) => {
        if (r.data.status === 'success') {
          r.data.data?.companies?.forEach(comp => {
            newCompanies[comp.company_id] = comp
          })
        } // else fail is normal for regular companies
      })
      // console.log('use companies', Object.values(newCompanies))
      setCompanies(Object.values(newCompanies))
    })
    .catch(error => {
      console.log('error', error)
      toast.error('Error loading companies')
    })
  }, [myCompanyData, companies.length, isEms, getCompanies])

  return {
    // About own company
    company_id,
    my_company,
    isEms,
    // timezone: my_company.properties?.pref_default_timezone || '',

    // About companies I can see
    companies,
    company_ids: companies.length > 0 ? companies.map(c => c.company_id) : undefined,
  }
}

export default useCompanies
