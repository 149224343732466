import { CallbackComponent } from 'redux-oidc'
import { connect } from 'react-redux'
import { push }    from 'connected-react-router'
import userManager from '../../utils/user-manager'
import Loading     from './Loading'

function LogoutCallback({ push }) {

  const successCallback = async () => {
    await userManager.clearStaleState()
    localStorage.clear()
    // TODO: check if localStorage.clear() is the same as PURGE
    window.location.replace(process.env.REACT_APP_PUBLIC_URL)
  }

  const errorCallback = async (error) => {
    push('/facilities')
  }

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}>
      <Loading message='Logout Success. Redirecting ...' />
    </CallbackComponent>
  )
}

export default connect(null, { push })(LogoutCallback)
