import { useState } from 'react'
import { hasValidSub } from '../../../../api/accounts'
import { useFacilitiesSubscription } from '../../../../utils/hooks'
import SubscriptionDialog from '../../../shared/SubscriptionDialog'
import UploadLabelsDialog from './UploadLabelsDialog'
import {
  Button,
} from '@material-ui/core'

function UploadLabelsButton({ inspectionId, facilityType }) {
  const [ open, setOpen ] = useState(false)
  const subscribed = hasValidSub(useFacilitiesSubscription())

  return (<>
    <Button variant='outlined' onClick={() => setOpen(true)}>
      Upload Offline Annotations
    </Button>
    { open && !subscribed &&
      <SubscriptionDialog onClose={() => setOpen(false)} />
    }
    { open && subscribed &&
      <UploadLabelsDialog
        open={open}
        onClose={() => setOpen(false)}
        inspectionId={inspectionId}
        facilityType={facilityType}
      />
    }
  </>)
}

export default UploadLabelsButton

  // subtitle: 'Annotate defects offline (launches new app)',
  // notes: 'Requires the original images on your local computer. The filenames must match what you uploaded. Use this option if you are annotating this using the same workstation for managing your UAV.',
