import { useState } from 'react'
import { hasValidSub } from '../../../api/accounts'
import {
  useCreateImageMutation,
  useGetElevationsQuery
} from '../../../api/inspectionOps'
import { history } from '../../../utils/store'
import { useFacilitiesSubscription } from '../../../utils/hooks'


import LoadingLightbox    from '../../shared/LoadingLightbox'
import SubscriptionDialog from '../../shared/SubscriptionDialog'
import FileDropArea       from './components/FileDropArea'
import TagElevations      from './components/TagElevations'
import AdvancedOptions    from './components/AdvancedOptions'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from '@material-ui/core'
import {
  ExpandMore,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.lg,
    margin:   '0 auto',
    padding:  theme.spacing(2),
  },
  floatRight: {
    marginTop: theme.spacing(2),
    float: 'right',
  },
})

function UploadImage({ classes, facilityId, inspectionId, facilityType }) {
  const subscribed = hasValidSub(useFacilitiesSubscription())
  const [ openSub, setOpenSub ] = useState(false)

  const [ createImage ] = useCreateImageMutation()

  // Upload State
  const [ files, setFiles ] = useState([])
  const [ error, setError ] = useState(false)
  const [ enabled, setEnabled ] = useState(false)
  const [ uploading, setUploading ] = useState('') // filename, cannot be empty
  const [ failedUploads, setFailedUploads ] = useState([])

  // Elevation State
  const [ tags, setTags ] = useState([])
  const [ privacyMask, setPrivacyMask ] = useState(false)

  const {data: elevationData, error: elevationError} = useGetElevationsQuery({facility_id: facilityId}, {skip: !facilityId})
  if (elevationError) {
    console.error(elevationError)
  }

  // Empty media_id can occur due to failed image upload while creating elevation
  // TODO: Improve create elevation with upload image success check
  const elevations = elevationData?.data?.elevations?.filter(
    elevation => elevation.images?.[0]?.media_id
  ) || []

  const handleSubmit = async () => {
    if (!subscribed) {
      setOpenSub(true)
      return
    }
    setEnabled(false)
    setError(false)
    const totalFiles = files.length
    const image_post_response = await handleUploadMedia()

    if (image_post_response && image_post_response.length === totalFiles){
      history.push(`/inspection/${inspectionId}/gallery`)
    }

    setEnabled(true)
  }

  const handleUploadMedia = async () => {
    console.log("tags: ", tags)
    const image_post_response = []
    const newFailedUploads = []
    const successfulUploads = []
    for (let i = 0; i < files.length; i++) {
      setUploading(files[i].name)
      const fd = new FormData()
      fd.append('facility_elevations', JSON.stringify(tags))
      fd.append('file', files[i])
      fd.append('inspection_id', inspectionId)
      fd.append('privacy_mask', privacyMask)

      const res = await createImage(fd)

      if (res?.data?.status === 'success') {
        image_post_response.push(res.data.data.image)
        setError(false)
        successfulUploads.push(files[i])
      } else {
        console.log('Failed uploading media', res)
        newFailedUploads.push(files[i])
      }
    }
    if (newFailedUploads.length > 0) {
      console.log('here', newFailedUploads)
      setFailedUploads(newFailedUploads)
      setFiles(newFailedUploads)
      setError(`${successfulUploads.length} image(s) uploaded successfully. Failed to upload ${newFailedUploads.length} image(s). Please try again`)
      console.log('Failed to upload image(s).', failedUploads)
    } else {
      setTags([])
      setFiles([])
      setFailedUploads([])
    }
    console.log('image_post_response', image_post_response)
    
    return image_post_response
  }

  function updatedUploadButton () {
    let filesAdded = false
    if (files.length > 0){
      filesAdded = true
    }
    let elevationAdded = false
    if (tags.length > 0) {
      elevationAdded = true
    }
    setEnabled(filesAdded && elevationAdded)
  }

  return (
    <div className={classes.root}>
      <Typography variant='h4'>Upload Images</Typography>
      <br />
      <Typography>
        (1) Drag and drop images from your last flight below, or click + to select files
      </Typography>
      <FileDropArea
        files={files}
        setFiles={setFiles}
        onChange={updatedUploadButton}
      />
      <Typography variant='body1'>
        (2) Tag all the images above to the following elevation(s).
      </Typography>
      <TagElevations
        elevations={elevations}
        tags={tags}
        setTags={setTags}
        facilityId={facilityId}
        onChange={updatedUploadButton}
      />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}>
          <Typography variant='body1'>
            Advanced options
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AdvancedOptions
            privacyMask={privacyMask}
            setPrivacyMask={setPrivacyMask}
          />
        </AccordionDetails>
      </Accordion>
      <Button
        disabled={!enabled}
        variant='contained'
        color='primary'
        className={classes.floatRight}
        onClick={handleSubmit}>
        Upload
      </Button>
      <LoadingLightbox
        open={!!uploading}
        showProgress={true}
        progress={files.map(f => f.name).indexOf(uploading)/files.length}
        message={`Uploading ${uploading}. Please wait.`}
        onClose={() => { if (error) { setUploading('') } }}
        error={error}
      />
      { openSub && <SubscriptionDialog onClose={() => setOpenSub(false)} /> }
    </div>
  )
}

export default withStyles(styles)(UploadImage)
