import { useState } from 'react'
import { hasValidSub } from '../../../../api/accounts'
import {
  useFacilitiesAppRoles,
  useFacilitiesSubscription,
} from '../../../../utils/hooks'
import SubscriptionDialog from '../../../shared/SubscriptionDialog'
import {
  Button,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  grid: {
    padding: '2px'
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
  },
  mainButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
  },
})

function DefaultControls({ classes, inspections, onNew, onSelect }) {

  const [ open, setOpen ] = useState(false)
  const subscribed = hasValidSub(useFacilitiesSubscription())

  const userAppRoles = useFacilitiesAppRoles()

  const handleNew = () => (
    subscribed ? onNew() : setOpen(true)
  )

  return (<>
    <Grid container direction='row' justifyContent='flex-end'>
      <Grid item className={classes.grid}>
        { inspections?.length > 0 &&
          <Button
            className={classes.button}
            variant='contained'
            onClick={onSelect}>
            Select
          </Button>
        }
      </Grid>
      <Grid item className={classes.grid}>
        { userAppRoles.includes('fm-manager') ?
          <Button
            className={classes.mainButton}
            variant='contained'
            onClick={handleNew}>
            New Inspection
          </Button>
        : <Button
            disabled
            variant='contained'>
            New Inspection
          </Button>
        }
      </Grid>
    </Grid>
    { open && <SubscriptionDialog onClose={() => setOpen(false)} /> }
  </>)
}

export default withStyles(styles)(DefaultControls)
