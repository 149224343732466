import { useState } from 'react'
import { useCreateReportMutation, useGetDefectsQuery } from '../../../../api/inspectionOps'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'

function GenerateReportButton({ inspectionIdArray, onSuccess, onError }) {
  const [ infoDialogOpen, setInfoDialogOpen ] = useState(false)
  const [ downloading, setDownloading ] = useState(false)
  const [ createReport, createResult ] = useCreateReportMutation()

  // Check if labels are correct
  const {
    data: defectsData
  } = useGetDefectsQuery({ inspection_ids: inspectionIdArray }, {
    skip: !inspectionIdArray || inspectionIdArray?.length === 0
  })

  function handleDownload() {
    
    setDownloading(true)
    createReport({ inspection_ids: inspectionIdArray })
    .unwrap()
    .then(fulfilled => {
      console.log('create report fulfilled', fulfilled)
      console.log('create report result', createResult)
      if (fulfilled.status === 'success') {
        onSuccess()
      } else {
        onError('Error')
      }
    })
    .catch(rejected => {
      console.log('create report rejected', rejected)
      onError('Please try again')
    })
    .finally(() => { setDownloading(false) })
  }

  return (
    <>
      <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)}>
        <DialogContent>
          Please note that {defectsData?.length} defect(s) without geolocation will be excluded from the report
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={handleDownload}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        disabled={downloading}
        variant='contained'
        size='small'
        color='secondary'
        onClick={() => setInfoDialogOpen(true)}>
        { downloading ? <CircularProgress size={22} /> : 'Generate Report' }
      </Button>
    </>
  )
}

export default GenerateReportButton
