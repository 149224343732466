import { useState } from 'react'
import { getSubscriptionUrl } from '../../../api/accounts'
import { useFacilitiesSubscription } from '../../../utils/hooks'

import NewSubsDialog from './NewSubsDialog'
import {
  Button,
} from '@material-ui/core'

function NewSubsButton({ className }) {
  const [ open, setOpen ] = useState(false)
  const subs = useFacilitiesSubscription()

  function redirect() {
    window.open(getSubscriptionUrl(), '_blank')
  }

  // Only brand new customers qualify for free trial
  const qualifyForFreeTrial = !subs || subs.length === 0

  return qualifyForFreeTrial ? <>
    <Button
      variant='contained'
      color='secondary'
      className={className}
      onClick={() => { setOpen(true) }}>
      Free Trial
    </Button>
    { open &&
      <NewSubsDialog onClose={() => { setOpen(false) }} />
    }
  </> : <>
    <Button
      style={{ whiteSpace: 'nowrap' }}
      variant='contained'
      color='secondary'
      className={className}
      onClick={redirect}>
      Renew Subscription
    </Button>
  </>
}

export default NewSubsButton
