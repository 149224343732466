import { useMemo, useRef, useState } from 'react'

import {
  useGetDefectCategoriesByFacilityTypeQuery,
  useGetImagesQuery,
  useCreateLabelWithDefectMutation
} from '../../../../api/inspectionOps'

import { convertCOCOtoYOLOPolygon } from '../../../../utils/coco-to-yolo-polygon'
import LoadingLightbox from '../../../shared/LoadingLightbox'
import LabelledImage from '../../../shared/LabelledImage'
import TopXCloseButton from '../../../shared/TopXCloseButton'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import {
  CloudUpload,
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  dragArea: {
    margin:       theme.spacing(2, 0),
    minHeight:    theme.spacing(12),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(1),
    border:       '1px dashed black',
    overflow:     'hidden',
  },
  table: {
    margin:       theme.spacing(2, 0),
    minHeight:    theme.spacing(12),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(1),
    border:       '1px solid black',
  },
  title: {
    padding:      theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  cloudupload: {
    color:        theme.palette.primary.light,
    width:        theme.spacing(8),
    height:       theme.spacing(8),
  },
  instructions: {
    margin:       theme.spacing(1, 0),
    padding:      theme.spacing(2, 0),
    width:        '100%',
    textAlign:    'center',
  },
  error: {
    margin:       theme.spacing(1, 0),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(2),
    fontWeight:   'bold',
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
})

function UploadLabelsDialog({ classes, onClose, inspectionId, facilityType }) {

  const [ errMsg,  setErrMsg  ] = useState('')
  const [ files,   setFiles   ] = useState([])
  const [ defects, setDefects ] = useState([]) // [{ file, raw, json }], raw & json each an array of rows.
  // const [ results, setResults ] = useState([])
  const [fileType, setFileType] = useState('csv')

  const { data, error, isLoading } = useGetImagesQuery({
    inspection_id: inspectionId,
  })
  if (!isLoading) {
    if (error) {
      console.error('Error loading images', error)
      setErrMsg('Error loading images')
    } else if (!data.status === 'success') {
      console.warn('Failed loading images', data)
      setErrMsg('Failed loading images')
    }
  }
  const imageArray = useMemo(() => (data?.data?.images || []), [data])
  // console.log('imageArray', imageArray, 'data', data, 'error', error, 'isLoading', isLoading)

  const [ createLabelWithDefect, {
    isLoading: isCreating,
    isError:   isErrorCreate,
    error:     errorCreate,
  } ] = useCreateLabelWithDefectMutation()

  const { data: defectCategoryData } = useGetDefectCategoriesByFacilityTypeQuery(facilityType, {
    skip: !facilityType
  })
  const defectCategories = defectCategoryData?.data?.categories || {}

  function handleSubmit() {
    for (let defect of defects) {
      // console.log('Creating defects from', defect.file.name)

      for (let i = 0; i < defect.json.length; i++) {

        const json = defect.json[i]

        if (!json.shape) {
          console.log('createLabelWithDefect: not uploading invalid defect', json)
          continue // some invalid inputs from CSV (e.g. file not there)
        }

        // TODO: uncomment if the defect types in annotation tool and in inspection-service are the same
        // json.type = defect.raw[i][0]

        createLabelWithDefect(json)
        .unwrap()
        .then(fulfilled => {
          if (fulfilled.status !== 'success') {
            console.log('create failed', fulfilled)
            setErrMsg('At least one create label / defect failed')
          } else {
            // console.log('create success', fulfilled)
            // console.log('create results: isCreating', isCreating, 'isErrorCreate', isErrorCreate)

            // TODO: Put the results in results
            onClose()
          }
        })
        .catch(rejected => {
          console.error('create rejected', rejected)
          console.log('create results: isCreating', isCreating, 'isErrorCreate', isErrorCreate, 'errorCreate', errorCreate)
          setErrMsg('At least one create label / defect error')
        })
      }
    }
  }

  function handleVerify() {
    if (fileType === 'csv') {
      for (let file of files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const contents = e.target.result
          const rows = contents.split('\n')
          const raw = rows.map(row => row.split(',').map(elem => elem.trim()))
          const json = raw.map(row => {
            const inspectionImage = imageArray.find(img => img.filename === row[5])
            if (!inspectionImage) {
              return {}
            } else {
              let surface = 'blank'
              let type = 'blank'
              let severity = 'blank'

              const lowercaseRow = row[0].toLowerCase()

              for (const category in defectCategories) {
                const values = defectCategories[category]
                for (const value of values) {
                  if (lowercaseRow.includes(value)) {
                    if (category === 'surface') {
                      surface = value
                    } else if (category === 'type') {
                      type = value
                    } else if (category === 'severity') {
                      severity = value
                    }
                  }
                }
              }
              return {
                inspection_image_id: inspectionImage._id,

                // This turned out to be wrong (originally suspected that it was based on YOLO)
                // x: Math.round(row[1] - (row[3] / 2)),
                // y: Math.round(row[2] - (row[4] / 2)),

                // x and y in the CSV file already refers to top left corner, and as such it
                // matches the internal definition for Facilities 4.0, that's based on HTML5
                // Canvas definition https://www.w3schools.com/tags/canvas_rect.asp
                x: parseInt(row[1]),
                y: parseInt(row[2]),
                w: parseInt(row[3]),
                h: parseInt(row[4]),
                shape: 'rect',
                inspection_id: inspectionId,
                surface:     surface,
                type:        type,
                severity:    severity,
                description: row[0] //Add the whole label to description field
              }
            }
          })
          setDefects(prevDefects => [...prevDefects, { file, raw, json }])
        }
        reader.readAsText(file)
      }
    } else if (fileType === 'coco') {
      for (let file of files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const contents = e.target.result
          const yoloData = convertCOCOtoYOLOPolygon(JSON.parse(contents))
          if (yoloData !== null) {
            const json = yoloData.map(data => {
              const inspectionImage = imageArray.find(img => img.filename === data.filename)
              if (!inspectionImage) {
                return {}
              } else {
                const originalCoordinates = data.polygon
                let convertedCoordinates = []
                if (originalCoordinates.length % 2 === 0) {
                  for (let i = 0; i < originalCoordinates.length; i += 2) {
                    const pair = [originalCoordinates[i], originalCoordinates[i + 1]]
                    convertedCoordinates.push(pair)
                  }
                } else {
                  console.log('Error: Odd coordinates')
                }
                return {
                  inspection_image_id: inspectionImage._id,
                  shape: 'yolo-poly',
                  polygon: convertedCoordinates,
                  inspection_id: inspectionId,
                }
              }
            })
            setDefects(prevDefects => [...prevDefects, { file, yoloData, json }])
          } else {
            alert('Wrong polygon format')
          }
        }
        reader.readAsText(file)
      }
    }
  }

  function handleAddFile({ selectedFiles, fileType }) {
    const curFiles = []
    for (let file of selectedFiles) {
      if (fileType === 'csv'){
        if (file.type !== 'text/csv')
          alert(file.name + ' is not a CSV file')
        else
          curFiles.push(file) // Construct own Array from FileList
      } else if (fileType === 'coco') {
        if (file.type !== 'application/json')
          alert(file.name + ' is not a JSON file')
        else
          curFiles.push(file) // Construct own Array from FileList
      }
    }
    setFiles(prevState => ([...prevState, ...curFiles]))
  }

  return (
    <Dialog open onClose={onClose}
      fullWidth maxWidth='lg'>
      <TopXCloseButton onClick={onClose} />
      <DialogContent>
        <Typography variant='h5'>Upload Labels</Typography>
        <HTML5warning />
        { defects.length === 0 && <FileTypeSelect setSelectedOption={setFileType} selectedOption={fileType}/>}
        { defects.length === 0 &&
          <UploadFiles files={files} fileType={fileType} handleAddFile={handleAddFile} />
        }
        { defects.length > 0 && (
          isLoading ? <CircularProgress />
          : <DefectsTable defects={defects} imageArray={imageArray} fileType={fileType} />
        )}
      </DialogContent>
      <DialogActions>
        { defects.length === 0 &&
          <Button variant='contained' color='primary'
            disabled={files.length === 0}
            onClick={handleVerify}>
            Verify
          </Button>
        }
        { defects.length > 0 &&
          <Button variant='contained' color='primary'
            onClick={handleSubmit}>
            Submit
          </Button>
        }
      </DialogActions>
      <LoadingLightbox
        open={!!errMsg}
        onClose={() => setErrMsg('')}
        error={errMsg}
      />
    </Dialog>
  )
}

function FileTypeSelect ({ selectedOption, setSelectedOption }) {

  const handleOptionChange = (event) => {
    const value = event.target.value
    setSelectedOption(value)
  }

  return (
    <FormControl>
      <InputLabel>Select File Type</InputLabel>
      <Select value={selectedOption} onChange={handleOptionChange}>
        <MenuItem value='csv'>CSV (Rectangles)</MenuItem>
        <MenuItem value='coco'>COCO (Polygons)</MenuItem>
      </Select>
    </FormControl>
  )
}

function DefectsTable({ defects, imageArray, fileType }) {
  const classes = makeStyles(styles)()

  if (fileType === 'csv') {
    return <>
      {defects.map(defect => <div key={defect.file.name} className={classes.table}>
        <Typography className={classes.title}>Defects in <b>{defect.file.name}</b></Typography>
        <table border='1' bordercolor='lightgrey'
               style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
          <tr>
            <td><b>Defect Preview</b></td>
            <td colSpan={8}><b>Raw data</b></td>
            <td><b>Transformed data</b> (to be inserted into db)</td>
          </tr>
          <tr>
            <td>Resized to preview (260px width)</td>
            <td>type</td>
            <td>topleftX</td>
            <td>topleftY</td>
            <td>labelW</td>
            <td>labelH</td>
            <td>filename</td>
            <td>imgW</td>
            <td>imgH</td>
            <td>Inspection Image Label</td>
          </tr>
          </thead>
          <tbody>
          {defect.raw.map((row, i) => {
            const json = defect.json[i]
            if (row.length < 8)
              return null
            const inspectionImage = imageArray.find(img => img.filename === row[5])
            if (inspectionImage)
              return <tr key={i}>
                <td>
                  <LabelledImage
                    media_id={inspectionImage.media_id}
                    size='preview'
                    labels={[{
                      x: json.x,
                      y: json.y,
                      w: json.w,
                      h: json.h,
                      imgW: parseInt(row[6]),
                      imgH: parseInt(row[7]),
                    }]}
                    scaleWidth={260}
                  />
                </td>
                {row.map((col, j) => <td key={j}>{col}</td>)}
                <td>
                  <small><pre>
                    {JSON.stringify(json, null, 2)}
                  </pre>
                  </small>
                </td>
              </tr>
            else
              return <tr key={i}>
                <td>Image "{row[5]}" not found in this inspection.</td>
                {row.map((col, j) => <td key={j}>{col}</td>)}
                <td>&nbsp</td>
              </tr>
          })}
          </tbody>
        </table>
      </div>)}
    </>
  } else if (fileType === 'coco') {
    return <>
    {defects.map(defect => <div key={defect.file.name} className={classes.table}>
      <Typography className={classes.title}>Defects in <b>{defect.file.name}</b></Typography>
      <table border='1' bordercolor='lightgrey'
             style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
        <tr>
          <td><b>Defect Preview</b></td>
          <td colSpan={5}><b>Raw data</b></td>
          <td><b>Transformed data</b> (to be inserted into db)</td>
        </tr>
        <tr>
          <td>Resized to preview (260px width)</td>
          <td>type</td>
          <td>polygon</td>
          <td>filename</td>
          <td>imgW</td>
          <td>imgH</td>
          <td>Inspection Image Label</td>
        </tr>
        </thead>
        <tbody>
        {defect.yoloData.map((data, i) => {
          const json = defect.json[i]
          const inspectionImage = imageArray.find(img => img.filename === data.filename)
          if (inspectionImage)
            return <tr key={i}>
              <td>
                <LabelledImage
                  media_id={inspectionImage.media_id}
                  size='preview'
                  labels={[{
                    shape: json.shape,
                    polygon: json.polygon,
                    imgW: data.width,
                    imgH: data.height
                  }]}
                  scaleWidth={260}
                />
              </td>
              <td>{data.type}</td>
              <td><small><pre>
                    {JSON.stringify(data.polygon, null, 2)}
                  </pre>
              </small></td>
              <td>{data.filename}</td>
              <td>{data.width}</td>
              <td>{data.height}</td>
              <td>
                <small><pre>
                    {JSON.stringify(json, null, 2)}
                  </pre>
                </small>
              </td>
            </tr>
          else
            return <tr key={i}>
              <td>Image "{data.filename}" not found in this inspection.</td>
              <td>{data.type}</td>
              <td><small><pre>
                    {JSON.stringify(data.polygon, null, 2)}
                  </pre>
              </small></td>
              <td>{data.filename}</td>
              <td>{data.width}</td>
              <td>{data.height}</td>
              <td>
                <small><pre>
                    {JSON.stringify(json, null, 2)}
                  </pre>
                </small>
              </td>
            </tr>
        })}
        </tbody>
      </table>
    </div>)}
    </>
  }
}

function UploadFiles({ files, fileType, handleAddFile }) {
  const classes = makeStyles(styles)()

  const inputFileRef = useRef(null)

  function handleFilesDrop(e) {
    e.preventDefault()
    const selectedFiles = e.dataTransfer.files
    handleAddFile({selectedFiles, fileType})
  }
  function handleDragOver(e) {
    e.preventDefault()
  }
  function handleFileChosen(e) {
    const selectedFiles = e.target.files
    handleAddFile({selectedFiles, fileType})
    e.target.value = ''
  }

  return (
    <div className={classes.dragArea}
      onDrop={e => handleFilesDrop(e)}
      onDragOver={e => handleDragOver(e)}>
      <input type='file' onChange={handleFileChosen} multiple ref={inputFileRef} />
      { files.length > 0 ?
        <ol>
          { files.map((file, i) => (
            <li key={i}><Typography>{file.name}</Typography></li>
          )) }
        </ol>
        : <Instructions fileType={fileType} />
      }
    </div>
  )
}

// TODO: Support the YOLO format for upload for both rect and polygon

function Instructions({ fileType }) {
  const classes = makeStyles(styles)()
  if (fileType === 'csv') {
    return (<>
      <Typography variant='body2' className={classes.instructions}>
        <CloudUpload className={classes.cloudupload}/><br/>
        Drag the CSV file (not YOLO format) here. <br/>
        Double check that it's from the same inspection.
      </Typography>
    </>)
  } else if (fileType === 'coco') {
    return (<>
      <Typography variant='body2' className={classes.instructions}>
        <CloudUpload className={classes.cloudupload}/><br/>
        Drag the COCO file here. <br/>
        Double check that it's from the same inspection.
      </Typography>
    </>)
  }
}

function HTML5warning() {
  const classes = makeStyles(styles)()
  if (!window.FileReader) // only need FileReader, no need Worker
   return (
      <Typography variant='body1' className={classes.error}>
        Your browser does not have HTML 5. Please use a modern browser.
      </Typography>
    )
  else
    return null
}


export default withStyles(styles)(UploadLabelsDialog)
