import { useState } from 'react'
import { Link } from 'react-router-dom'
import { history } from '../../utils/store'

import SideDrawer from './SideDrawer'
import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ChevronRight,
  Menu,
} from '@material-ui/icons'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
  menuGrid: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  menuBtn: {
    width:       theme.spacing(8),
    height:      theme.spacing(8),
  },
  title: {
    lineHeight:   '2',
    whiteSpace:   'nowrap',
    overflow:     'hidden',
    textOverflow: 'ellipsis',
  },
  logo: {
    height:        theme.spacing(7),
  },
  appbar: {
    width: '100vw',
  },
  toolbar: {
    height:        theme.spacing(8),
    display:       'flex',
    flexDirection: 'row',
    alignItems:    'center',
  },
  overline: {
    lineHeight: '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: ({ subtitleLink }) => ({
    height:         '100%',
    display:        'flex',
    flexDirection:  'column',
    justifyContent: 'center',
    alignItems:     'end',
    cursor:         subtitleLink ? 'pointer' : 'auto',
    padding:        theme.spacing(1),

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'fill-available',

    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '0.4rem',
    // }
  }),
  subtitleText: {
    lineHeight: '1',
  },
  navContainer: {
    width: 'fill-available',
    overflow: 'hidden',
  },
})

function NavBar({ classes, title, breadcrumb, inspectionId, midSubtitle, midSubtitleOverline, subtitle, subtitleOverline, subtitleLink }) {

  const [ open,       setOpen       ] = useState(false)
  const [ activeMenu, setActiveMenu ] = useState('')

  function handleMenuClick(newItem) {
    setActiveMenu(newItem)
    setOpen(false)
  }
  function handleSubtitleClick() {
    if (subtitleLink)
      history.push(subtitleLink)
  }

  return (
    <AppBar className={classes.appbar} position='static'>
      <Toolbar className={classes.toolbar}>
        <div className={classes.menuGrid}>
          <IconButton edge='start' color='inherit' className={classes.menuBtn}
            onClick={() => setOpen(true)}>
            <Menu />
          </IconButton>
        </div>
        <Grid container className={classes.navContainer}>
          <Box component={Grid} item sm={6} display={{ xs: 'none', sm: 'block' }}>
            { title ?
              <Typography variant='h4' className={classes.title}>{title}</Typography>
            : breadcrumb ? <>
              <Box display={{ xs: 'none', sm: 'block', md: 'none' }}>
                <img src='/facilities4.0_logo_white.png' alt='logo' className={classes.logo} />
              </Box>
              <Box display={{ xs: 'none', md: 'block' }}>
                <InspectionNav breadcrumb={breadcrumb} inspectionId={inspectionId} />
              </Box>
            </> :
              <img src='/facilities4.0_logo_white.png' alt='logo' className={classes.logo} />
            }
          </Box>
          <Grid item xs={12} sm={6}>
            { subtitle && subtitleOverline &&
              <div className={classes.subtitle} onClick={handleSubtitleClick}>
                <Typography variant='overline' className={classes.overline}>
                  { subtitleOverline }
                </Typography>
                <Typography variant='h6' className={classes.subtitleText}>
                  { subtitle }
                </Typography>
              </div>
            }
          </Grid>
        </Grid>
      </Toolbar>
      { breadcrumb &&
        <Box display={{ xs: 'block', md: 'none' }} mb={1}>
          <InspectionNav narrow
            breadcrumb={breadcrumb} inspectionId={inspectionId} />
        </Box>
      }
      <SideDrawer
        open={open}
        activeMenu={activeMenu}
        onClick={handleMenuClick}
        onClose={() => setOpen(false)}
      />
    </AppBar>
  )
}

const inspectionStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  active: {
    whiteSpace: 'nowrap',
    padding: ({ narrow }) => theme.spacing(1, narrow ? 0 : 3),
    borderRadius: theme.spacing(4),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  button: {
    whiteSpace: 'nowrap',
    padding: ({ narrow }) => theme.spacing(1, narrow ? 0 : 3),
    borderRadius: theme.spacing(4),
    color: theme.palette.grey[200],
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
})

function InspectionNav({ breadcrumb, inspectionId, narrow }) {
  const classes = makeStyles(inspectionStyles)()
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Button
          variant='text'
          className={breadcrumb === 'images' ? classes.active : classes.button}
          component={Link}
          to={`/inspection/${inspectionId}/gallery`}>
          Images
        </Button>
      </Grid>
      { !narrow &&
        <Grid item><IconButton><ChevronRight /></IconButton></Grid>
      }
      <Grid item>
        <Button
          variant='text'
          className={breadcrumb === 'defects' || breadcrumb === 'detections' ? classes.active : classes.button}
          component={Link}
          to={`/inspection/${inspectionId}/detections`}>
          Detections
        </Button>
      </Grid>
      { !narrow &&
        <Grid item><IconButton><ChevronRight /></IconButton></Grid>
      }
      <Grid item>
        <Button
          variant='text'
          className={breadcrumb === 'reports' ? classes.active : classes.button}
          component={Link}
          to={`/reports?inspection=${inspectionId}`}>
          Reports
        </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(NavBar)
