import {
  Typography,
} from '@material-ui/core'
import {
  PhotoLibrary,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(0, 2, 8, 4),
  },
  subtitle: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})

function EmptyGallery({ classes, hideUploadMessage }) {
  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>
        <PhotoLibrary className={classes.icon} />
        <Typography variant='h6'>
          No Images Found
        </Typography>
      </div>
      { !hideUploadMessage && (<>
        <Typography variant='h3' gutterBottom color='primary'>
        Let's Get Started
        </Typography>
        <Typography variant='h5' gutterBottom>Create an Image Gallery</Typography>
        <Typography variant='body1' gutterBottom>
          An <b>Image Gallery</b> is where you would find all the images taken 
          from your Cerana drone. You can also upload images from your other drones.
        </Typography>
        <Typography variant='body1' gutterBottom>
          To start, make sure you are on the right inspection, and click UPLOAD
          on the top right.
        </Typography>
        <br />
        <br />
        <Typography variant='h5' gutterBottom>Privacy Masking</Typography>
        <Typography variant='body1' gutterBottom>
          All images uploaded to the image gallery will have personally identifiable
          information masked away. For more information, see our Terms of Use.
        </Typography>
      </>)}
    </div>
  )
}

export default withStyles(styles)(EmptyGallery)
