import { useState, useEffect }       from 'react'
import { isValidSub, isUpcomingSub, getSubscriptionUrl } from '../../api/accounts'
import { useFacilitiesSubscription } from '../../utils/hooks'
import { dd_mmm_yyyyEpoch }          from '../../utils'
import TopXCloseButton from './TopXCloseButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
  actions: {
    margin: 0,
    padding: theme.spacing(1),
  }
})

function SubscriptionDialog({ classes, onClose }) {
  const [ msg, setMsg ] = useState(0)
  const subs = useFacilitiesSubscription()

  useEffect(() => {
    console.log('subs', subs)
    if (!subs || subs.length === 0)
      setMsg(1)
    else if (subs.some(isValidSub))
      setMsg(0)
    else if (subs.some(isUpcomingSub))
      setMsg(3)
    else
      setMsg(2)
  }, [subs])

  let det = {
    end_date:   '',
    start_date: '',
  }
  if (subs) {
    const end   = subs.length ? subs[0]?.end_date   : 0
    const start = subs.length ? subs[0]?.start_date : 0
    if (end > 0)
      det.end_date = dd_mmm_yyyyEpoch(end)
    if (start > 0)
      det.start_date = dd_mmm_yyyyEpoch(start)
  }

  const link = getSubscriptionUrl()
  switch (msg) {
    case 1:
      det = {
        title: 'Error',
        description: 'Error fetching subscription details. Please try again later',
      }
      break
    case 2:
      det = {
        title: 'Subscription Expired',
        description: `Your Facilities 4.0 subscription has expired on ${det.end_date}`,
        buttonText: 'Renew Subscription',
        buttonBehavior: { action: 'redirect', link },
      }
      break
    case 3:
      det = {
        title: 'Upcoming Subscription',
        description: `Your subscription has not started yet. This feature will be available on ${det.start_date}`,
        buttonText: 'Go to Accounts',
        buttonBehavior: { action: 'redirect', link },
      }
      break
    default:
      det = {
        title: 'Subscribe',
        description: 'Please subscribe or renew subscription to use this feature',
        buttonText: 'Activate Subscription',
        buttonBehavior: { action: 'redirect', link },
      }
  }

  const handleButtonClick = (behavior) => {
    switch (behavior.action) {
      case 'refresh':
        window.location.reload()
        break
      case 'redirect':
        window.open(behavior.link, '_blank')
        break
      default:
        window.location.reload()
    }
  }
  function handleClose() {
    setMsg(0)
    onClose && onClose()
  }
  return (
    <Dialog open={msg > 0} onClose={handleClose}>
      <TopXCloseButton onClick={handleClose} />
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant='h6'>{det.title}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <Typography gutterBottom>
          {det.description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color='primary'
          onClick={() => handleButtonClick(det.buttonBehavior)}>
          {det.buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(SubscriptionDialog)
