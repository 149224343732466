import { useEffect, useState } from 'react'
import { useGetDefectCategoriesByFacilityTypeQuery } from '../../../../api/inspectionOps'

import { pretty } from '../../../../utils'
import TopXCloseButton from '../../../../components/shared/TopXCloseButton'

import {
  Box,
  Chip,
  Divider,
  Drawer,
  Typography,
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
  drawer: {
    width:   '350px',
    padding: theme.spacing(2),
  },
})

function FilterDrawer({
  classes,
  open,
  onClose,
  onFilter,
  elevations,
  facilityType,
}) {
  const { data: defectCategoryData } = useGetDefectCategoriesByFacilityTypeQuery(facilityType, {
    skip: !facilityType
  })
  const defectCategories = defectCategoryData?.data?.categories || {}

  const [ filters, setFilters ] = useState({
    generic:   [],
    status:    [],
    facility_elevations: [],
    surface:   [],
    type:      [],
    severity:  [],
  })

  function handleClick(filterType, filterId) {
    if (filters[filterType].includes(filterId)) {
      setFilters(prevFilter => ({
        ...filters,
        [filterType]: prevFilter[filterType].filter(fi => fi !== filterId)
      }))
    } else {
      setFilters(prevFilter => ({
        ...filters,
        [filterType]: [...prevFilter[filterType], filterId]
      }))
    }
  }

  useEffect(() => {
    onFilter && onFilter(filters)
  }, [ filters, onFilter ])

  return (
    <Drawer
      open={open}
      anchor='left'
      onClose={onClose}>
      <div className={classes.drawer}>
        <TopXCloseButton onClick={onClose} />
        <Typography variant='h6'>Filters</Typography>
        <Section>
          <Title>Workflow</Title>
          <Subtitle>Data Collection</Subtitle>
          <Toggles
            enums={['Position Missing']}
            label={() => 'Position Missing'}
            current={filters.generic}
            onClick={id => { handleClick('generic', id) }}
          />
          <Subtitle>Rectification Status</Subtitle>
          <Toggles
            enums={defectCategories.status}
            label={status => pretty(status)}
            current={filters.status}
            onClick={id => { handleClick('status', id) }}
          />
        </Section>
        <Section>
          <Title>Facility</Title>
          <Subtitle>Elevation</Subtitle>
          <Toggles
            enums={elevations}
            label={elevation => elevation.name}
            current={filters.facility_elevations}
            onClick={id => { handleClick('facility_elevations', id) }}
          />
        </Section>
        <Section>
          <Title>Defect Classification</Title>
          <Subtitle>Severity</Subtitle>
          <Toggles
            enums={defectCategories.severity}
            label={severity => severity !== 'blank' ? pretty(severity) : 'Unclassified'}
            current={filters.severity}
            onClick={id => { handleClick('severity', id) }}
          />
          <Subtitle>Surface</Subtitle>
          <Toggles
            enums={defectCategories.surface}
            label={surface => surface !== 'blank' ? pretty(surface) : 'Unclassified'}
            current={filters.surface}
            onClick={id => { handleClick('surface', id) }}
          />
          <Subtitle>Type</Subtitle>
          <Toggles
            enums={defectCategories.type}
            label={type => type !== 'blank' ? pretty(type) : 'Unclassified'}
            current={filters.type}
            onClick={id => { handleClick('type', id) }}
          />
        </Section>
      </div>
    </Drawer>
  )
}

function Section({ children }) {
  return <Box sx={{ pb: 1 }}>
    <Divider />
    { children }
  </Box>
}

function Title({ children }) {
  return <Typography variant='body1' gutterBottom><b>{ children }</b></Typography>
}

function Subtitle({ children }) {
  return <Typography variant='body1' gutterBottom>{ children }</Typography>
}

const toggleStyles = theme => ({
  chip: {
    marginBottom: theme.spacing(1),
    marginRight:  theme.spacing(1),
  },
})

function Toggles({ enums, label, current, onClick }) {
  const classes = makeStyles(toggleStyles)()

  if (!(enums?.length > 0))
    return <Typography variant='body1'>Not available</Typography>

  return <>{
    enums.map(item => {
      const id = typeof item === 'string' ? item : item._id
      return <Chip clickable
        key={id}
        color={current.includes(id) ? 'primary' : 'default'}
        className={classes.chip}
        label={label(item)}
        onClick={() => { onClick(id) }}
      />
    })
  }</>
}

export default withStyles(styles)(FilterDrawer)
