import { useRef, useEffect, useCallback, useState } from 'react'
import { Button } from '@material-ui/core'

function ElevationQuadrilateralCanvasOverlay({ imageSrc, color = 'cyan', containerRef, getLocation, recapture, setQuadrilateral}) {
  const canvasRef = useRef(null)
  const backgroundRef = useRef(null)
  const verticesRef = useRef([])

  const [posX, setPosX] = useState(0)
  const [posY, setPosY] = useState(0)

  const handleMouseDown = useCallback((event) => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const rect = canvas.getBoundingClientRect()

    function getCursorPosition(canvas, event) {
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top
      return [x,y]
    }

    const coord = getCursorPosition(canvasRef.current,event)
    
    const dotSize = 5
    const vertices = verticesRef.current

    context.beginPath()
    context.strokeStyle = color
    context.fillStyle = color
    context.fillRect(coord[0]-.5*dotSize,coord[1]-.5*dotSize,dotSize,dotSize)
    context.stroke()
    
    if (vertices.length > 0) {
      context.beginPath()
      context.lineWidth = 1
      context.moveTo(vertices[vertices.length - 1][0], vertices[vertices.length - 1][1])
      context.lineTo(coord[0],coord[1])
      context.stroke()
    }

    coord[2] = getLocation(coord)?.height
    
    vertices.push(coord)
    
    if (vertices.length >= 4) {
      const context = canvasRef.current.getContext('2d')
      context.beginPath()
      context.lineWidth = 1
      context.moveTo(vertices[vertices.length - 1][0], vertices[vertices.length - 1][1])
      context.lineTo(vertices[0][0],vertices[0][1])
      context.stroke()

      context.fillStyle = 'rgba(0, 255, 255, 0.5)'
      context.beginPath()
      context.moveTo(vertices[0][0], vertices[0][1])
      for (let i = 1; i < vertices.length; i++)
        context.lineTo(vertices[i][0], vertices[i][1])
      context.closePath()
      context.fill()

      canvasRef.current.removeEventListener('mousedown', handleMouseDown)
      const quad = []
      for (let v of vertices)
        quad.push({
          x: v[0], 
          y: v[1], 
          height: v[2] ? v[2].toFixed(2) : undefined,
          imgWidth: rect.width,
          imgHeight: rect.height
        })
        
      setQuadrilateral(quad)
    }
  }, [color, getLocation, setQuadrilateral])

  const handleMouseMove = useCallback((e) => {
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()

    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    
    setPosX(x)
    setPosY(y)
  }, [])

  const draw = useCallback(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    const imgWidth = containerRef.current.cesiumElement._element.clientWidth
    const imgHeight = containerRef.current.cesiumElement._element.clientHeight

    const canvasWidth = imgWidth
    const canvasHeight = imgHeight

    canvas.width = canvasWidth
    canvas.height = canvasHeight

    // Clear canvas
    context.clearRect(0, 0, canvasWidth, canvasHeight)

    context.drawImage(backgroundRef.current, 0, 0, canvasWidth, canvasHeight)
    canvasRef.current.addEventListener('mousedown', handleMouseDown)
    canvasRef.current.addEventListener('mousemove', handleMouseMove)
  }, [handleMouseDown, handleMouseMove, containerRef])

  useEffect((e) => {
    if (!imageSrc) return
    
    const canvas = canvasRef.current
    const background = new Image()
    verticesRef.current = []

    background.onload = () => {
      draw()
    }

    background.src = URL.createObjectURL(imageSrc)
    backgroundRef.current = background

    // Cleanup event listeners
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown)
      canvas.removeEventListener('mousemove', handleMouseMove)
    }

  }, [imageSrc, color, draw, handleMouseDown, handleMouseMove])

  const clear = () => {
    draw()
    setQuadrilateral([])
    verticesRef.current = []
  }

  const handleDownload = () => {
    const img = canvasRef.current.toDataURL('image/jpg')
    const link = document.createElement('a')
    link.download = 'elevation.jpg'
    link.href = img
    link.click()
  }

  return (
    <div style={{position: 'absolute', zIndex: 3, width: '100%', height: '100%', cursor: 'crosshair'}}>
      <div style={{
        position: 'absolute',
        background: 'transparent',
        borderTop: '1px dotted cyan',
        zIndex: 4,
        width: '100%', 
        top: `${posY}px`,
        pointerEvents: 'none'
      }}></div>
      <div style={{
        position: 'absolute',
        background: 'transparent',
        borderLeft: '1px dotted cyan',
        zIndex: 4,
        height: '100%', 
        left: `${posX}px`,
        pointerEvents: 'none'
      }}></div>
      <div style={{position: 'absolute', zIndex: 4, bottom: '48px', left: '12px'}}>
        <Button variant='outlined'
          style={{backgroundColor: '#ffffff', display: 'inline-block'}}
          onClick={() => {
            clear()
            recapture()
          }}>
            Recapture
        </Button>
        <Button variant='outlined'
          style={{backgroundColor: '#ffffff', display: 'inline-block', marginLeft: '8px'}}
          onClick={clear}>
            Clear
        </Button>
        <Button variant='outlined'
          style={{backgroundColor: '#ffffff', display: 'inline-block', marginLeft: '8px'}}
          onClick={handleDownload}>
            Download
        </Button>
      </div>
      <canvas ref={canvasRef} style={{border: '1px solid black'}}></canvas>
    </div>
  )
}

export default ElevationQuadrilateralCanvasOverlay