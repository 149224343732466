import { useEffect } from 'react'
import Loading from './Loading'
import { logout } from '../../api/accounts'

function Logout() {
  useEffect(() => { logout() }, [])
  return <Loading message='Logging out. Redirecting ...' />
}

export default Logout
