import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  Assessment,
  ErrorOutline,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import { useGetDocumentsQuery } from '../../../../../api/inspectionOps'
import { useMediaURL } from '../../../../../api/media'

import CardHeaderTitle from './CardHeaderTitle'

const styles = theme => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiCardHeader-action': {
      marginRight: 'auto',
      marginTop: 'auto',
    }
  },
  inspectionName: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  inspectionList: {
    overflow: 'auto',
    maxHeight: theme.spacing(66)
  },

  fileTypeChip: {
    marginRight: theme.spacing(1),
  },
  downloadChip: {
    marginRight: theme.spacing(2),
  },
  subcard: {
    height: '100%',
  }
})

const DownloadBar = ({ classes, doc }) => {
  const href = useMediaURL(doc?.original_file_media_id, 'original')

  const handleDownload = () => {
    window.location.href = href
  }

  return (<>
    <Chip label='PDF' size='small' className={classes.fileTypeChip} />
    <Link href='#' onClick={handleDownload} className={classes.downloadChip}>
      DOWNLOAD
    </Link>
    <Chip label='DOCX' size='small' className={classes.fileTypeChip} />
    <Link href='#' onClick={handleDownload} className={classes.downloadChip}>
      DOWNLOAD
    </Link>
  </>)
}

function InspectionReportsCard({ classes, facilityId, inspection }) {
  const { data } = useGetDocumentsQuery({ inspectionId: inspection._id })
  
  // Get first 2 documents to avoid clutter
  const documents = data ? data.data.documents.slice(0, 2) : []
  const totalCount = data ? data.data.documents.length : 0

  return <Card className={classes.subcard}>
    <CardHeader
      avatar={<Avatar><Assessment /></Avatar>}
      title={<CardHeaderTitle count={totalCount} label='Inspection Reports' />}
      titleTypographyProps={{ variant: 'h6' }}
      className={classes.cardHeader}
    />
    <CardContent>
      <List className={classes.inspectionList}>
      { documents.length === 0 ?
        <ListItem>
          <ListItemIcon><ErrorOutline /></ListItemIcon>
          <ListItemText primary='No documents found' />
        </ListItem>
        : documents.map((doc, i) => (
          <ListItem key={i} style={{ cursor: 'pointer' }}
            onClick={() => window.location.href =`/document/${doc.document_id}`} >
            <ListItemIcon><Avatar><Assessment /></Avatar></ListItemIcon>
            <ListItemText
              disableTypography
              primary={doc.name}
              secondary={<DownloadBar classes={classes} doc={doc} />}
            />
          </ListItem>
        )) }
      </List>
    </CardContent>
  </Card>
}

export default withStyles(styles)(InspectionReportsCard)