import { createTheme } from '@material-ui/core/styles'

// Facilities 4.0 website theme to follow Style Guide (Facilities 4.0)
const facilitiesTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 3408,
    },
  },
  palette: {
    primary: {
      main:  '#246ff1',    // Garuda Blue
      light: '#709dff',    // Light blue
      dark:  '#0045bd',    // Dark blue
      contrastText: '#FFFFFF',
    },
    secondary: {
      main:  '#f9723d',    // Orange (color of the facilities logo)
      light: '#ffa36a',    // Light orange
      dark:  '#c0420f',    // Dark orange
      contrastText: '#FFFFFF',
    },
    warning: {
      main:  '#f7b149',    // Yellow
      light: '#ffe379',    // Light yellow
      dark:  '#c08214'     // Dark yellow
    },
    success: {
      main:  '#5aa700',    // Green
      light: '#8dd944',    // Light green
      dark:  '#227700'     // Dark green
    },
    error: {
      main:  '#b31c16',    // Red
      light: 'ec543f',     // Light red
      dark:  '7c0000'      // Dark red
    },
    dark: {
      bg:    '#343a40'
    },
    favourites: {
      main:  '#E94235',     // Google Red
      inactive: '#CCCCCC'  // Grey
    },
    inactive: {
      main:  '#DDD',     // Grey
    },
    back: {
      main: '#D9D9D9'
    }
  }
})

export default facilitiesTheme
