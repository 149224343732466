import { useState, useRef  } from 'react'
import { toast } from 'react-toastify'
import { useAllFacilities } from '../../shared/hooks/facilities'
import { DEFAULT_MAP_CENTER } from '../../../utils/site-maps'
import {
  Box,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Add, Warning } from '@material-ui/icons'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import L from 'leaflet'

const LAT_LNG_SEARCH_RADIUS = 5000  // metres

const styles = theme => ({
  addByCoordIcon: {
    marginRight: theme.spacing(2)
  }
})

function compareFacilities(a, b) {
  if (a.distance !== undefined && b.distance !== undefined) {
    return a.distance - b.distance
  } else if (a.distance !== undefined) {
    return -1  // a has distance, b doesn't, so a comes first
  } else if (b.distance !== undefined) {
    return 1  // b has distance, a doesn't, so b comes first
  } else {
    return 0  // both a and b don't have distance, order doesn't matter
  }
}

function parseLatLng(text) {
  const regex = /^\s*(-?\d+(\.\d+)?)\s*,\s*(-?\d+(\.\d+)?)\s*$/
  const match = text.match(regex)
  if (match) {
    const lat = parseFloat(match[1])
    const lng = parseFloat(match[3])
    if (isValidLatLng(lat, lng)) {
      return { lat, lng }
    }
  }
  return null
}

function isValidLatLng(lat, lng) {
  return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180
}

/**
 * Search field for addresses and facilities, by various fields including
 * LatLng.
 * If setLatLng is non-null, a new section for creating a facility is available
 */
const SearchField = ({
  classes,
  setAddress,
  setFacility,
  setLatLng,
  setCoords,  // Set coordinates of selected address if non-null

  label = 'Search',
  placeholder = 'Enter facility name, address, or any places nearby',
}) => {
  // Addresses
  // const [ addresses, setAddresses ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const { facilities, facilitiesLoading } = useAllFacilities()

  // Combined data
  const [ data, setData ] = useState([])

  // Geolocation
  const currentLocationRef = useRef(null)
  const [ showLocationError, setShowLocationError ] = useState(false)

  // Searching
  const [ searchQuery, setSearchQuery ] = useState('')
  const [ isLatLngSearch, setIsLatLngSearch ] = useState(false)

  const getGeolocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((currentPosition) => {
        if (currentPosition?.coords?.latitude && currentPosition?.coords?.longitude) {
          console.log('got current location')
          setShowLocationError(false)
          currentLocationRef.current = {
            lat: currentPosition?.coords?.latitude, 
            lng: currentPosition?.coords?.longitude
          }
          resolve(currentLocationRef.current)
        } else {
          console.warn('no current location')
          setShowLocationError(true)
          currentLocationRef.current = {
            lat: DEFAULT_MAP_CENTER[0],
            lng: DEFAULT_MAP_CENTER[1]
          }
          resolve(currentLocationRef.current)
        }
      }, (err)=> { 
        console.warn('error getting current location', err)
        setShowLocationError(true)
        currentLocationRef.current = { 
          lat: DEFAULT_MAP_CENTER[0],
          lng: DEFAULT_MAP_CENTER[1]
        }
        resolve(currentLocationRef.current)
      })
    })
  }

  const getPredictions = async (g, query, latLng, delta) => {
    const boundsNe = new g.maps.LatLng(
      currentLocationRef.current.lat + 0.5,
      currentLocationRef.current.lng + 0.5
    )
    const boundsSw = new g.maps.LatLng(
      currentLocationRef.current.lat - 0.5,
      currentLocationRef.current.lng - 0.5
    )
    const bounds = new g.maps.LatLngBounds(boundsSw, boundsNe)

    const options = {
      input: query,
      locationBias: bounds
    }

    await g.maps.importLibrary('places')
    const svc = new g.maps.places.AutocompleteService()
    const predictions = await svc.getPlacePredictions(options)
    console.log('predictions for delta', delta, predictions)
    return predictions
  }

  const getGeoInfo = async (g, placeId) => {
    const geocoder = new g.maps.Geocoder()
    const response = await geocoder.geocode({ placeId })
    if (!response || !response.results || response.results.length === 0) {
      console.warn('no geocode results')
      return null
    }

    const result = response.results[0]
    console.log('geocode result', result)
    const address = result.formatted_address
    const lat = result.geometry.location.lat()
    const lng = result.geometry.location.lng()
    console.log('lat lng', lat, lng)
    return { address, lat, lng }
  }

  const loadSuggestions = async (event, value) => {
    setSearchQuery(value)
    if (value === null || value === '') {
      setData([])
      return
    }
    
    // prevent browser from constantly re-requesting user location
    if (currentLocationRef.current === null) {
      await getGeolocation()  // browser only allows on user action
    }

    setIsLoading(true)
    const finalData = []

    // Places address search
    // Checks if value is a valid latlng string.
    const latLng = parseLatLng(value)
    setIsLatLngSearch(latLng !== null)

    if (setAddress || setCoords) {
      const g = window.google
      if (!g) {
        console.warn('google not loaded')
        setTimeout(() => loadSuggestions(event, value), 500)
        setIsLoading(false)
        return
      }
  
      if (!currentLocationRef.current) {
        console.warn('current location not set')
        setTimeout(() => loadSuggestions(event, value), 500)
        setIsLoading(false)
        return
      }
  
      const currLatLng = new g.maps.LatLng(
        currentLocationRef.current.lat,
        currentLocationRef.current.lng
      )
      
      const preds05 = await getPredictions(g, value, currLatLng, 0.5)
      const data = preds05.predictions.map(p => ({
        id: 'place_' + p.place_id,  // for MUI autocomplete
        name: p.description,
        type: 'place',
        typeName: 'Places',
        place: p
      }))
      
      finalData.push(...data)
    }
    
    if (setFacility) {
      const currLatLng = new L.LatLng(
        currentLocationRef.current.lat,
        currentLocationRef.current.lng
      )

      // add distance from current location to each facility
      const facilitiesWithDistance = facilities
        .map(facility => {
          if (!Number.isFinite(facility?.geometry?.coordinates?.[1]) 
              || !Number.isFinite(facility?.geometry?.coordinates?.[0])) {
            return facility
          }

          const facilityLatLng = new L.LatLng(
            facility?.geometry?.coordinates?.[1],
            facility?.geometry?.coordinates?.[0]
          )
          const distance = facilityLatLng.distanceTo(currLatLng)
          return { ...facility, distance }
        })

      // Filter by latlng if available
      // Assume that if value is a valid latlng string, it is a latlng search
      let outFacData = facilitiesWithDistance
      if (latLng) {
        const gLatLng = new L.LatLng(latLng.lat, latLng.lng)
        const filtered = facilitiesWithDistance.filter(f => {
          if (!Number.isFinite(f?.geometry?.coordinates?.[1]) 
            || !Number.isFinite(f?.geometry?.coordinates?.[0])) {
            console.log('facility has no latlng')
            return false
          }

          const facilityLatLng = new L.LatLng(f?.geometry?.coordinates?.[1], f?.geometry?.coordinates?.[0])
          const distance = facilityLatLng.distanceTo(gLatLng)
          return distance <= LAT_LNG_SEARCH_RADIUS
        })
        outFacData = filtered
      }

      // sort by distance
      outFacData.sort(compareFacilities)

      // set facilities
      console.log('filtered facilities w distance', outFacData)
      const data = outFacData.map(f => ({
        id: 'facility_' + f._id,  // for MUI autocomplete
        name: f.name,
        type: 'facility',
        typeName: 'Facilities',
        facility: f
      }))
      finalData.push(...data)
    }

    // Create a new record to add a facility
    if (latLng && setLatLng) {
      const record = {
        id: 'new',
        name: `Create new facility at ${latLng.lat}, ${latLng.lng}`,
        type: 'coords',
        typeName: null,
        latLng
      }

      finalData.push(record)
    }

    finalData.sort((a, b) => a.type.localeCompare(b.type))
    console.log('finalData', finalData)

    setData(finalData)
    setIsLoading(false)
  }

  const handleSelect = async (event, value) => {
    if (!value) {
      return
    }

    switch (value.type) {
      case 'place':
        const g = window.google
        if (!g) {
          console.warn('google not loaded')
          setTimeout(() => handleSelect(event, value), 500)
          return
        }

        if (!currentLocationRef.current) {
          console.warn('current location not set')
          setTimeout(() => handleSelect(event, value), 500)
          return
        }

        console.log('selected', value)
        const geoInfo = await getGeoInfo(g, value.place.place_id)
        if (!geoInfo) {
          setAddress(null)
          toast.warn('Unable to get coordinates for selected location')
          return
        }

        console.log('geo info', geoInfo)

        if (setAddress) {
          setAddress(
            geoInfo.lat,
            geoInfo.lng,
            geoInfo.address,
            true
          )
        }

        if (setCoords) {
          setCoords({ lat: geoInfo.lat, lng: geoInfo.lng })
        }
        return
      case 'facility':
        const facility = value.facility
        setFacility(facility)
        return
      case 'coords':
        const latLng = value.latLng
        console.log('setting lat lng', latLng)
        setLatLng(latLng.lat, latLng.lng)
        return
      default:
        console.log('unknown search result type', value.type)
        return
      }
  }

  const filterOptions = createFilterOptions({
    stringify: option => isLatLngSearch ? searchQuery : option.name
  })

  return (
    <Autocomplete
      fullWidth
      options={data}
      filterOptions={filterOptions}
      getOptionLabel={opt => opt.name}
      getOptionSelected={(opt, val) => opt.id === val.id}
      groupBy={opt => opt.typeName}
      onChange={handleSelect}
      onInputChange={loadSuggestions}

      renderOption={opt => {
        return (
          <Box display='flex' alignItems='center'>
            { opt.type === 'coords' && (
              <Add className={classes.addByCoordIcon} />
            )}
            <Typography variant='body1'>
              {opt.name}
            </Typography>
          </Box>
        )
      }}
      
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (<>
              { !isLoading && params.InputProps.endAdornment }
              { (isLoading || facilitiesLoading) && 
                <CircularProgress color='inherit' size={20} /> 
              }
              { showLocationError && (
                  <Tooltip title='Error getting location'>
                    <Warning />
                  </Tooltip>
                )
              }
            </>)
          }}
        />
      )}
    />
  )
}

export default withStyles(styles)(SearchField)
