import { useState, useEffect } from 'react'
import { useMediaURL } from '../../api/media'

// Either give src, or give media_id and size
function Image({ src, media_id, size, alt, fallback, ...rest }) {
  const [ _src, setSrc ] = useState('')
  const media_url = useMediaURL(media_id, size)

  useEffect(() => {
    if (src)
      setSrc(src)
    else if (media_id && size)
      setSrc(media_url)
  }, [src, media_id, size, media_url])

  function setFallback() {
    // console.log('onError, setting fallback', fallback, 'for src', src, media_url)
    if (fallback)
      setSrc(fallback)
    else
      setSrc('/facilities40.png')
  }

  return (
    _src ?
      <img src={_src} alt={alt} onError={setFallback} {...rest} />
    : null
  )
}

export default Image
