import {
  Typography,
} from '@material-ui/core'
import {
  AssignmentTurnedIn,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(0, 2, 8, 4),
  },
  subtitle: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})

function EmptyInspections({ classes }) {
  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>
        <AssignmentTurnedIn className={classes.icon} />
        <Typography variant='h6'>
          No Inspections Found
        </Typography>
      </div>
      <Typography variant='h3' gutterBottom color='primary'>
        Let's Get Started
      </Typography>
      <Typography variant='h5' gutterBottom>Create an Inspection</Typography>
      <Typography variant='body1' gutterBottom>
        An <b>Inspection</b> is a type of task where information about the
        facility is collected to verify its condition.
      </Typography>
      <Typography variant='body1'>
        Examples of Inspections includes
      </Typography>
      <ol>
        <li>Facade Visual Inspection</li>
        <li>Facade Tactile Inspection</li>
        <li>Facade Thermal Inspection</li>
        <li>Radio Interference Inspection</li>
      </ol>
      <Typography variant='body1' gutterBottom>
        and more. You will need to create a Facility first before
        creating an Inspection. To create an Inspection, click on
        NEW INSPECTION on the top right.
      </Typography>
      <Typography variant='h5' gutterBottom>Add Inspection Images</Typography>
      <Typography variant='body1' gutterBottom>
        To upload images, create an inspection first, then click Upload.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(EmptyInspections)
