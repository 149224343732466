import ReactDOMServer from 'react-dom/server'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import '../../../assets/css/leaflet.css'
import BuildingIcon from '../../../assets/svg/apartment_icon.svg'
import PowerlineIcon from '../../../assets/svg/powerline_icon.svg'
import TelecomsIcon from '../../../assets/svg/cell_tower_icon.svg'
import {
  MapContainer,
  Marker,
  Pane,
  Popup,
  ScaleControl,
} from 'react-leaflet'
import CommonLayers     from '../../shared/map/CommonLayers'
import MyLocationMarker from '../../shared/map/MyLocationMarker'

import { Button, Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../../utils/site-theme'

const styles = theme => ({
  mapContainer: {
    height: '85vh', //TODO: Still not full height of the container. But a quick fix
  },
  rightColumn: {
    width: theme.spacing(34),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    right: theme.spacing(2),
    top: theme.spacing(40),
    zIndex: 10000,
    position: 'absolute'
  },
  leftButton: {
    float:'left',
    width: theme.spacing(16)
  },
  rightButton: {
    float:'right',
    width: theme.spacing(14)
  },
  iconMarker: {
    zIndex: 1000
  },
  higherPane: {
    zIndex: 999
  },
  mapIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  }
})

function DirectoryMap({
  classes,
  directory,
  inspectionList,
  mapInfo,
  setMap,
  onCreate,
  clearMarker,
  showMyLocation,
}) {
  const [ latitude,  setLatitude  ] = useState(mapInfo?.lat)
  const [ longitude, setlongitude ] = useState(mapInfo?.lng)
  const [ address,   setAddress   ] = useState('')
  const [ open,      setOpen      ] = useState(mapInfo?.openOverlay)
  const [ mapMarkerOn, setMapMarkerOn] = useState(false)
  const popupStyles = {
    width: '120px',
    textAlign: 'center',
    border: '1px solid ' + theme.palette.secondary.main,
    borderRadius: '0 16px 16px 16px',
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  }

  const history = useHistory()
  // TODO: Programmatically set map bounds to include all facilities


  useEffect(() => {
    setLatitude(mapInfo?.lat)
    setlongitude(mapInfo?.lng)
    setAddress(mapInfo?.address)
    setOpen(mapInfo?.openOverlay)
    setMapMarkerOn(clearMarker)
    return () => { setOpen('none') }
  }, [mapInfo, clearMarker])

  return (
    <div className={classes.mapContainer}>
      <div className={classes.rightColumn} style={{ display: open }}>
        <p>Latitude: {latitude}</p>
        <p>Longitude: {longitude} </p>
        { address && (
          <p>Address: {address}</p>
        )}

        <Button className={classes.leftButton} color='primary' variant='contained' size='small' onClick={onCreate} >
          New Facility
        </Button>
        <Button className={classes.rightButton} onClick={e => { setOpen('none') }} variant='contained' size='small' >
          CLOSE
        </Button>
      </div>

      <MapContainer
        center={[latitude, longitude]}
        zoom={12}
        ref={setMap}
        maxZoom={20}>
        <CommonLayers position='topright' defaultLayer='onemap'/>
        { showMyLocation &&
          <MyLocationMarker />
        }
        <Pane name='higherPane' className={classes.higherPane}>
          { directory.map(facility => {
            if (facility?.geometry?.coordinates?.[1] && facility?.geometry?.coordinates?.[0]) {
              // const popup =
              //   <div style={popupStyles}>
              //     <b>{facility.name}</b><br />
              //     {facility.short_name}<br />
              //     {facility.type}<br />
              //     {facility.status}
              //   </div>

              let icon = BuildingIcon
              if (facility.type === 'powerline')
                icon = PowerlineIcon
              else if (facility.type === 'telecoms')
                icon = TelecomsIcon

              const divIcon = new L.DivIcon({
                iconSize: [48, 48],
                html: ReactDOMServer.renderToString(
                  <Box display='flex' flexDirection='column' alignItems='center' textAlign='center'>
                    <img
                      className={classes.mapIcon}
                      src={icon}
                      alt='facility icon'
                    />
                    <Typography variant='body2' color='textPrimary'>
                      {facility.short_name}
                    </Typography>
                  </Box>
                )
              })

              return <Marker
                key={facility._id}
                className={classes.iconMarker}
                position={facility?.geometry?.coordinates}
                eventHandlers={{click: () => { history.push(`/facilities/${facility._id}`)  }}}
                // icon={new L.Icon({ 
                //   iconUrl: icon,
                //   iconRetinaUrl: icon,
                //   iconSize: [50,50]
                // })}
                icon={divIcon}
              >
                <Popup></Popup>
              </Marker>

              // TODO: if in viewport, load the most recent inspection images
            } else return null
          }) }
        </Pane>
        { mapMarkerOn &&
          mapInfo?.address ? < Marker position={[latitude, longitude]} icon={L.divIcon({
            html: ReactDOMServer.renderToString(<div style={popupStyles}>
              {mapInfo.address?.split(' ').map((item, index) => {
                  if (index <= 2) {
                    return <b> {item} </b>
                  } else {
                    return <span> {item} </span>
                  }
              })}
            </div>), className: 'dummy'
          })} /> : ''
        }
        <ScaleControl imperial={false} />
      </MapContainer>
    </div>
  )
}

export default withStyles(styles)(DirectoryMap)
