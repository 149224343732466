import { useState, useEffect } from 'react';
import { useCesium } from 'resium'
import { Cesium3DTileset, Cesium3DTileStyle, ScreenSpaceEventType, Color, defined, Cartesian3, DirectionalLight } from 'cesium';

const getOneMapTileset = async () => {
  const tileset = await Cesium3DTileset.fromUrl(process.env.REACT_APP_GEOSPATIAL_API+'/onemap-tileset/sg_noterrain_tiles/tileset.json', {
    maximumScreenSpaceError: 8,
    cacheBytes: 536870912*2,
    maximumCacheOverflowBytes: 536870912*2
  })
  tileset.style = new Cesium3DTileStyle({
    // Create a style rule to control each building's "show" property.
    show: {
      evaluate : function(feature) { return true }
    },
    // Set the default color style for this particular 3D Tileset.
    // For any building that has a `cesium#color` property, use that color, otherwise make it white.
    color: 'Boolean(${feature[\'cesium#color\']}) ? color(${feature[\'cesium#color\']}) : color(\'#ffffff\')'
  })
  

  return tileset
}

// Information about the currently selected feature
const selected = {
  feature: undefined,
  originalColor: new Color(),
}

// Information about the currently highlighted feature
const highlighted = {
  feature: undefined,
  originalColor: new Color(),
}

const OneMapTileset =  () => {
  const { viewer } = useCesium()
  const [ tileset, setTileset ] = useState(null)
  const [ picked, setPicked ] = useState(null)

  useEffect (() => {
    if (!picked) return

    tileset.style = new Cesium3DTileStyle({
      show: {
        evaluate : function(feature) { return (feature.getProperty('Name') === picked || feature.getProperty('gml:name') === picked) }
      },
      color: "Boolean(${feature['cesium#color']}) ? color(${feature['cesium#color']}) : color('#ffffff')"
    })
    
  }, [picked, tileset])

  useEffect(() => {
    if (!viewer?.scene) return
    getOneMapTileset().then((response) => {
      const flashlight = new DirectionalLight({
        direction: viewer.scene.camera.directionWC,
        intensity: 1
      })
      viewer.scene.light = flashlight
      viewer.scene.preRender.addEventListener(function (scene, time) {
        if (scene.light === flashlight) {
          scene.light.direction = Cartesian3.clone(
            scene.camera.directionWC,
            scene.light.direction
          )
        }
      })
      viewer.scene.sun.show = false
      viewer.scene.primitives.removeAll()
      viewer.scene.primitives.add(response)
      viewer.camera.moveBackward(40)
      setTileset(response)
    })
  },[viewer.camera, viewer.scene])

  // Get default left click handler for when a feature is not picked on left click
  const clickHandler = viewer.screenSpaceEventHandler.getInputAction(ScreenSpaceEventType.LEFT_CLICK)

//   var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
// handler.setInputAction(function (input) {
//   const pickedObject = viewer.scene.pick(input.position);
//   if (Cesium.defined(pickedObject) && viewer.scene.pickPositionSupported) {
//     const cartesian = viewer.scene.pickPosition(input.position);
//     addBillboardAt(cartesian);
//   }
// }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

  // Color a feature yellow on hover.
  if (!picked) {
    // TODO: -- Error: reading featureTables property from undefined
    // viewer.screenSpaceEventHandler.setInputAction(function onMouseMove(movement) {
    //   // If a feature was previously highlighted, undo the highlight
    //   if (highlighted && defined(highlighted.feature)) {
    //     highlighted.feature.color = highlighted.originalColor
    //     highlighted.feature = undefined
    //   }
    //   // Pick a new feature
    //   const pickedFeature = viewer.scene.pick(movement.endPosition)

    //   if (!pickedFeature || !defined(pickedFeature)) {
    //     return
    //   }

    //   // Highlight the feature if it's not already selected.
    //   if (pickedFeature !== selected.feature) {
    //     highlighted.feature = pickedFeature
    //     Color.clone(
    //       pickedFeature.color,
    //       highlighted.originalColor
    //     )
    //     pickedFeature.color = Color.YELLOW
    //   }
    // }, ScreenSpaceEventType.MOUSE_MOVE)
  
    // Color a feature on selection
    viewer.screenSpaceEventHandler.setInputAction(function onLeftClick(movement) {
      // Pick a new feature
      const pickedFeature = viewer.scene.pick(movement.position)
      if (!defined(pickedFeature)) {
        clickHandler(movement)
        return
      }

      if (!pickedFeature || !pickedFeature.getProperty) return

      // Select the feature if it's not already selected
      if (selected.feature === pickedFeature) {
        return
      }

      selected.feature = pickedFeature
      // Save the selected feature's original color
      if (pickedFeature === highlighted.feature) {
        Color.clone(
          highlighted.originalColor,
          selected.originalColor
        )
        // highlighted.feature = undefined
      } else {
        Color.clone(pickedFeature.color, selected.originalColor)
      }

      setPicked(pickedFeature.getProperty('Name') ? pickedFeature.getProperty('Name') : pickedFeature.getProperty('gml:name'))
    }, ScreenSpaceEventType.LEFT_CLICK)
  }
}

export default OneMapTileset