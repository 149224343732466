import { useEffect, useMemo, useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { AccountsContext } from '../../AccountsContextProvider'
import {
  useCreateFacilityMutation,
  useUpdateFacilityMutation,
  useUpdateFacilityProfileImageMutation,
} from '../../../api/inspectionOps'

import TopXCloseButton from '../../shared/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import {
  LocationOn,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { useScript } from '../../../utils/hooks'

const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const autocompleteService = { current: null }
let geocoder = null

// Unless you're dealing with a very complex style, most of the time
// it's better to have the style of the component in the same file.
const styles = (theme) => ({
  facilityHeightTitle: {
    marginBottom: theme.spacing(1)
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  browseFiles: {
    height: '50px',
    display: 'flex',
    alignItems: 'center'
  }
})

// Highly recommended: Immediately decompose props.
// - This has the added benefit to serve as your 'function signature'
// - Very importantly, you don't point local state at the prop object!
// - If you must use objects, make a deep copy of the object locally.
//
const FacilityDialog = ({
  classes,
  facility,
  open,
  isUpdate,
  onClose,
  lat,
  lng,
  address,
  setFacilityUpdatingImageId
}) => {
  const gmapsScriptLoaded = useScript(
    `https://maps.googleapis.com/maps/api/js?language=en&key=${googleMapsKey}&libraries=places`,
    'google-maps'
  )
  const { company_id } = useContext(AccountsContext)

  const [ createFacility ] = useCreateFacilityMutation()
  const [ updateFacility ] = useUpdateFacilityMutation()
  const [ updateFacilityProfileImage ] = useUpdateFacilityProfileImageMutation()

  // Highly recommended: Avoid deeply nested objects for your local state.
  // Manage every control separately using an immutable variable.
  // Leaving this in the code for future reference / tutorial
  //
  const [ _fac, setFac ] = useState({
    name:       '',
    short_name: '',
    type:       '',
    address:    '',
    lat:        '',
    lng:        '',
    amsl:       '',
    status:     '',
  })

  // For this tutorial, we will examine metres and stories
  //
  // Here's the suggested better way to handle **TextField**
  // Firstly, TextFields cannot handle _numbers_.
  // In the complex object above metres and stories are numbers.
  // TextFields operate only on Strings as value
  //
  const [ heightMetres,  setHeightMetres  ] = useState('')
  const [ heightStories, setHeightStories ] = useState('')

  const [ files, setFiles ] = useState([])

  // Disable the submit button when pressed
  const [ isSubmitting, setIsSubmitting ] = useState(false)

  const [ addrOptions, setAddrOptions] = useState([])
  const [ addrInput,   setAddrInput  ] = useState('')
  const [ gAddress,     setGAddress    ] = useState('')

  const throttleFetch = useMemo(() => {
    let timer
    return (request, callback) => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200)
    }
  }, [])

  useEffect(() => {
    if (!gmapsScriptLoaded) {
      console.log('Google not loaded')
      return
    }
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }
    if (!geocoder) {
      geocoder = new window.google.maps.Geocoder()
    }

    if (addrInput === '') {
      setAddrOptions(gAddress ? [gAddress] : [])
      return undefined
    }

    throttleFetch({ input: addrInput }, (results) => {
      if (active) {
        let newOptions = []

        if (gAddress) {
          newOptions = [gAddress]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setAddrOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [gmapsScriptLoaded, gAddress, addrInput, throttleFetch])

  useEffect(() => {
    if (facility) {
      // Keeping as an example of setting deep objects. You will end up deep
      // cloning to ensure immutabilty, not the best idea but sometimes you
      // need it. In this case, you're pointing at the prop outside.
      setFac({
        ...facility,
        lat: facility.centroid?.coordinates?.[1] || '',
        lng: facility.centroid?.coordinates?.[0] || '',
        amsl: facility.centroid?.coordinates?.[2] || '',
      })

      // Recommended:
      // When you first receive a new prop, you convert everything to
      // a local string variable, exactly how you want to display it
      //
      // Besides the benefit of getting your data type right, you also
      // ensure the mistake of affecting the outside value never happens
      //
      setHeightMetres(facility.height?.metres?.toString() || '')
      setHeightStories(facility.height?.stories?.toString() || '')
    }
    // TODO Review this use case where facility is created via map search
    else if (address) {
      setFac({
        address,
        lat: lat.toString(),
        lng: lng.toString(),
      })
    } else if (lat && lng) {
      setFac({
        lat: lat.toString(),
        lng: lng.toString(),
      })
    }
  }, [facility, lat, lng, address])

  useEffect(() => {
    if (!isUpdate && open)
    setFac({
      name:       '',
      short_name: '',
      type:       '',
      address:    '',
      lat:        '',
      lng:        '',
      amsl:       '',
      status:     '',
    })
  }, [isUpdate, open])

  // Keeping as an example of setting deep fields and shallow cloning
  // This means only the top level of the object is copied in ...prevState
  // The 'height' nested object continues to point to the external object
  const handleInputChange = (event) => {
    let name = event.target.name
    let value = event.target.value

    setFac(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  // During regular operation of a TextField, just set the value
  // This gives user the best experience of just typing whatever they want
  const handleHeightMetresChange = (evt) => {
    setHeightMetres(evt.target.value)
  }

  const handleHeightStoriesChange = (evt) => {
    setHeightStories(evt.target.value)
  }

  const isSubmitDisabled = () => {
    return isSubmitting || !company_id || !_fac.name || !_fac.type || 
              (isNaN(_fac.lat) || _fac.lat==='') || (isNaN(_fac.lng) || _fac.lng==='') || (isNaN(_fac.amsl) || _fac.amsl==='') ||
              !isEmptyOrDigits(heightMetres, true) || !isEmptyOrDigits(heightStories, false)
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)

    async function submitImage(facilityId) {
      if (files.length > 0) {
        if (setFacilityUpdatingImageId) {
          setFacilityUpdatingImageId(facilityId)
        }
        updateFacilityProfileImage({ facilityId, image: files[0] })
        .unwrap()
        .then(fulfilled => {
          console.log('Facility profile image update fulfilled', fulfilled)
          if (fulfilled.status === 'success') {
            toast.success('Facility profile image updated.')
          }
          else
          toast.error(`Failed to update facility profile image.`)
        })
        .catch(rejected => {
          console.log('update rejected', rejected)
          toast.error(`Error updating facility profile image.`)
        }).finally(() => {
          if (setFacilityUpdatingImageId) {
            setFacilityUpdatingImageId(null)
          }
        })
        setFiles([])
      }
      onClose()
    }

    // Finally, right before you update, put all the local state back together
    // This is the best chance. In this example I'm doing a shallow clone for
    // fields I did not manage individually, but for height, I manage it plus
    // I got my chance of putting it back from string to number.
    const newFacility = {
      name:       _fac.name,
      company_id,
      short_name: _fac.short_name,
      type:       _fac.type,
      address:    _fac.address,
      height: {
        metres:  parseInt(heightMetres)  || 0,
        stories: parseInt(heightStories) || 0,
      },
      centroid: {
        type: 'Point',
        coordinates: [parseFloat(_fac.lng), parseFloat(_fac.lat), parseFloat(_fac.amsl)]
      }
    }
    // TODO: this is also a good place to block the submission when there's at least
    //       one issue with the form. Warning users on the field itelf is useful for
    //       data type issues, but warning users here is useful for incongruent fields
    //       E.g. if the height in meters is not 0, could the height in stories be 0?
    // In this example, I only default any non parsable integer to 0 for simplicity.

    if (isUpdate) {
      newFacility._id = facility._id
      console.log('update facility:', newFacility)
      updateFacility(newFacility)
      .unwrap()
      .then(fulfilled => {
        console.log('update fulfilled', fulfilled)
        if (fulfilled.status === 'success') {
          submitImage(fulfilled.data.facility._id)
          toast.success('Facility updated.')
        }
        else {
          toast.error('Failed to update facility.')
        }
      })
      .catch(rejected => {
        console.log('update rejected', rejected)
        toast.error('Update facility error.')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
    } else {
      newFacility.status = 'active'
      console.log('create facility:', newFacility)
      createFacility(newFacility)
      .unwrap()
      .then(fulfilled => {
        console.log('create fulfilled', fulfilled)
        toast.success('Facility created.')
        if (fulfilled.status === 'success') {
          submitImage(fulfilled.data.facility._id)
        }
        else {
          toast.error('Failed to create facility.')
        }
      })
      .catch(rejected => {
        console.log('create rejected', rejected)
        toast.error('Error creating facility.')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
    }
  }

  const handleFileChosen = (e) => {
    const selectedFiles = e.target.files
    setFiles(prevState => [...prevState, ...selectedFiles])
  }

  // Please avoid large deep nesting of HTML elements
  // Though not exactly necessary, you can use <Grid> but keep the entire Grid within one screen view
  // The way to do this is to refactor our every control to be its own variable (and sometimes its
  // own separate component). In this example, I've refactored Snackbar as an component below, while
  // making TextFields a variable here.

  const uploadImageTitle =
    <div className={classes.browseFiles}>
      Upload Facility Image
    </div>

  const uploadImageInput =
    <div className={classes.browseFiles}>
      <input type='file' onChange={handleFileChosen} />
    </div>

  const facilityNameTextField =
    <TextField
      variant='outlined'
      label='Facility Name'
      name='name'
      value={_fac.name}
      onChange={handleInputChange}
      fullWidth
      required />

  const facilityShortNameTextField =
    <TextField
      variant='outlined'
      label='Facility Short Name'
      name='short_name'
      value={_fac.short_name}
      onChange={handleInputChange}
      inputProps={{ maxLength: 30 }}
      fullWidth />

  const facilityTypeSelect =
    <FormControl required fullWidth variant='outlined'>
      <InputLabel id='facility_type_label'>Type</InputLabel>
      <Select
        labelId='facility_type_label'
        id='facility_type'
        name='type'
        value={_fac.type}
        onChange={handleInputChange}
        fullWidth
        required
        label='Type'>
        <MenuItem value='building'>Building</MenuItem>
        <MenuItem value='powerline'>Power Line</MenuItem>
      </Select>
    </FormControl>

  const facilityAddressTextField = 
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      options={addrOptions}
      autoComplete
      includeInputInList
      value={_fac.address}
      onChange={(event, newValue) => {
        setAddrOptions(newValue ? [newValue, ...addrOptions] : addrOptions)
        if (newValue){
          setGAddress(newValue)
          setFac(prevState => ({
            ...prevState,
            'address': newValue.description
          }))
          geocoder
            .geocode({ placeId: newValue.place_id })
            .then(({ results }) => {
              setFac(prevState => ({
                ...prevState,
                'lat': results[0].geometry.location.lat()
              }))
              setFac(prevState => ({
                ...prevState,
                'lng': results[0].geometry.location.lng()
              }))
            })
        }
      }}
      onInputChange={(event, newAddrInput) => {
        setAddrInput(newAddrInput)
        setFac(prevState => ({
          ...prevState,
          'address': newAddrInput
        }))
      }}
      renderInput={(params) => (
        <TextField {...params} label='Facility Address' variant='outlined' fullWidth />
      )}
      renderOption={(option) => {
        //TODO: Highlighting using autosuggest-highlight
        return (
          <Grid container alignItems='center'>
            <Grid item>
              <LocationOn className={classes.icon} />
            </Grid>
            <Grid item xs>
              <span style={{fontWeight: 400}}>
                {option.structured_formatting?.main_text}
              </span>

              <Typography variant='body2' color='textSecondary'>
                {option.structured_formatting?.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />

  /* <Grid item xs={6}>
    <FormControl required fullWidth variant='outlined'>
      <InputLabel id='facility_status_label'>Status</InputLabel>
      <Select labelId='facility_status_label' id='facility_status' value={_fac.status} fullWidth required label='Status'>
        <MenuItem value='active'>Active</MenuItem>
        <MenuItem value='archived'>Archived</MenuItem>
        <MenuItem value='deleted'>Deleted</MenuItem>
      </Select>
    </FormControl>
  </Grid> */
  /* <Grid item xs={12}>
    <Typography variant='body1'>Profile Image</Typography>
    {_fac.profile_image &&
        <img src={TestImage} alt='Facilities 4.0 Logo' />
    }
    <Button onClick={() => null}>Choose new image</Button>
  </Grid> */

  function isEmptyOrDigits(val, allowDecimal) {
    // \d is an invalid test because it includes e . and digits in other languages like 一二三
    if (!allowDecimal)
      return /^[0-9]*$/.test(val) === true
    else
      return /^[0-9]*\.?[0-9]*$/.test(val) === true
  }

  const facilityLatTextField =
    <TextField
      variant='outlined'
      label='Facility Latitude'
      name='lat'
      value={_fac.lat}
      onChange={handleInputChange}
      fullWidth
      required
    />
  const facilityLngTextField =
    <TextField
      variant='outlined'
      label='Facility Longitude'
      name='lng'
      value={_fac.lng}
      onChange={handleInputChange}
      fullWidth
      required
    />

  const facilityAmslTextField =
    <TextField
      variant='outlined'
      label='Facility AMSL'
      name='amsl'
      value={_fac.amsl}
      onChange={handleInputChange}
      fullWidth
      required
    />

  const facilityHeightGrid = <>
    <Typography
      variant='body1'
      className={classes.facilityHeightTitle}>
      Facility Height
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          variant='outlined'
          label='Height in metres'
          name='metres'
          helperText={isEmptyOrDigits(heightMetres, true) ? null : 'Please enter valid height'}
          value={heightMetres}
          onChange={handleHeightMetresChange}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position='end'>m</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant='outlined'
          label='Height in stories'
          name='stories'
          helperText={isEmptyOrDigits(heightStories, false) ? null : 'Please enter valid number of stories'}
          value={heightStories}
          onChange={handleHeightStoriesChange}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position='end'>stories</InputAdornment>,
          }}
        />
      </Grid> 
    </Grid>
  </>

  return (
    <>
      <Dialog open={open}>
        <TopXCloseButton onClick={onClose} />
        <DialogTitle className={classes.title}>
          { isUpdate ? 'Edit' : 'Add' } Facility
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>{ uploadImageTitle }</Grid>
            <Grid item xs={8}>{ uploadImageInput }</Grid>
            <Grid item xs={12}>{ facilityNameTextField }</Grid>
            <Grid item xs={12}>{ facilityShortNameTextField }</Grid>
            <Grid item xs={12}>{ facilityTypeSelect }</Grid>
            <Grid item xs={12}>{ facilityAddressTextField }</Grid>
            <Grid item xs={4}>{ facilityLatTextField }</Grid>
            <Grid item xs={4}>{ facilityLngTextField }</Grid>
            <Grid item xs={4}>{ facilityAmslTextField }</Grid>
            <Grid item xs={12}>{ facilityHeightGrid }</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            disabled={isSubmitDisabled()}
          >
            { isUpdate ? 'Update' : 'Create' }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(FacilityDialog)
