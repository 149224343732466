import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { standardHeaders } from './api-utils'

const GEOSPATIAL_API = new URL(process.env.REACT_APP_GEOSPATIAL_API).href

export const geospatialApi = createApi({
  reducerPath: 'geospatialApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GEOSPATIAL_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['Label To Location'],
  endpoints: (builder) => ({
    labelToLocation: builder.query({
      query: ({ label_media, elevation_line }) => ({
        url: '/label-to-location',
        method: 'POST',
        body: { label_media, elevation_line }
      }),
    }),
    terrain: builder.query({
      query: ({ locations }) => ({
        url: '/terrain/elevation',
        method: 'POST',
        body: { locations }
      }),
    }),
  }),
})

export const {
  useLabelToLocationQuery,
  useTerrainQuery,
  useLazyTerrainQuery
} = geospatialApi
