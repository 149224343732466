import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TopXCloseButton from './TopXCloseButton'

const styles = theme => ({
  title: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
  },
})

function AreYouSureDialog({ classes, open, onClose, title, message, action, onOK, error, isLoading = false }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      fullWidth
      maxWidth='sm'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        { title || 'Confirmation' }
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          { message || 'Are you sure?' }
        </Typography>
      </DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        { error &&
          <Typography variant='body1' className={classes.error}>{error}</Typography>
        }
        <Button
          variant='contained'
          color='secondary'
          onClick={() => onOK()}
          disabled={isLoading}>
          {isLoading ? (
            <>
              <CircularProgress size={20} className={classes.spinnerIcon} />
              Loading...
            </>
          ) : (
            action || 'OK'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(AreYouSureDialog)