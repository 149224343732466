import { useState } from 'react'
import { hasValidSub } from '../../../../api/accounts'
import { useFacilitiesSubscription } from '../../../../utils/hooks'
import useCompanies       from '../../../shared/useCompanies'
import SubscriptionDialog from '../../../shared/SubscriptionDialog'
import AIApplyDialog      from './AIApplyDialog'
import AISimpleDialog     from './AISimpleDialog'
import {
  Button,
} from '@material-ui/core'

function AIApplyButton({ inspectionId }) {
  const [ open, setOpen ] = useState(false)
  const { my_company } = useCompanies()
  const subscribed = hasValidSub(useFacilitiesSubscription())
  const isEnterprise = my_company?.properties?.tier === 'enterprise'

  return (<>
    <Button variant='contained' onClick={() => setOpen(true)}>
      Label using AI Algorithm
    </Button>
    { open && !subscribed &&
      <SubscriptionDialog onClose={() => setOpen(false)} />
    }
    { open && subscribed && isEnterprise &&
      <AIApplyDialog
        onClose={() => setOpen(false)}
        inspectionId={inspectionId}
      />
    }
    { open && subscribed && !isEnterprise &&
      <AISimpleDialog
        onClose={() => setOpen(false)}
        inspectionId={inspectionId}
      />
    }
  </>)
}

export default AIApplyButton