
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { makeQueryString, standardHeaders } from './api-utils'

const TASKS_API = process.env.REACT_APP_TASKS_API
if (!TASKS_API) throw new Error('Environment Variable REACT_APP_TASKS_API is required.')

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TASKS_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['Tasks'],
  endpoints: (builder) => ({
    getTasks: builder.query({
      query: (params) => (`/${makeQueryString(params, [
          'status',
          'type'
      ])}`),
      providesTags: ['Tasks']
    }),
  }),
})

export const {
  useGetTasksQuery,
} = tasksApi
