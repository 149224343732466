import {
  CircularProgress,
  Dialog,
  Typography,
} from '@material-ui/core'
import {
  Error,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  main: {
    display:        'flex',
    flexDirection:  'row',
    justifyContent: 'center',
    alignItems:     'center',
    padding:        theme.spacing(2),
  },
  icon: {
    width:  theme.spacing(5),
    height: theme.spacing(5),
    margin: theme.spacing(1),
    color:  theme.palette.secondary.main,
  }
})

function LoadingLightbox({ open, onClose, showProgress, progress, message, error, classes }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.main}>
        {
          error ? <>
            <Error className={classes.icon} />
            <Typography variant='body1'>{ error }</Typography>
          </> : <>
            { showProgress ? 
              <CircularProgress className={classes.icon} variant='determinate' value={progress*100}/>
              : <CircularProgress className={classes.icon} />
            }
            <Typography variant='body1'>{ message }</Typography>
          </>
        }
      </div>
    </Dialog>
  )
}

export default withStyles(styles)(LoadingLightbox)
