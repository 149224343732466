import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Chip,
  List,
  Typography,
} from '@material-ui/core'
import {
  Error,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import CardHeaderTitle from './CardHeaderTitle'
import { useGetDefectsQuery } from '../../../../../api/inspectionOps'
import { pretty } from '../../../../../utils'

const styles = theme => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiCardHeader-action': {
      marginRight: 'auto',
      marginTop: 'auto',
    }
  },
  inspectionName: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  inspectionList: {
    overflow: 'auto',
    maxHeight: theme.spacing(66)
  },
  chip: {
    width: 'fit-content',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  chipLabel: {
    fontWeight: 'bold',
  },
  countChip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(2)
  },
  subcard: {
    height: '100%',
  },
})

const DetectionChip = ({ classes, label }) => {
  const text = (
    <Typography variant='body2' className={classes.chipLabel}>
      {label}
    </Typography>
  )

  return <Chip className={classes.chip} label={text} />
}

function DetectionsCard({ classes, inspection }) {
  const {
    data: defectsData
  } = useGetDefectsQuery({ inspection_ids: [inspection._id] }, {
    skip: !inspection._id
  })
  
  const defectsByType = {}
  if (defectsData?.data?.defects)
    for (let d of defectsData.data.defects) {
      if (defectsByType[d.type])
        defectsByType[d.type]++
      else
        defectsByType[d.type] = 1
    }


  return <Card className={classes.subcard}>
    <CardHeader
      avatar={<Avatar><Error /></Avatar>}
      title={<CardHeaderTitle count={defectsData?.data?.defects ? defectsData?.data?.defects.length : 0} label='Detections' />}
      titleTypographyProps={{ variant: 'h6' }}
      className={classes.cardHeader}
    />
    <CardContent>
      <List className={classes.inspectionList}>
        <Box display='flex' flexDirection='column' justifyContent='space-between'>
          {
            Object.keys(defectsByType).map((d,i) =>
              <DetectionChip key={i} classes={classes} label={pretty(d)} />)
          }
        </Box>
      </List>
    </CardContent>
  </Card>
}


export default withStyles(styles)(DetectionsCard)