
import {
  LayersControl,
  TileLayer,
} from 'react-leaflet'

import {
  OSM_SERVER,
  OSM_ATTRIBUTION,
  ONEMAP_SERVER,
  ONEMAP_ATTRIBUTION,
} from '../../../utils/site-maps'

import GoogleMapsBaseLayers from './GoogleMapsBaseLayers'

const { BaseLayer } = LayersControl

function CommonLayers({
  position,
  hideControls,
  freezeControls,

  defaultLayer = 'onemap',
}) {

  // TODO: when freezeControls is true, show the LayersControl but don't allow changes

  return (hideControls ?
    <TileLayer
      url={OSM_SERVER}
      attribution={OSM_ATTRIBUTION}
      maxZoom={22}
      maxNativeZoom={18}
    />
  : <LayersControl position={ position || 'topleft' }>
      <GoogleMapsBaseLayers />
      <BaseLayer checked={defaultLayer === 'osm'} name='OpenStreetMap'>
        <TileLayer
          url={OSM_SERVER}
          attribution={OSM_ATTRIBUTION}
          maxZoom={22}
          maxNativeZoom={18}
        />
      </BaseLayer>
      { ONEMAP_SERVER && ONEMAP_ATTRIBUTION &&
        <BaseLayer checked={defaultLayer === 'onemap'} name='SLA OneMap'>
          <TileLayer
            url={ONEMAP_SERVER}
            attribution={ONEMAP_ATTRIBUTION}
            maxZoom={22}
            maxNativeZoom={18}
          />
        </BaseLayer>
      }
    </LayersControl>
  )
}

export default CommonLayers
