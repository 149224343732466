import { useState } from 'react'

import {
  useGetUsersQuery,
} from '../../api/accounts'

import { allRoles, validRoles } from '../../utils/enums'
import { validEmail } from '../../utils'
import User from './text/User'
import TopXCloseButton from './TopXCloseButton'

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  AssignmentTurnedIn,
  CheckCircle,
  Close,
  Computer,
  Delete,
  ImageSearch,
  Person,
  SportsEsports,
  SupervisorAccount,
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  snackbarError: {
    backgroundColor: theme.palette.error.main
  },
})

function InspectionPersonnelDialog({
  classes,
  open,
  onClose,
  // isUpdate,
  personnel,
  setInspection,
}) {
  // const [ usersBackend,  setUsersBackend  ] = useState([])
  const [ snackbarError, setSnackbarError ] = useState('')

  // TODO
  // const usersBackend = personnel.map(person => {
  //   const { data } = useGetUserByUserIdQuery(person.user_id)
  //   return data?.data?.user || {}
  // })

  return (<>
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <TopXCloseButton onClick={onClose} />
      <DialogContent>
        <PersonnelHeaders />
        <ExistingPersonnel
          personnel={personnel}
          setInspection={setInspection} />
        <AddPersonnel
          personnel={personnel}
          setInspection={setInspection}
          setSnackbarError={setSnackbarError} />
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained'
          onClick={onClose}>Ok</Button>
      </DialogActions>
    </Dialog>
    <Snackbar open={!!snackbarError} autoHideDuration={3000}
      onClose={() => setSnackbarError('')}>
      <SnackbarContent className={classes.snackbarError}
        message={snackbarError}
        action={[
          <IconButton key='close' color='inherit'
            onClick={() => setSnackbarError('')}>
            <Close style={{ fontSize: 20 }} />
          </IconButton>
        ]}
      />
    </Snackbar>
  </>)
}

const addPersonnelStyles = theme => ({
  addPersonnel: {
    display: 'flex',
    alignItems: 'top',
    marginTop: theme.spacing(2),
  },
  emailTextField: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  addButton: {
    flexShrink: 0,
    marginBottom: theme.spacing(3),
  },
})

function AddPersonnel({ personnel, setInspection, setSnackbarError }) {
  const classes = makeStyles(addPersonnelStyles)

  const [ loading,    setLoading    ] = useState(false)
  const [ newEmail,   setNewEmail   ] = useState('')
  const [ emailError, setEmailError ] = useState('')

  const { data, error, isLoading } = useGetUsersQuery({
    email: newEmail,
  }, { skip: !loading })

  if (loading && !isLoading) {
    setLoading(false)
    if (error) {
      setSnackbarError('Unknown error. Try again later.')
      console.error('AddPersonnel Error', error)
    } else if (!data?.status === 'success') {
      setEmailError('Email not found, check your input')
      console.info('AddPersonnel Fail', data)
    }
  }
  const newUser = data?.data?.users[0]

  if (newUser) {
    console.log('newUser', newUser)
    if (personnel.some(person => person.user_id === newUser.user_id)) {
      setEmailError('Personnel already exists. You can select multiple roles for each personnel.')
    }
    else if (!newUser.app_roles?.facilities || newUser.app_roles.facilities.length === 0) {
      setEmailError('Personnel does not have permission to access Facilities 4.0')
    }
    else {
      const newPersonnel = {
        email:   newUser.email, // same as newEmail
        name:    newUser.name,
        user_id: newUser.user_id,
        role:    [...newUser.app_roles.facilities],
        company: newUser.company, // this is currently missing (See Accounts Service docs for discussion)
      }
      console.log('newPersonnel', newPersonnel)
      setInspection(prevInspection => ({
        ...prevInspection,
        personnel: [...prevInspection.personnel, newPersonnel]
      }))
      setNewEmail('')
    }
  }

  function handleEmailChange(e) {
    setEmailError('')
    setNewEmail(e.target.value)
  }

  return (
    <div className={classes.addPersonnel}>
      <TextField
        className={classes.emailTextField}
        type='email'
        name='personnel'
        value={newEmail}
        fullWidth
        variant='outlined'
        size='small'
        onChange={handleEmailChange}
        error={!!emailError}
        helperText={emailError || 'Email of the authorized inspection personnel (1 per line, click add after each email)'}
      />
      <Button
        className={classes.addButton}
        variant={ loading ? 'text' : 'contained' }
        color='secondary'
        onClick={() => setLoading(true)}
        disabled={loading || !validEmail(newEmail)}>
        { loading ? 'Loading...' : 'Add' }
      </Button>
    </div>
  )
}

const existingPersonnelStyles = theme => ({
  avatar: {
    width:  theme.spacing(4),
    height: theme.spacing(4),
  },
  checkCircle: {
    width:  theme.spacing(4),
    height: theme.spacing(4),
  },
})

function ExistingPersonnel({ personnel, setInspection }) {
  const classes = makeStyles(existingPersonnelStyles)

  function handleDeletePersonnel(index) {
    const newPersonnel = [...personnel]
    newPersonnel.splice(index, 1)

    setInspection(prevState => ({
      ...prevState,
      personnel: newPersonnel
    }))
  }

  return personnel.map((p, index) => (
    <Grid container spacing={1} alignItems='center' key={p.user_id + index}>
      <Grid item md={1}>
        <Avatar className={classes.avatar}>{index + 1}</Avatar>
      </Grid>
      <Grid item md={4}>
        <Typography variant='body1'>
          <User id={p.user_id} name />
        </Typography>
        <Typography variant='body2'>
          {p.company?.name}{p.email && (', ' + p.email)}
        </Typography>
      </Grid>
      { validRoles.map(role => (
        <Grid item md={1} key={role} style={{ textAlign: 'center' }}>
          { p.role?.includes(role) &&
            <CheckCircle color='primary' className={classes.checkCircle} />
          }
        </Grid>
      )) }
      <Grid item md={1}>
        <IconButton onClick={e => handleDeletePersonnel(index)}>
          { personnel.length > 1 && <Delete /> }
        </IconButton>
      </Grid>
    </Grid>
  ))
}

function PersonnelHeaders() {
  const roleIcon = {
    'fm-manager': <SupervisorAccount />,
    'fm-user':    <Person />,
    'dsp-pilot':  <SportsEsports />,
    'dsp-user':   <Computer />,
    'pro-fi':     <ImageSearch />,
    'pro-cp':     <AssignmentTurnedIn />,
  }
  return (
    <Grid container spacing={1}>
      <Grid item md={5}>
        <Typography variant='h5'>Personnel</Typography>
      </Grid>
      { validRoles.map(role => (
        <Grid item md={1} key={role} style={{ textAlign: 'center' }}>
          { roleIcon[role] }<br />
          <Typography variant='caption'>{allRoles[role]?.label || ''}</Typography>
        </Grid>
      )) }
    </Grid>
  )
}

export default withStyles(styles)(InspectionPersonnelDialog)