export const validInspectionTypes = {
  building: [
    'facade-visual',
    'facade-tactile',
  ],
  powerline: [
    'facade-visual',
  ],
  telecoms: [
    'facade-visual',
    'radio-interference'
  ],
}

export const allInspectionTypes = {
  'facade-visual': {
    label: 'Facade - Visual',
  },
  'facade-tactile': {
    label: 'Facade - Tactile',
  },
  'radio-interference': {
    label: 'Radio - Inteference'
  }
}

export const validInspectionStatuses = [
  'draft',
  'for-approval',
  'inspecting',
  'processing',
  'completed',
  'cancelled',
]

export const allInspectionStatuses = {
  draft: {
    label: 'Planning (Draft)',
  },
  'for-approval': {
    label: 'Plan Submitted for Approval',
  },
  inspecting: {
    label: 'Flight Operation (Inspection In Progress)',
  },
  processing: {
    label: 'Processing Data, Preparing Reports',
  },
  completed: {
    label: 'Inspection Completed',
  },
  cancelled: {
    label: 'Inspection Cancelled',
  }
}

// Currently in used roles
export const validRoles = [
  'fm-manager',
  'fm-user',
  'dsp-pilot',
  'dsp-user',
  'pro-fi',
  'pro-cp',
]

// All roles as per design document
export const allRoles = {
  'fm-manager': {
    label: 'Facility Manager',
    fullName: 'Facility Manager',
    type: 'FMs',
  },
  'fm-user': {
    label: 'FM User',
    fullName: 'Other Facilities Management User',
    type: 'FMs',
  },
  'dsp-pilot': {
    label: 'Drone Pilot',
    fullName: 'Drone Service Provider Pilot',
    type: 'vendors',
  },
  'dsp-user': {
    label: 'DSP User',
    fullName: 'Other Drone Service Provider Users',
    type: 'vendors',
  },
  'pro-fi': {
    label: 'Facility Inspector',
    fullName: 'Professional Facility Inspector',
    type: 'vendors',
  },
  'pro-cp': {
    label: 'Competent Person',
    fullName: 'Professional Facade Inspector with Competent Person Qualifications',
    type: 'vendors',
  },
}
