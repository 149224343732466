import { useEffect, useRef , useState} from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  withStyles
} from '@material-ui/core'

import TopXCloseButton from '../../../../shared/TopXCloseButton'
import CommonLayers from '../../../../shared/map/CommonLayers' 
import PolylineIcon from '../../../../../assets/svg/polyline.svg'

import {
  FeatureGroup,
  MapContainer,
  useMap
} from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import '../../../../../assets/css/leaflet.css'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { GeomanControls } from 'react-leaflet-geoman-v2'
// import SearchField from '../../../components/SearchField'
import { DEFAULT_MAP_CENTER } from  '../../../../../utils/site-maps'

const styles = (theme) => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  fileUpload: {
    width: theme.spacing(27),
    overflow: 'hidden'
  },
  content: {
    height: theme.breakpoints.values.sm,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  elevNameField: {
    width: '50%',
  },
  important: {
    fontWeight: 'bold',
  }
})

function ElevationLineModal({ facility, classes, open, onClose, onNext, name, setName, location, setLocation }) {
  const [ mapCoords, setMapCoords ] = useState(facility?.centroid?.coordinates ? [facility?.centroid?.coordinates[1],facility?.centroid?.coordinates[0]] : DEFAULT_MAP_CENTER)

  useEffect(() => { if (facility) setMapCoords([facility?.centroid?.coordinates[1], facility?.centroid?.coordinates[0]]) }, [facility])

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        Step 1: Locate the Elevation on the map, viewed from top-down
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box display='flex' flexDirection='column' marginBottom={1}>
          <TextField 
            variant='outlined'
            label='Enter your Elevation Name'
            value={name}
            onChange={e => setName(e.target.value)}
            className={classes.elevNameField}
          /> 
        </Box>
        <Typography variant='body1'>
          Click the <img src={PolylineIcon} alt='polyline icon' style={{border: '1px solid grey', borderRadius: '3px', padding: '4px', width: '24px', marginBottom: '-6px'}}/> icon on the left of the map to activate the Line tool.<br/>
          Click two points on the map representing the start and the end of the Elevation. <br/>
          Tip: For more accurate elevation geolocation, draw the elevation line in clockwise direction around the facility <br/><br/>
        </Typography>
        <Box flexGrow='1' style={{marginBottom: '16px'}}>
          <MapContainer
            center={mapCoords}
            zoom={18}
            maxZoom={20}>
            <CommonLayers position='topright' defaultLayer='onemap'/>
            <GeomanTools onSave={setLocation} location={location}/>
          </MapContainer>
        </Box>
      </DialogContent>
      <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={name === '' || !location || location?.features?.length === 0}
            onClick={onNext}>
            Next
          </Button>
      </DialogActions>
    </Dialog>
  )
}

function GeomanTools({onSave, location}) {
  const map = useMap()
  const featureGroupRef = useRef(null)

  useEffect(() => {
    if (!location) return

    L.geoJSON(location, {
      onEachFeature: (feature, layer) => {
        layer.options.pmIgnore = false
        L.PM.reInitLayer(layer)
        layer.bringToFront()
        featureGroupRef.current.addLayer(layer)
      }
    })
  }, [location])

  const clearPolygons = () => {
    const fgroup = featureGroupRef.current
    const geoJSON = fgroup.toGeoJSON()
    geoJSON.features = []
    onSave({...geoJSON})
    fgroup.clearLayers()
  }

  if (!map._events['pm:drawstart']) {
    map.on('pm:drawstart', ({ workingLayer }) => {
      clearPolygons()
      workingLayer.on('pm:vertexadded', (e) => {
        if (e.shape === 'Line' && workingLayer.getLatLngs().length >= 2){
          map.pm.Draw.Line._finishShape()
          const layers = featureGroupRef?.current?.getLayers()
          if (layers.length > 1)
            for(let i = 1; i < layers.length; i++){
              featureGroupRef.current.removeLayer(layers[i])
            }
          const fgroup = featureGroupRef.current
          const geoJSON = fgroup.toGeoJSON()
          geoJSON.features = [geoJSON.features[geoJSON.features.length - 1]]
          onSave({...geoJSON})
        }
      })
    })

    map.on('pm:create',(e)=>{
      e.layer.setStyle({color: 'cyan'})
    })
  }
  
  return (
    <>
      <div style={{position: 'absolute', zIndex: 999, bottom: '10px', left: '10px'}}>
        <Button variant='outlined'
          style={{backgroundColor: '#ffffff', display: 'block', marginTop: '4px'}}
          onClick={clearPolygons}>
            Clear
        </Button>
      </div>
      <FeatureGroup ref={featureGroupRef} style={{zIndex: 999}}>
        <GeomanControls
          options={{
            position: 'topleft',
            drawPolygon: false,
            drawMarker: false,
            drawCircle: false,
            drawRectangle: false,
            drawCircleMarker: false,
            drawText: false,
            editMode: false,
            dragMode: false,
            removalMode: false,
            rotateMode: false,
            cutPolygon: false
          }}
        />
      </FeatureGroup>
    </>
  )
}

export default withStyles(styles)(ElevationLineModal)