import { useSelector } from 'react-redux'
import { jwtDecode } from 'jwt-decode'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { endOfDay, startOfDay } from '../utils'
import { makeQueryString, standardHeaders } from './api-utils'

const ACCOUNTS_API = new URL(process.env.REACT_APP_ACCOUNTS_API).href
const ACCOUNTS_URL = new URL(process.env.REACT_APP_ACCOUNTS_URL).href

export function getAccountsURL() {
  return new URL(ACCOUNTS_URL)
}

export function getSubscriptionUrl() {
  return `${ACCOUNTS_URL}/apps/facilities`
}

export function useAccessToken() {
  return useSelector(state => state?.oidc?.user?.access_token)
}

export function useDecodedAccessToken() {
  const token = useAccessToken()
  if (token) {
    const decoded = jwtDecode(token)
    const {
      plex: { company_id, company_status }
    } = decoded
    return {
      ...decoded,
      user_id: decoded.sub,
      company_id,
      company_status,
    }
  } else {
    return {
      user_id: '',
      company_id: '',
      company_status: '',
    }
  }
}
export function useMyUserId() {
  return useDecodedAccessToken().user_id
}
export function useMyCompanyId() {
  return useDecodedAccessToken().company_id
}
export function useMyCompanyStatus() {
  return useDecodedAccessToken().company_status
}
export function logout() {
  import('../utils/user-manager').then(userManager => {
    userManager.default.signoutRedirect()
  })
  // userManager
  //   .signoutRedirect({ id_token_hint: oidc?.user?.access_token })
  //   .catch((err) => {
  //     console.error('signoutRedirect error', err)
  //     // TODO: handle errors from signoutRedirect
  //   })
  // userManager.clearStaleState()
}

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ACCOUNTS_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['User', 'Company', 'Subscription'],
  endpoints: (builder) => ({

    // === USER ===
    getUserById: builder.query({
      query: (userId) => `/users/${userId}`,
      providesTags: ['User'],
    }),
    getUsers: builder.query({
      query: (params) => (`/users${makeQueryString(params, [
        'email',
      ])}`),
      providesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `/users/${user.user_id}`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),

    // === COMPANY ===
    getCompanyById: builder.query({
      query: (company_id) => `/companies/${company_id}`,
      providesTags: ['Company'],
    }),
    getCompanies: builder.query({
      query: (params) => (`/companies${makeQueryString(params, [
        'name',
        'ems_enabled',
      ])}`),
      providesTags: ['Company'],
    }),

    // === SUBSCRIPTIONS ===
    getSubscriptions: builder.query({
      query: (params) => (`/subscriptions${makeQueryString(params, [
        'app_key',
        'company_id',
      ])}`),
      providesTags: ['Subscription'],
    }),
    createSubscription: builder.mutation({
      query: (body) => ({
        url: '/subscriptions/self-service',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Subscription']
    }),

  }),
})

export const {
  useGetUserByIdQuery,
  useGetUsersQuery,
  useUpdateUserMutation,

  useGetCompanyByIdQuery,
  useLazyGetCompaniesQuery,

  useGetSubscriptionsQuery,
  useCreateSubscriptionMutation,
} = accountsApi

// Subscription helpers

export function isValidSub(sub) {
  const now = Date.now()
  return (
    now > startOfDay(sub.start_date).getTime() &&
    now < endOfDay(sub.end_date).getTime()
  )
}
export const isUpcomingSub = sub => (
  Date.now() < startOfDay(sub.start_date).getTime()
)
export const hasValidSub = subs => (
  Array.isArray(subs) && subs.some(isValidSub)
)
