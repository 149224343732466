import { useState } from 'react'
import { connect }  from 'react-redux'
import { push }     from 'connected-react-router'

import logoIcon from '../../assets/png/facilities40_logo_icon.png'
import logoName from '../../assets/png/facilities40_logo_brand.png'
import bgImage  from '../../assets/jpg/facilities40_home_cover.jpg'

import { useDecodedAccessToken } from '../../api/accounts'
import userManager from '../../utils/user-manager'
import NewSubsButton from '../shared/subs/NewSubsButton'
import LogoutButton  from './LogoutButton'
import {
  Button,
  Grid,
  Snackbar,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  home: {
    position:   'fixed',
    width:      '100%',
    height:     '100%',
    padding:     theme.spacing(2),
    backgroundImage: 'url(' + bgImage + ')',
    backgroundSize:  'cover',
    backgroundColor: 'rgba(20,20,20,.5)',
    backgroundBlendMode: 'multiply',
  },
  logoIcon: {
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
    },
    width:   theme.spacing(8),
    height:  'auto',
    margin:  theme.spacing(1),
  },
  logoName: {
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(32),
    },
    width:   theme.spacing(50),
    height:  'auto',
    margin:  theme.spacing(1),
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    fontSize:   '28px',
    fontWeight: 'bold',
    color:      theme.palette.common.white,
    textAlign:  'center',
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    fontSize:   '18px',
    color:      theme.palette.common.white,
    textAlign:  'center',
  },
  button: {
    marginTop:   theme.spacing(3),
    width:       theme.spacing(22),
    height:      theme.spacing(5),
  }
})

const Home = ({ classes, push }) => {
  const token = useDecodedAccessToken()
  const [ err, setErr ] = useState('')

  const handleLogin = async (e) => {
    e.preventDefault()
    await userManager.signinRedirect()
    .catch(error => {
      setErr(error.message)
    })
  }
  const loggedIn = !!token.user_id
  const hasRole  = loggedIn ? token.app_roles?.facilities?.length > 0 : false

  return (<>
    { loggedIn && <LogoutButton /> }
    <Grid container
      className={classes.home}
      direction='column'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
        <img src={logoIcon} className={classes.logoIcon} alt={'Facilities 4.0 Logo'} />
        <img src={logoName} className={classes.logoName} alt={'Facilities 4.0 Name'} />
      </Grid>
      <Grid item>
        <Typography className={classes.title}>
          Streamline your inspection operations
          </Typography>
        <Typography className={classes.subtitle}>
          Use AI to identify facility defects and generate templated reports
        </Typography>
      </Grid>
      <Grid item>
        { loggedIn ?
            hasRole ?
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                href='/facilities'>
                My Facilities
              </Button>
            : <NewSubsButton className={classes.button} />
          : <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={handleLogin}>
              Login
            </Button>
        }
      </Grid>
    </Grid>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={err !== ''}
      autoHideDuration={6000}
      onClose={() => { setErr('') }}>
      <Alert severity='error' variant='filled'>{err}</Alert >
    </Snackbar>
  </>)
}

export default connect(null, { push })(withStyles(styles)(Home))
