import { useState, useContext } from 'react'
import { toast } from 'react-toastify'
import {
  useCreateSubscriptionMutation,
  useUpdateUserMutation,
} from '../../../api/accounts'
import { AccountsContext } from '../../AccountsContextProvider'
import ExternalLink    from '../ExternalLink'
import LogoBanner      from '../LogoBanner'
import TopXCloseButton from '../TopXCloseButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import {
  Launch,
} from '@material-ui/icons'

function NewSubsDialog({ onClose }) {
  const { user, company, company_id } = useContext(AccountsContext)
  const isAdmin = user?.app_roles?.account?.includes('admin')

  const [ createSubscription ] = useCreateSubscriptionMutation()
  const [ updateUser ] = useUpdateUserMutation()
  const [ waiting, setWaiting ] = useState(false)

  function handleNewSub() {
    if (!isAdmin)
      return
    const newMe = {
      ...user,
      app_roles: {
        ...user.app_roles,
        facilities: ['fm-manager'], // default role for new sign ups
      }
    }

    setWaiting(true)
    createSubscription({
      company_id,
      app: 'facilities',
      offer_id: '1-WEEK-FREE-TRIAL'
    })
    .unwrap()
    .then(fulfilled => {
      console.log(fulfilled)
      if (fulfilled.status !== 'success') {
        toast.error('Failed to create subscription')
        setWaiting(false)
        return
      }
      updateUser(newMe)
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.status !== 'success') {
          toast.error('Failed to assign role, please contact us at support@garuda.io')
          setWaiting(false)
          return
        }
        toast.success('Your 1 Week Free Trial has been activated. Please login again.')
        onClose()
        setTimeout(() => {
          window.location.href = '/logout'
        }, 2500)
      })
      .catch(e => {
        toast.error('Error updating user')
        console.log('Error updating user', e)
        onClose()
      })
    })
    .catch(e => {
      toast.error('Error creating subscription')
      console.log('Error creating subscription', e)
      onClose()
    })
  }

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='md'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>
        <LogoBanner />
        Start your Free Trial
      </DialogTitle>
      <DialogContent>
        { isAdmin ?
          <Typography variant='body1'>
            You are about to sign up for a 1 week free trial of Facilities 4.0 app.
            <br /><br />
            By signing up, you agree to our <ExternalLink href='https://garuda.io/terms'>Terms
            of Use <Launch /></ExternalLink>.<br />
            <br />
            Please verify your account details:<br />
            <b>Company name</b>: {company.name}<br />
            <b>Billing e-mail</b>: {company.email}<br />
            <b>Your name and e-mail</b>: {user.name} ({user.email})<br />
            <br />
            No credit card is required for this free trial. At the end of the trial,
            your account will become read only. You can subsequently upgrade to a full
            subscription to continue using Facilities 4.0.
          </Typography>
        : <Typography variant='body1'>
            Please contact your company administrator to sign your company up for the
            1 week free trial of Facilities 4.0
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary'
          disabled={!isAdmin || waiting}
          onClick={handleNewSub}>
          Start Free Trial
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewSubsDialog
