
import {
  Typography,
} from '@material-ui/core'
import {
  Description,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(0, 2, 8, 4),
  },
  subtitle: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
})

function EmptyDocuments({ classes }) {

  return (
    <div className={classes.root}>
      <div className={classes.subtitle}>
        <Description className={classes.icon} />
        <Typography variant='h6'>
          No Documents Found
        </Typography>
      </div>
      <Typography variant='h3' gutterBottom color='primary'>
        Let's Get Started
      </Typography>
      <Typography variant='h5' gutterBottom>Create a Document</Typography>
      <Typography variant='body1'>
        You can store any document related to your Facility or Inspections on
        Facilities 4.0. When report templates are generated, they are also found
        here as a Document. Documents can be:
      </Typography>
      <ol>
        <li>Reports</li>
        <li>Permits</li>
        <li>Licenses</li>
        <li>Insurance</li>
      </ol>
      <Typography variant='body1' gutterBottom>
        and more. Documents can tagged to a Facility or an Inspection or
        both. To upload a Document, click on NEW DOCUMENT on the top right.
      </Typography>
    </div>  )
}

export default withStyles(styles)(EmptyDocuments)
