import { Close } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  x: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer'
  },
  icon: {
    color: 'white',
  }
})

function TopXCloseButton({ classes, onClick }) {
  return (
    <div className={classes.x} onClick={onClick}>
      <Close className={classes.icon}/>
    </div>
  )
}

export default withStyles(styles)(TopXCloseButton)