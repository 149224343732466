// Render friendly Icons (from Material or custom SVG / PNG)
//
// Usage:
//   <FacilityTypeIcon type='building' />
//   <FacilityStatusIcon type='active' />
//   <InspectionStatusIcon type='draft' />
//   <DefectStatusIcon type='resolved' />
//   <DocumentStatusIcon type='archived' />

import {
  Avatar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
import {
  Apartment,
  Archive,
  Assignment,
  AssignmentTurnedIn,
  Build,
  Cancel,
  Check,
  Delete,
  Done,
  ErrorOutline,
  HelpOutline,
  ImageSearch,
  RateReview,
  ReportProblem,
  ThumbsUpDown,
} from '@material-ui/icons'
import { withStyles, makeStyles } from '@material-ui/core/styles'

export const FacilityTypeIcon = ({ type, ...rest }) => ({
  building:  <Apartment {...rest} />,
  powerline: <SvgIcon width='163.000000pt' height='211.000000pt' viewBox='0 0 163.000000 211.000000' preserveAspectRatio='xMidYMid meet' {...rest}>
    <g transform='translate(0.000000,211.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
      <path d='M792 2028 c-5 -7 -29 -102 -52 -210 l-43 -198 -331 -2 -331 -3 -3 -95 c-4 -125 0 -140 38 -140 32 0 38 10 42 87 l3 43 243 -139 c133 -76 245 -140 247 -143 5 -5 -18 -115 -90 -443 -136 -617 -156 -717 -144 -731 10 -12 83 -14 445 -14 388 0 434 2 449 16 11 12 14 25 10 43 -28 112 -244 1125 -241 1128 2 3 114 67 248 143 l243 138 3 -41 c4 -76 10 -87 41 -87 17 0 31 6 35 16 8 20 8 188 0 208 -5 14 -44 16 -333 16 l-328 0 -43 198 c-23 108 -47 203 -52 210 -6 6 -18 12 -28 12 -10 0 -22 -6 -28 -12z m-116 -505 c-2 -10 -14 -62 -26 -116 l-22 -97 -51 29 c-29 16 -119 68 -202 115 l-150 86 228 0 c213 0 228 -1 223 -17z m204 11 c0 -3 11 -55 25 -116 14 -61 25 -115 25 -119 0 -5 -49 -9 -110 -9 -60 0 -110 4 -110 9 0 4 11 58 25 119 14 61 25 113 25 116 0 3 27 6 60 6 33 0 60 -3 60 -6z m385 -80 c-82 -47 -173 -99 -202 -115 l-51 -29 -22 97 c-12 54 -24 106 -26 116 -5 16 10 17 223 17 l228 0 -150 -86z m-405 -250 c0 -5 -240 -344 -244 -344 -2 0 50 247 69 328 5 21 10 22 90 22 47 0 85 -3 85 -6z m145 -244 c25 -118 48 -223 51 -232 5 -17 -10 -18 -220 -18 l-225 0 172 241 c94 132 173 237 174 232 1 -4 23 -105 48 -223z m-40 -364 c-16 -13 -133 -109 -260 -214 -126 -104 -231 -189 -233 -187 -1 1 18 95 43 209 25 114 45 209 45 212 0 2 98 4 218 4 l217 0 -30 -24z m214 -453 l1 -23 -332 0 c-201 0 -328 4 -322 9 5 5 133 111 284 235 l275 226 47 -212 c26 -117 47 -223 47 -235z' />
    </g>
  </SvgIcon>,
  telecoms: null, // TODO SVG icon for telco tower
}[type] || null)

export const FacilityStatusIcon = ({ type, ...rest }) => ({
  active:         <Check {...rest} />,
  archived:       <Archive {...rest} />,
  deleted:        <Delete {...rest} />,
}[type] || null)

export const InspectionStatusIcon = ({ type, ...rest }) => ({
  draft:          <Assignment {...rest} />,
  'for-approval': <ThumbsUpDown {...rest} />,
  inspecting:     <Apartment {...rest} />,
  processing:     <ImageSearch {...rest} />,
  completed:      <AssignmentTurnedIn {...rest} />,
  cancelled:      <Cancel {...rest} />,
}[type] || null)

export const StyledToolTip = withStyles({
  tooltip: {
    padding: '8px 24px',
  },
})(Tooltip)

export function InspectionStatusIconWithToolTip({ status, ...rest }) {
  const desc = {
    draft         : 'Planning (Draft)',
    'for-approval': 'Plan Submitted for Approval',
    inspecting    : 'Flight Operation (Inspection In Progress)',
    processing    : 'Processing Data, Preparing Reports',
    completed     : 'Inspection Completed',
    cancelled     : 'Inspection Cancelled',
  }
  return (
    <StyledToolTip
      title={<>
        <Typography variant='body1'><b>{desc[status]}</b></Typography>
      </>}>
      <span>
        <Avatar>
          <InspectionStatusIcon type={status} {...rest} />
        </Avatar>
      </span>
    </StyledToolTip>
  )
}

// TODO: Revisit colors - used to be for defect status but now severity also gets color, might
//       clash. Alternative plan is to use non yellow orange red for workflow status. 
const styles = theme => ({
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  success: {
    color: theme.palette.success.main,
  },
})

export function DefectStatusIcon({ status, ...rest }) {
  const classes = makeStyles(styles)()
  return (
    status === 'new'       ? <ErrorOutline className={classes.secondary} {...rest} /> :
    status === 'confirmed' ? <ReportProblem className={classes.error} {...rest} /> :
    status === 'fixing'    ? <Build className={classes.primary} {...rest} /> :
    status === 'resolved'  ? <Done className={classes.success}  {...rest} /> :
    <HelpOutline {...rest} />
  )
}

export function DefectStatusIconWithToolTip({ status, ...rest }) {
  const desc = {
    new       : 'New Defect',
    confirmed : 'Confirmed Defect',
    fixing    : 'Defect Rectification In Progress',
    resolved  : 'Defect Rectified',
  }
  return (
    <StyledToolTip
      title={<>
        <Typography variant='body1'><b>{desc[status]}</b></Typography>
      </>}>
      <span>
        <DefectStatusIcon status={status} {...rest} />
      </span>
    </StyledToolTip>
  )
}

export function DocumentStatusIcon({ status, ...rest }) {
  return (
    status === 'draft'    ? <Assignment {...rest} /> :
    status === 'review'   ? <RateReview {...rest} /> :
    status === 'final'    ? <Check {...rest} /> :
    status === 'archived' ? <Archive {...rest} /> :
    status === 'deleted'  ? <Delete {...rest} /> :
    <HelpOutline {...rest} />
  )
}

