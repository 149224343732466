import { useState, useRef, useEffect } from 'react'
import {
  IconButton,
  withStyles,
} from '@material-ui/core'
import {
  Add,
  Remove,
} from '@material-ui/icons'

const styles = theme => ({
  root: {
    backgroundColor: '#ffffff',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  zoomControls: {
    backgroundColor: '#fff',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),

    '&:hover': {
      backgroundColor: '#eee',
    }
  }
})

function ImageZoomInOut({ classes, imageUrl }) {
  const [ scale, setScale ] = useState(1)
  const [ position, setPosition ] = useState({ x: 0, y: 0 })
  const [ isLandscape, setIsLandscape ] = useState(false)
  const imageRef = useRef(null)
  const containerRef = useRef(null)

  const handleZoomIn = () => {
    setScale((scale) => scale + 0.1)
  }

  const handleZoomOut = () => {
    setScale((scale) => scale - 0.1)
  }

  const handleWheel = (e) => {

  }

  useEffect(() => {
    const container = containerRef.current
    let isDragging = false
    let prevPosition = { x: 0, y: 0 }

    const handleMouseDown = (e) => {
      isDragging = true
      prevPosition = { x: e.clientX, y: e.clientY }
    }

    const handleMouseMove = (e) => {
      if (!isDragging) return
      let deltaX = e.clientX - prevPosition.x
      let deltaY = e.clientY - prevPosition.y

      if (scale < 1) {
        const multiplier = (1 - scale) + 1
        deltaX *= multiplier
        deltaY *= multiplier
      }

      prevPosition = { x: e.clientX, y: e.clientY }
      setPosition((position) => ({
        x: position.x + deltaX,
        y: position.y + deltaY,
      }))
    }

    const handleMouseUp = () => {
      isDragging = false
    }

    container?.addEventListener('mousedown', handleMouseDown)
    container?.addEventListener('mousemove', handleMouseMove)
    container?.addEventListener('mouseup', handleMouseUp)

    return () => {
      container?.removeEventListener('mousedown', handleMouseDown)
      container?.removeEventListener('mousemove', handleMouseMove)
      container?.removeEventListener('mouseup', handleMouseUp)
    }
  }, [imageRef, scale])

  useEffect(() => {
    setScale(1)
    setPosition({ x: 0, y: 0 })
  }, [imageUrl])

  // need to use function, as naturalWidth and naturalHeight are not available when loading
  function imageOnLoad() {
    const landscape = imageRef.current && imageRef.current.naturalWidth >= imageRef.current.naturalHeight
    console.log('isLandscape', landscape, imageRef.current.naturalWidth, imageRef.current.naturalHeight)
    setIsLandscape(landscape)
  }

  console.log('img', imageRef)

  return (
    <div className={classes.root} onWheel={handleWheel} ref={containerRef}>
      <div className={classes.btnContainer}>
        <IconButton className={classes.zoomControls} onClick={handleZoomIn}>
          <Add />
        </IconButton>
        <IconButton className={classes.zoomControls} onClick={handleZoomOut}>
          <Remove />
        </IconButton>
      </div>

      <img
        ref={imageRef}
        src={imageUrl}
        onLoad={imageOnLoad}
        alt=''
        style={{
          width: (isLandscape ? '100%' : 'auto'),
          height: (isLandscape ? 'auto' : '50vh'),
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          cursor: 'move',
        }}
        draggable={false}
      />
    </div>
  )
}

export default withStyles(styles)(ImageZoomInOut)