import { Link } from 'react-router-dom'
import Company from '../../../shared/text/Company'
import Image   from '../../../shared/Image'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import placeholderBuilding from '../../../../assets/png/facility_placeholder_building.png'
import placeholderPowerLine from '../../../../assets/png/facility_placeholder_powerline.png'

const styles = theme => ({
  media: {
    // height: '260px',
    height: theme.spacing(28),
    width: '100%',
    overflow:       'hidden',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
  },
  root: {
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    lineClamp: 2,
    WebkitLineClamp: 2,
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  actionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  cardImage: {
    // width: '100%',
    // height: '100%',
    objectFit: 'cover',
  },
})

function DirectoryCard({
  classes,
  facility,
  ownFacility,
  facilityUpdatingImageId,
  onClick
})
  {
  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.actionArea} component={Link}
        to={`/facilities/${facility._id}`}>
        {/* <CardMedia
          className={classes.media}
          title={facility.name}
          image={facility.profile_image ? getMediaURL(facility.profile_image, 'preview') :
            facility.type === 'powerline' ? placeholderPowerLine : placeholderBuilding}
          /> */}
          <CardMedia title={facility.name} className={classes.media}>
            { facilityUpdatingImageId === facility._id ?
              <CircularProgress />
            : facility.profile_image ?
              <Image className={classes.cardImage}
                media_id={facility.profile_image}
                size='preview'
                alt={facility.name}
              />
            : <Image className={classes.cardImage}
                src={facility.type === 'powerline' ? placeholderPowerLine : placeholderBuilding}
                alt={facility.name}
              />
            }

          </CardMedia>
        <CardContent className={classes.content}>
          <Typography className={classes.title} variant='h5' gutterBottom>
            {facility.name}
          </Typography>
          <Typography variant='body2'>
            {facility.address || 'No address'}
          </Typography>
          {
            !ownFacility &&
            <Typography variant='body2'>
              <b>Owner: <Company id={facility.company_id} name /></b>
            </Typography>
          }
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default withStyles(styles)(DirectoryCard)
