import { withStyles } from '@material-ui/core/styles'

import logoIcon from '../../assets/png/facilities40_logo_icon.png'
import logoName from '../../assets/png/facilities40_logo_brand.png'

const styles = theme => ({
  logo: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  logoIcon: {
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
    },
    width:   theme.spacing(8),
    height:  'auto',
    margin:  theme.spacing(2),
  },
  logoName: {
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(32),
    },
    width:   theme.spacing(50),
    height:  'auto',
    margin:  theme.spacing(2),
  },
})

function LogoBanner({ classes }) {
  return (
    <div className={classes.logo}>
      <img src={logoIcon} className={classes.logoIcon} alt={'Facilities 4.0 Logo'} />
      <img src={logoName} className={classes.logoName} alt={'Facilities 4.0 Name'} />
    </div>
  )
}

export default withStyles(styles)(LogoBanner)
