import { useMediaURL } from '../../api/media'
import defaultImage from '../../assets/png/facilities40_logo_icon.png'

function Media({ mediaId, size, alt, fill, style, ...rest }) {
  const _style = {
    ...style,
    ...(fill ? { width: '100%', height: '100%' } : {})
  }
  const mediaUrl = useMediaURL(mediaId, size)
  if (!mediaUrl)
    return (
      <div style={_style} {...rest}>
        <img src={defaultImage}
          alt='no avatar'
          style={{
            width: '26px',
            animationName: 'spin',
            animationDuration: '60000ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear'
          }}
        />
      </div>
    )
  return (
    <div style={_style} {...rest}>
      <img src={mediaUrl}
        alt={alt || 'image'}
        style={_style}
      />
    </div>
  )
}

export default Media
